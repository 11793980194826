import React, { useEffect, useState } from 'react'
import { HiUsers } from "react-icons/hi";
import { RiShoppingBasketLine } from "react-icons/ri";
import { FaList, FaFolderOpen } from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css'
import axios from 'axios';
import { BASE_URL } from '../../services/Api';
import authHeader from '../../services/auth-header';
import ProgressBar from "@ramonak/react-progress-bar";
import AuthService from '../../services/auth.service';
import { Link } from 'react-router-dom';
export default function DashBoard() {
  const [message, setMessage] = useState([]);
  const [retailer, setretailer] = useState('');
  const [workorderdate, setworkorderdate] = useState('');
  const [workordermonth, setworkordermonth] = useState('');
  const [workorderyear, setworkorderyear] = useState('');
  const [primaryorderdate, setprimaryorderdate] = useState('');
  const [primaryordermonth, setprimaryordermonth] = useState('');
  const [primaryorderyear, setprimaryorderyear] = useState('');
  const [purchaseorderdate, setpurchaseorderdate] = useState('');
  const [purchaseordermonth, setpurchaseordermonth] = useState('');
  const [purchaseorderyear, setpurchaseorderyear] = useState('');
  const [sdndate, setsdndate] = useState('');
  const [sdnmonth, setsdnmonth] = useState('');
  const [sdnyear, setsdnyear] = useState('');
  const currentuser = AuthService.getCurrentUser();
  // console.log('mello',currentuser)
  /* Admin Access */
  function getretailerdata() {
    axios.get(BASE_URL + 'ret/count/get', {
      headers: authHeader()
    }).then(res => {

      setretailer(res.data[0]);
    })
  }
  /* Admin Access */
  function getworkorderdatadate() {
    axios.get(BASE_URL + 'count/get/date', {
      headers: authHeader()
    }).then(res => {

      setworkorderdate(res.data[0]);
    })

    axios.get(BASE_URL + 'count/get/month', {
      headers: authHeader()
    }).then(res => {

      setworkordermonth(res.data[0]);
    })

    axios.get(BASE_URL + 'count/get/year', {
      headers: authHeader()
    }).then(res => {

      setworkorderyear(res.data[0]);
    })
  }
  /* Admin Access */
  function getprimaryorderdatamonth() {
    axios.get(BASE_URL + 'count/dist/get/date', {
      headers: authHeader()
    }).then(res => {

      setprimaryorderdate(res.data[0]);
    })

    axios.get(BASE_URL + 'count/dist/get/month', {
      headers: authHeader()
    }).then(res => {

      setprimaryordermonth(res.data[0]);
    })

    axios.get(BASE_URL + 'count/dist/get/year', {
      headers: authHeader()
    }).then(res => {

      setprimaryorderyear(res.data[0]);
    })

    /* purchase Order Date*/
    axios.get(BASE_URL + 'count/po/get/date', {
      headers: authHeader()
    }).then(res => {
      setpurchaseorderdate(res.data[0]);
    })

    /* Purchase Order Month */
    axios.get(BASE_URL + 'count/po/get/month', {
      headers: authHeader()
    }).then(res => {
      // console.log('mello',res.data)
      setpurchaseordermonth(res.data[0]);
    })

    /* Purchase Order Year */
    axios.get(BASE_URL + 'count/po/get/year', {
      headers: authHeader()
    }).then(res => {
      // console.log('mello',res.data)
      setpurchaseorderyear(res.data[0]);
    })

    /* Supplier Delivery Note Date*/
    axios.get(BASE_URL + 'count/sdn/get/date', {
      headers: authHeader()
    }).then(res => {
      setsdndate(res.data[0]);
    })
    /* Supplier Delivery Note Month*/
    axios.get(BASE_URL + 'count/sdn/get/month', {
      headers: authHeader()
    }).then(res => {
      setsdnmonth(res.data[0]);
    })
    /* Supplier Delivery Note Year*/
    axios.get(BASE_URL + 'count/sdn/get/year', {
      headers: authHeader()
    }).then(res => {
      setsdnyear(res.data[0]);
    })
  }

/* Supplier Access */
function getsupplierdata(){
      /* purchase Order Date*/
      
      axios.get(BASE_URL + 'count/po/get/date/supplier/' + currentuser.id, {
        headers: authHeader()
      }).then(res => {
        setpurchaseorderdate(res.data[0]);
      })
  
      /* Purchase Order Month */
      axios.get(BASE_URL + 'count/po/get/month/supplier/' +currentuser.id, {
        headers: authHeader()
      }).then(res => {
        // console.log('mello',res.data)
        setpurchaseordermonth(res.data[0]);
      })
  
      /* Purchase Order Year */
      axios.get(BASE_URL + 'count/po/get/year/supplier/' +currentuser.id, {
        headers: authHeader()
      }).then(res => {
        // console.log('mello',res.data)
        setpurchaseorderyear(res.data[0]);
      })
  
      /* Supplier Delivery Note Date*/
      axios.get(BASE_URL + 'count/sdn/get/date/supplier/' +currentuser.id, {
        headers: authHeader()
      }).then(res => {
        setsdndate(res.data[0]);
      })
      /* Supplier Delivery Note Month*/
      axios.get(BASE_URL + 'count/sdn/get/month/supplier/' +currentuser.id, {
        headers: authHeader()
      }).then(res => {
        setsdnmonth(res.data[0]);
      })
      /* Supplier Delivery Note Year*/
      axios.get(BASE_URL + 'count/sdn/get/year/supplier/'+currentuser.id, {
        headers: authHeader()
      }).then(res => {
        setsdnyear(res.data[0]);
      })
}

  /* Distributor Access */
  function getretailerdatawithdistributor() {
    axios.get(BASE_URL + 'ret/count/get/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setretailer(res.data[0]);
    })
  }
  /* Distributor Access */
  function getworkorderdatadatedistributor() {
    axios.get(BASE_URL + 'count/get/date/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setworkorderdate(res.data[0]);
    })

    axios.get(BASE_URL + 'count/get/month/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setworkordermonth(res.data[0]);
    })

    axios.get(BASE_URL + 'count/get/year/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setworkorderyear(res.data[0]);
    })
  }
  /* Distributor Access */
  function getprimaryorderdatamonthdistributor() {
    axios.get(BASE_URL + 'count/dist/get/date/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setprimaryorderdate(res.data[0]);
    })

    axios.get(BASE_URL + 'count/dist/get/month/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setprimaryordermonth(res.data[0]);
    })

    axios.get(BASE_URL + 'count/dist/get/year/distributor/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setprimaryorderyear(res.data[0]);
    })
  }



  /* staff Access */
  function getretailerdatawithstaff() {
    axios.get(BASE_URL + 'ret/count/get/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setretailer(res.data[0]);
    })
  }
  /* staff Access */
  function getworkorderdatadatestaff() {
    axios.get(BASE_URL + 'count/get/date/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setworkorderdate(res.data[0]);
    })

    axios.get(BASE_URL + 'count/get/month/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setworkordermonth(res.data[0]);
    })

    axios.get(BASE_URL + 'count/get/year/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setworkorderyear(res.data[0]);
    })
  }
  /* staff Access */
  function getprimaryorderdatamonthstaff() {
    axios.get(BASE_URL + 'count/dist/get/date/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setprimaryorderdate(res.data[0]);
    })

    axios.get(BASE_URL + 'count/dist/get/month/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setprimaryordermonth(res.data[0]);
    })

    axios.get(BASE_URL + 'count/dist/get/year/staff/' + currentuser.id, {
      headers: authHeader()
    }).then(res => {

      setprimaryorderyear(res.data[0]);
    })
  }


    /* Retailer Access */
    function getworkorderdataretailerlogin() {
      axios.get(BASE_URL + 'count/get/date/retailer/'+currentuser.id, {
        headers: authHeader()
      }).then(res => {
  
        setworkorderdate(res.data[0]);
      })
  
      axios.get(BASE_URL + 'count/get/month/retailer/'+currentuser.id, {
        headers: authHeader()
      }).then(res => {
  
        setworkordermonth(res.data[0]);
      })
  
      axios.get(BASE_URL + 'count/get/year/retailer/'+currentuser.id, {
        headers: authHeader()
      }).then(res => {
  
        setworkorderyear(res.data[0]);
      })
    }

  let retailerperInActive = (retailer.total_inactive_retailer / retailer.total_retailer * 100).toFixed(2);
  let retailerperActive = retailer.total_active_retailer == null ? 0 : (retailer.total_active_retailer / retailer.total_retailer * 100).toFixed(2);
  /*Workorder order */
  let workorderdatapending = workorderdate.total_count == 0 ? 0 : (workorderdate.total_pending_count / workorderdate.total_count * 100).toFixed(2);
  let workorderdataconverted = workorderdate.total_count == 0 ? 0 : (workorderdate.total_converted_count / workorderdate.total_count * 100).toFixed(2);
  let workordermonthpending = workordermonth.total_count == 0 ? 0 : (workordermonth.total_pending_count / workordermonth.total_count * 100).toFixed(2);
  let workordermonthconverted = workordermonth.total_count == 0 ? 0 : (workordermonth.total_converted_count / workordermonth.total_count * 100).toFixed(2);
  let workorderyearpending = workorderyear.total_count == 0 ? 0 : (workorderyear.total_pending_count / workorderyear.total_count * 100).toFixed(2);
  let workorderyearconverted = workorderyear.total_count == 0 ? 0 : (workorderyear.total_converted_count / workorderyear.total_count * 100).toFixed(2);
  /*primary order */
  let primaryorderdatapending = primaryorderdate.total_count == 0 ? 0 : (primaryorderdate.total_pending_count / primaryorderdate.total_count * 100).toFixed(2);
  let primaryorderdataconverted = primaryorderdate.total_count == 0 ? 0 : (primaryorderdate.total_converted_count / primaryorderdate.total_count * 100).toFixed(2);
  let primaryordermonthpending = primaryordermonth.total_count == 0 ? 0 : (primaryordermonth.total_pending_count / primaryordermonth.total_count * 100).toFixed(2);
  let primaryordermonthconverted = primaryordermonth.total_count == 0 ? 0 : (primaryordermonth.total_converted_count / primaryordermonth.total_count * 100).toFixed(2);
  let primaryorderyearpending = primaryorderyear.total_count == 0 ? 0 : (primaryorderyear.total_pending_count / primaryorderyear.total_count * 100).toFixed(2);
  let primaryorderyearconverted = primaryorderyear.total_count == 0 ? 0 : (primaryorderyear.total_converted_count / primaryorderyear.total_count * 100).toFixed(2);

  /*Purchase order */
  let purchaseordatapending = purchaseorderdate.total_count == 0 ? 0 : (purchaseorderdate.total_pending_count / purchaseorderdate.total_count * 100).toFixed(2);
  let purchaseordataconverted = purchaseorderdate.total_count == 0 ? 0 : (purchaseorderdate.total_converted_count / purchaseorderdate.total_count * 100).toFixed(2);
  let purchaseormonthpending = purchaseordermonth.total_count == 0 ? 0 : (purchaseordermonth.total_pending_count / purchaseordermonth.total_count * 100).toFixed(2);
  let purchaseormonthconverted = purchaseordermonth.total_count == 0 ? 0 : (purchaseordermonth.total_converted_count / purchaseordermonth.total_count * 100).toFixed(2);
  let purchaseoryearpending = purchaseorderyear.total_count == 0 ? 0 : (purchaseorderyear.total_pending_count / purchaseorderyear.total_count * 100).toFixed(2);
  let purchaseoryearconverted = purchaseorderyear.total_count == 0 ? 0 : (purchaseorderyear.total_converted_count / purchaseorderyear.total_count * 100).toFixed(2);

  /*Purchase order */
  let sdndatapending = sdndate.total_count == 0 ? 0 : (sdndate.total_pending_count / sdndate.total_count * 100).toFixed(2);
  let sdndataconverted = sdndate.total_count == 0 ? 0 : (sdndate.total_converted_count / sdndate.total_count * 100).toFixed(2);
  let sdnmonthpending = sdnmonth.total_count == 0 ? 0 : (sdnmonth.total_pending_count / sdnmonth.total_count * 100).toFixed(2);
  let sdnmonthconverted = sdnmonth.total_count == 0 ? 0 : (sdnmonth.total_converted_count / sdnmonth.total_count * 100).toFixed(2);
  let sdnyearpending = sdnyear.total_count == 0 ? 0 : (sdnyear.total_pending_count / sdnyear.total_count * 100).toFixed(2);
  let sdnyearconverted = sdnyear.total_count == 0 ? 0 : (sdnyear.total_converted_count / sdnyear.total_count * 100).toFixed(2);
  // console.log('mello',workorderdataconverted)

  // console.log('mello',retailerper)
  useEffect(() => {
    { currentuser.roles[0] == 'ROLE_ADMIN' && getretailerdata(); }
    { currentuser.roles[0] == 'ROLE_ADMIN' && getworkorderdatadate(); }
    { currentuser.roles[0] == 'ROLE_ADMIN' && getprimaryorderdatamonth(); }

    { currentuser.roles[0] == 'ROLE_DISTRIBUTOR' && getretailerdatawithdistributor() }
    { currentuser.roles[0] == 'ROLE_DISTRIBUTOR' && getworkorderdatadatedistributor() }
    { currentuser.roles[0] == 'ROLE_DISTRIBUTOR' && getprimaryorderdatamonthdistributor() }
    { (currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE') && getretailerdatawithstaff() }
    { (currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE') && getworkorderdatadatestaff() }
    { (currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE') && getprimaryorderdatamonthstaff() }
    { currentuser.roles[0] == 'ROLE_SUPPLIER' && getsupplierdata()}
    { currentuser.roles[0] == 'ROLE_RETAILER' && getworkorderdataretailerlogin()}



    const timeoutId = setTimeout(() => {
      setMessage('Delayed message after 2 seconds!');
    }, 2000);

    return () => clearTimeout(timeoutId);

  }, [])



  return (
    <div data-aos="fade">
      <div class="row" style={{gap:10}}>
        {(currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE' || currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == "ROLE_DISTRIBUTOR") &&
    
    
    <Link to="/Newretailer/1" style={{ display: 'flex' }}>
          <div class="" className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={{ color: '#000000' }}>{retailer.total_retailer}</div><span>Retailer Count</span></p3>
            {/* <HiUsers className='box-icon'/> */}

          </div>
          <div class="" className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={{ color: '#000000', display: 'flex' }}>{retailer.total_inactive_retailer} </div><span>Retailer InActive Count</span></p3>

            <div><ProgressBar completed={retailerperInActive} bgColor={retailerperInActive >= 75 ? "red" : retailerperInActive >= 50 & retailerperInActive < 75 ? "orange" : retailerperInActive >= 25 & retailerperInActive < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>
            {/* <HiUsers className='box-icon'/> */}

          </div>
          <div class="" className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={{ color: '#000000' }}>{retailer.total_active_retailer}</div><span>Retailer Active Count</span></p3>
            <div><ProgressBar completed={retailerperActive} bgColor={retailerperActive >= 75 ? "green" : retailerperActive >= 50 & retailerperActive < 75 ? "#164194" : retailerperActive >= 25 & retailerperActive < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>


          </div>
        </Link>}
        {/* WorkOrder Date */}
        {(currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE' || currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_DISTRIBUTOR' || currentuser.roles[0] == "ROLE_RETAILER") &&
          //  <Link to="/Newretailer/1" style={{ display: 'flex' }}>

       <Link to="/Newoo/1" style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderdate.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{workorderdate.total_count} <span style={workorderdate.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>Today</span></div><span>Secondary Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderdate.total_pending_count == 0 ? { color: 'orange' } : { color: 'red' }}>{workorderdate.total_pending_count} <span style={workorderdate.total_pending_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'red', fontSize: 12, }}>Today</span></div><span>Secondary Count Pending</span></p3>
            <div><ProgressBar completed={workorderdatapending} bgColor={workorderdatapending >= 75 ? "red" : workorderdatapending >= 50 & workorderdatapending < 75 ? "orange" : workorderdatapending >= 25 & workorderdatapending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderdate.total_converted_count == 0 ? { color: 'orange' } : { color: 'green' }}>{workorderdate.total_converted_count} <span style={workorderdate.total_converted_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>Today</span></div><span>Secondary Count Converted </span></p3>
            <div><ProgressBar completed={workorderdataconverted} bgColor={workorderdataconverted >= 75 ? "green" : workorderdataconverted >= 50 & workorderdataconverted < 75 ? "#164194" : workorderdataconverted >= 25 & workorderdataconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderdate.total_amount == null ? { color: 'orange' } : { color: '#164194' }}>{workorderdate.total_amount == null ? "0" : workorderdate.total_amount.toLocaleString("en-IN")} <span style={workorderdate.total_amount == null ? { color: 'orange', fontSize: 12, } : { color: '#164194', fontSize: 12, }}>Today</span></div><span>Secondary Count Amount</span></p3>
          </div>
        </Link>
        // </Link>
        }

        {/* WorkOrder Month */}
        {(currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE' || currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_DISTRIBUTOR' || currentuser.roles[0] == "ROLE_RETAILER") &&
        <Link to="/Newoo/1" style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workordermonth.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{workordermonth.total_count} <span style={workordermonth.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Month Total Count</span></div><span>Secondary Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workordermonth.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{workordermonth.total_pending_count} <span style={workordermonth.total_pending_count == 0 ? { color: "orange", fontSize: 12 } : { color: "red", fontSize: 12 }}>This Month Pending</span></div><span>Secondary Count Pending</span></p3>
            <div><ProgressBar completed={workordermonthpending} bgColor={workordermonthpending >= 75 ? "red" : workordermonthpending >= 50 & workordermonthpending < 75 ? "orange" : workordermonthpending >= 25 & workordermonthpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workordermonth.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{workordermonth.total_converted_count} <span style={workordermonth.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Month Converted</span></div><span>Secondary Count Converted </span></p3>
            <div><ProgressBar completed={workordermonthconverted} bgColor={workordermonthconverted >= 75 ? "green" : workordermonthconverted >= 50 & workordermonthconverted < 75 ? "#164194" : workordermonthconverted >= 25 & workordermonthconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workordermonth.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{workordermonth.total_amount == null ? "0" : workordermonth.total_amount.toLocaleString("en-IN")} <span style={workordermonth.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Month Total Amount</span></div><span>Secondary Count Amount</span></p3>
          </div>
        </Link>
}
        {/* WorkOrder Year */}
        {(currentuser.roles[0] == 'ROLE_NSM' || currentuser.roles[0] == 'ROLE_RSM' || currentuser.roles[0] == 'ROLE_ASM' || currentuser.roles[0] == 'ROLE_ASE' || currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_DISTRIBUTOR' || currentuser.roles[0] == "ROLE_RETAILER") &&
        <Link to="/Newoo/1"  style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderyear.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{workorderyear.total_count} <span style={workorderyear.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Year Total Count</span></div><span>Secondary Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderyear.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{workorderyear.total_pending_count} <span style={workorderyear.total_pending_count == 0 ? { color: "orange", fontSize: 12 } : { color: "red", fontSize: 12 }}>This Year Total Pending</span></div><span>Secondary Count Pending</span></p3>
            <div><ProgressBar completed={workorderyearpending} bgColor={workorderyearpending >= 75 ? "red" : workorderyearpending >= 50 & workorderyearpending < 75 ? "orange" : workorderyearpending >= 25 & workorderyearpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderyear.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{workorderyear.total_converted_count} <span style={workorderyear.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Year Total Converted</span></div><span>Secondary Count Converted </span></p3>
            <div><ProgressBar completed={workorderyearconverted} bgColor={workorderyearconverted >= 75 ? "green" : workorderyearconverted >= 50 & workorderyearconverted < 75 ? "#164194" : workorderyearconverted >= 25 & workorderyearconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={workorderyear.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{workorderyear.total_amount == null ? "0" : workorderyear.total_amount.toLocaleString("en-IN")} <span style={workorderyear.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Year Total Amount</span></div><span>Secondary Count Amount</span></p3>
          </div>
        </Link>
}
        
        {/* Primary Order */} 
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_DISTRIBUTOR') &&
          <Link to="/Newoo/1"  style={{ display: 'flex' }}>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderdate.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{primaryorderdate.total_count} <span style={primaryorderdate.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>Today</span></div><span>Primary Order Count</span></p3>
            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderdate.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{primaryorderdate.total_pending_count} <span style={primaryorderdate.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>Today</span></div><span>Primary Order Count Pending</span></p3>
              <div><ProgressBar completed={primaryorderdatapending} bgColor={primaryorderdatapending >= 75 ? "red" : primaryorderdatapending >= 50 & primaryorderdatapending < 75 ? "orange" : primaryorderdatapending >= 25 & primaryorderdatapending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderdate.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{primaryorderdate.total_converted_count} <span style={primaryorderdate.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>Today</span></div><span>Primary Order Count Converted </span></p3>
              <div><ProgressBar completed={primaryorderdataconverted} bgColor={primaryorderdataconverted >= 75 ? "green" : primaryorderdataconverted >= 50 & primaryorderdataconverted < 75 ? "#164194" : primaryorderdataconverted >= 25 & primaryorderdataconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderdate.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{primaryorderdate.total_amount == null ? "0" : primaryorderdate.total_amount.toLocaleString("en-IN")} <span style={primaryorderdate.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>Today</span></div><span>Primary Order Count Amount</span></p3>
            </div>
          </Link>
        }
               {/* Primary Order */} 
               {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_DISTRIBUTOR') &&
          <Link to="/Newpo/1"  style={{ display: 'flex' }}>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryordermonth.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{primaryordermonth.total_count} <span style={primaryordermonth.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Month Total Count</span></div><span>Primary Order Count</span></p3>
            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryordermonth.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{primaryordermonth.total_pending_count} <span style={primaryordermonth.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>This Month Pending</span></div><span>Primary Order Count Pending</span></p3>
              <div><ProgressBar completed={primaryordermonthpending} bgColor={primaryordermonthpending >= 75 ? "red" : primaryordermonthpending >= 50 & primaryordermonthpending < 75 ? "orange" : primaryordermonthpending >= 25 & primaryordermonthpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryordermonth.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{primaryordermonth.total_converted_count} <span style={primaryordermonth.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Month Converted</span></div><span>Primary Order Count Converted </span></p3>
              <div><ProgressBar completed={primaryordermonthconverted} bgColor={primaryordermonthconverted >= 75 ? "green" : primaryordermonthconverted >= 50 & primaryordermonthconverted < 75 ? "#164194" : primaryordermonthconverted >= 25 & primaryordermonthconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryordermonth.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{primaryordermonth.total_amount == null ? "0" : primaryordermonth.total_amount.toLocaleString("en-IN")} <span style={primaryordermonth.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Month Total Amount</span></div><span>Primary Order Count Amount</span></p3>
            </div>
          </Link>
        }
               {/* Primary Order */} 
               {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_DISTRIBUTOR') &&
          <Link to="/Newpo/1" style={{ display: 'flex' }}>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderyear.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{primaryorderyear.total_count} <span style={primaryorderyear.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Year Total Count</span></div><span>Primary Order Count</span></p3>
            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderyear.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{primaryorderyear.total_pending_count} <span style={primaryorderyear.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>This Year Total Pending</span></div><span>Primary Order Count Pending</span></p3>
              <div><ProgressBar completed={primaryorderyearpending} bgColor={primaryorderyearpending >= 75 ? "red" : primaryorderyearpending >= 50 & primaryorderyearpending < 75 ? "orange" : primaryorderyearpending >= 25 & primaryorderyearpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderyear.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{primaryorderyear.total_converted_count} <span style={primaryorderyear.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Year Total Converted</span></div><span>Primary Order Count Converted </span></p3>
              <div><ProgressBar completed={primaryorderyearconverted} bgColor={primaryorderyearconverted >= 75 ? "green" : primaryorderyearconverted >= 50 & primaryorderyearconverted < 75 ? "#164194" : primaryorderyearconverted >= 25 & primaryorderyearconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

            </div>
            <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
              <p3 style={{ marginTop: '-2px' }}><div style={primaryorderyear.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{primaryorderyear.total_amount == null ? "0" : primaryorderyear.total_amount.toLocaleString("en-IN")} <span style={primaryorderyear.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Year Total Amount</span></div><span>Primary Order Count Amount</span></p3>
            </div>
          </Link>
        }
        {/* purchase Order Date*/}
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_SUPPLIER') &&
        <Link to='/potable/1' style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderdate.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{purchaseorderdate.total_count} <span style={purchaseorderdate.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>Today</span></div><span>Purchase Order Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderdate.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{purchaseorderdate.total_pending_count} <span style={purchaseorderdate.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>Today</span></div><span>Purchase Order Count Pending</span></p3>
            <div><ProgressBar completed={purchaseordatapending} bgColor={purchaseordatapending >= 75 ? "red" : purchaseordatapending >= 50 & purchaseordatapending < 75 ? "orange" : purchaseordatapending >= 25 & purchaseordatapending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderdate.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{purchaseorderdate.total_converted_count} <span style={purchaseorderdate.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>Today</span></div><span>Purchase Order Count Converted </span></p3>
            <div><ProgressBar completed={purchaseordataconverted} bgColor={purchaseordataconverted >= 75 ? "green" : purchaseordataconverted >= 50 & purchaseordataconverted < 75 ? "#164194" : purchaseordataconverted >= 25 & purchaseordataconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderdate.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{purchaseorderdate.total_amount == null ? "0" : purchaseorderdate.total_amount.toLocaleString("en-IN")} <span style={purchaseorderdate.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>Today</span></div><span>Purchase Order Count Amount</span></p3>
          </div>
        </Link >
}

        {/* Purchase Order Month */}
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_SUPPLIER') &&
        <Link to='/potable/1' style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseordermonth.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{purchaseordermonth.total_count} <span style={purchaseordermonth.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Month Total Count</span></div><span>Purchase Order Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseordermonth.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{purchaseordermonth.total_pending_count} <span style={purchaseordermonth.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>This Month Pending</span></div><span>Purchase Order Count Pending</span></p3>
            <div><ProgressBar completed={purchaseormonthpending} bgColor={purchaseormonthpending >= 75 ? "red" : purchaseormonthpending >= 50 & purchaseormonthpending < 75 ? "orange" : purchaseormonthpending >= 25 & purchaseormonthpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseordermonth.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{purchaseordermonth.total_converted_count} <span style={purchaseordermonth.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Month Converted</span></div><span>Purchase Order Count Converted </span></p3>
            <div><ProgressBar completed={purchaseormonthconverted} bgColor={purchaseormonthconverted >= 75 ? "green" : purchaseormonthconverted >= 50 & purchaseormonthconverted < 75 ? "#164194" : purchaseormonthconverted >= 25 & purchaseormonthconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseordermonth.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{purchaseordermonth.total_amount == null ? "0" : purchaseordermonth.total_amount.toLocaleString("en-IN")} <span style={purchaseordermonth.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Month Total Amount</span></div><span>Purchase Order Count Amount</span></p3>
          </div>
        </Link>
}
        {/* Purchase Order Year */}
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_SUPPLIER') &&
        <Link to='/potable/1' style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderyear.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{purchaseorderyear.total_count} <span style={purchaseorderyear.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Year Total Count</span></div><span>Purchase Order Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderyear.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{purchaseorderyear.total_pending_count} <span style={purchaseorderyear.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>This Year Total Pending</span></div><span>Purchase Order Count Pending</span></p3>
            <div><ProgressBar completed={purchaseoryearpending} bgColor={purchaseoryearpending >= 75 ? "red" : purchaseoryearpending >= 50 & purchaseoryearpending < 75 ? "orange" : purchaseoryearpending >= 25 & purchaseoryearpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderyear.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{purchaseorderyear.total_converted_count} <span style={purchaseorderyear.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Year Total Converted</span></div><span>Purchase Order Count Converted </span></p3>
            <div><ProgressBar completed={purchaseoryearconverted} bgColor={purchaseoryearconverted >= 75 ? "green" : purchaseoryearconverted >= 50 & purchaseoryearconverted < 75 ? "#164194" : purchaseoryearconverted >= 25 & purchaseoryearconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={purchaseorderyear.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{purchaseorderyear.total_amount == null ? "0" : purchaseorderyear.total_amount.toLocaleString("en-IN")} <span style={purchaseorderyear.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Year Total Amount</span></div><span>Purchase Order Count Amount</span></p3>
          </div>
        </Link>
}
        {/* SDN Date*/}
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_SUPPLIER') &&
        <Link to='/SupplierDeliveryNoteTable/1' style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdndate.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{sdndate.total_count} <span style={sdndate.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>Today</span></div><span>Supplier Delivery Note Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdndate.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{sdndate.total_pending_count} <span style={sdndate.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>Today</span></div><span>Supplier Delivery Note Count Pending</span></p3>
            <div><ProgressBar completed={sdndatapending} bgColor={sdndatapending >= 75 ? "red" : sdndatapending >= 50 & sdndatapending < 75 ? "orange" : sdndatapending >= 25 & sdndatapending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdndate.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{sdndate.total_converted_count} <span style={sdndate.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>Today</span></div><span>Supplier Delivery Note Count Converted </span></p3>
            <div><ProgressBar completed={sdndataconverted} bgColor={sdndataconverted >= 75 ? "green" : sdndataconverted >= 50 & sdndataconverted < 75 ? "#164194" : sdndataconverted >= 25 & sdndataconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdndate.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{sdndate.total_amount == null ? "0" : sdndate.total_amount.toLocaleString("en-IN")} <span style={sdndate.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>Today</span></div><span>Supplier Delivery Note Count Amount</span></p3>
          </div>
        </Link>
}

        {/* SDN Month */}
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_SUPPLIER') &&
        <Link to='/SupplierDeliveryNoteTable/1' style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnmonth.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{sdnmonth.total_count} <span style={sdnmonth.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Month Total Count</span></div><span>Supplier Delivery Note Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnmonth.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{sdnmonth.total_pending_count} <span style={sdnmonth.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>This Month Pending</span></div><span>Supplier Delivery Note Count Pending</span></p3>
            <div><ProgressBar completed={sdnmonthpending} bgColor={sdnmonthpending >= 75 ? "red" : sdnmonthpending >= 50 & sdnmonthpending < 75 ? "orange" : sdnmonthpending >= 25 & sdnmonthpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnmonth.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{sdnmonth.total_converted_count} <span style={sdnmonth.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Month Converted</span></div><span>Supplier Delivery Note Count Converted </span></p3>
            <div><ProgressBar completed={sdnmonthconverted} bgColor={sdnmonthconverted >= 75 ? "green" : sdnmonthconverted >= 50 & sdnmonthconverted < 75 ? "#164194" : sdnmonthconverted >= 25 & sdnmonthconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnmonth.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{sdnmonth.total_amount == null ? "0" : sdnmonth.total_amount.toLocaleString("en-IN")} <span style={sdnmonth.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Month Total Amount</span></div><span>Supplier Delivery Note Count Amount</span></p3>
          </div>
        </Link>
}
        {/* SDN Year */}
        {(currentuser.roles[0] == "ROLE_ADMIN" || currentuser.roles[0] == 'ROLE_SUPPLIER') &&
        <Link to='/SupplierDeliveryNoteTable/1' style={{ display: 'flex' }}>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnyear.total_count == 0 ? { color: 'orange' } : { color: 'black' }}>{sdnyear.total_count} <span style={sdnyear.total_count == 0 ? { color: 'orange', fontSize: 12, } : { color: 'green', fontSize: 12, }}>This Year Total Count</span></div><span>Supplier Delivery Note Count</span></p3>
          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnyear.total_pending_count == 0 ? { color: "orange" } : { color: "red" }}>{sdnyear.total_pending_count} <span style={sdnyear.total_pending_count == 0 ? { color: "orange", fontSize: 12, } : { color: "red", fontSize: 12, }}>This Year Total Pending</span></div><span>Supplier Delivery Note Count Pending</span></p3>
            <div><ProgressBar completed={sdnyearpending} bgColor={sdnyearpending >= 75 ? "red" : sdnyearpending >= 50 & sdnyearpending < 75 ? "orange" : sdnyearpending >= 25 & sdnyearpending < 50 ? '#164194' : 'green'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnyear.total_converted_count == 0 ? { color: "orange" } : { color: "green" }}>{sdnyear.total_converted_count} <span style={sdnyear.total_converted_count == 0 ? { color: "orange", fontSize: 12 } : { color: "green", fontSize: 12 }}>This Year Total Converted</span></div><span>Supplier Delivery Note Count Converted </span></p3>
            <div><ProgressBar completed={sdnyearconverted} bgColor={sdnyearconverted >= 75 ? "green" : sdnyearconverted >= 50 & sdnyearconverted < 75 ? "#164194" : sdnyearconverted >= 25 & sdnyearconverted < 50 ? 'orange' : 'red'} animateOnRender={true} /></div>

          </div>
          <div className={`card1 ${message.length == 0 ? ' shimmer  ' : ' card1'}`}>
            <p3 style={{ marginTop: '-2px' }}><div style={sdnyear.total_amount == null ? { color: "orange" } : { color: "#164194" }}>{sdnyear.total_amount == null ? "0" : sdnyear.total_amount.toLocaleString("en-IN")} <span style={sdnyear.total_amount == null ? { color: "orange", fontSize: 12 } : { color: "#164194", fontSize: 12 }}>This Year Total Amount</span></div><span>Supplier Delivery Note Count Amount</span></p3>
          </div>
        </Link>
}

      </div>

    </div>
  )
}
