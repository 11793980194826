import axios from "axios";
import React, { useEffect, useState } from "react";
import authHeader from "../services/auth-header";
// import Select from "react-select/dist/declarations/src/Select";
import { BASE_URL } from "../services/Api";
import Select from "react-select";

const Salesexpensereprot = () => {
  const [staffvalue, setstaffvalue] = useState([]);
  const [staffData, setstaffData] = useState([]);
  const [staff,setstaff] = useState('');

const [reportData, setReportData] = useState([])
//  useEffect(()=>{
   
 

//   axios.get(`http://43.225.54.138:8081/api/salesexp/report/35/2023-09-10/2026-02-10`, { headers: authHeader() })
//   .then(response1 => {
//     // reportData(response1.data)

//     const newdata=response1.data.map((item)=>({
//       date:item.expdate,
//       homeTown:item.hometown,
//       travel:item.expenseReportItemsDTO.map((item1)=>({
//         from:item1.travelfrom,
//         to:item1.travelto,
//         mode:item1.modeoftravel,
//         fare:item1.travelfare,
//         status:'Pending'
//       }),),
//       dailyAllowance:item.dailyallownces,
//       acceptedAllowance: item.approvedexpensebyadmin, 
//       otherExpenses: item.expenseReportItemsDTO.map((item1)=>({
//         type:item1.otherexp,
//         amount:item1.otherexpamount
//         // numberOfVisits: 4, 
//         // scvalue:400
        
//       }),),
//       numberOfVisits:item.countvisit,
//       scvalue:item.secondaryordervalue
//     }))
//     setReportData(newdata)

//     console.log(response1.data);
//   })




// },[])
const customFilter = (e) => {
  let name = e;
  axios.get( BASE_URL + `staff/page/0/1000/asc/id/${name?name:"%20"}`,{
      headers:authHeader()
  }).then(res=>{
 
    // setstaffData((res.data?.Index).map(user=>({ value:user.id,label:user.staff_Name})))
  })
 }


const onChangestaffdata = (e) => {
  // console.log(e.value);
  setstaff(Number(e.value));
  setstaffvalue({ value: e.value, label: e.label });
};
  const [currentPage, setCurrentPage] = useState(1);
  const [getid, setgetid] = useState(0);

useEffect(() => {
  axios.get(BASE_URL + 'staff/page/0/100/asc/id/%20',{
      headers:authHeader()
  }).then(res=>{
      setstaffData((res.data.Index).map(user=>({ value:user.id,label:user.staff_Name})))
  })
  // setCurrentPage(Number(pointindex));
// 
  // setgetid(Number(pointindex));

}, []);

const [startDate, setStartDate] = useState([])
const [endDate, setendDate] = useState([])

useEffect(() => {
  axios
    .get(BASE_URL+`salesexp/report/${staff}/${startDate}/${endDate}`, { headers: authHeader() })
    .then(response1 => {
      if (response1.data && Array.isArray(response1.data)) {
        const newdata = response1.data
          .map(item => ({
            date: item.expdate,
            homeTown: item.hometown,
            travel: item.expenseReportItemsDTO.map(item1 => ({
              from: item1.travelfrom,
              to: item1.travelto,
              mode: item1.modeoftravel,
              fare: item1.travelfare,
              status:item1.status,
            })),
            dailyAllowance: item.dailyallownces,
            acceptedAllowance: item.approvedexpensebyadmin,
            otherExpenses: item.expenseReportItemsDTO.map(item1 => ({
              type: item1.otherexp,
              amount: item1.otherexpamount,
            })),
            numberOfVisits: item.countvisit,
            scvalue: item.secondaryordervalue,
          }))
          .sort((a, b) => (a.homeTown === "WITHIN CITY" ? -1 : 1));

        setReportData(newdata);
      }
    })
    .catch(error => {
      console.error("Error fetching sales expense report:", error);
    });
}, [staff,startDate,endDate]);
const reportData1 = [
  {
    date: "1/1/2025",
    homeTown: "Within City",
    travel: [
      { from: "Virar", to: "Ambernath", mode: "Bus", fare: 200, status: 'Pending' },
      { from: "Vasai", to: "Matunga", mode: "Manual", fare: 100, status: 'Approved' },
      { from: "Villeparle", to: "Vasai", mode: "Manual", fare: 150, status: 'Rejected' },
      { from: "Andheri", to: "Bhandup", mode: "Manual", fare: 300, status: 'Pending' },
    ],
    dailyAllowance: 350,
    acceptedAllowance: 300, // This will come from the DB
    otherExpenses: [
      { type: "Mobile Recharge", amount: 300 },
      { type: "Courier", amount: 100 },
    ],
    numberOfVisits: 4, 
    scvalue:400
  },
  {
    date: "1/2/2025",
    homeTown: "Outside City",
    travel: [
      { from: "Gujrat", to: "Gujrat", mode: "Manual", fare: 200, status: 'Approved' },
      { from: "Gujrat", to: "Gujrat", mode: "Manual", fare: 100, status: 'Approved' },
      { from: "Gujrat", to: "Gujrat", mode: "Manual", fare: 150, status: 'Pending' },
    ],
    dailyAllowance: 1700,
    acceptedAllowance: 1600, // This will come from the DB
    otherExpenses: [],
    numberOfVisits: 4, 
    scvalue:400
  },
  {
    date: "1/3/2025",
    homeTown: "Within City",
    travel: [
      { from: "Virar", to: "Bhayander", mode: "Manual", fare: 200, status: 'Approved' },
      { from: "Vasai", to: "MiraRoad", mode: "Manual", fare: 100, status: 'Rejected' },
    ],
    dailyAllowance: 350,
    acceptedAllowance: 300, // This will come from the DB
    otherExpenses: [],
    numberOfVisits: 4, 
    scvalue:400    },
];

 console.log(reportData)
 const handleDateChange = (e) => {
  const selectedDate = new Date(e.target.value);
  const formattedDate = selectedDate.toISOString().split("T")[0]; // Formats to YYYY-MM-DD
  setStartDate(formattedDate);
};
const handleDateChangeen = (e) => {
  const selectedDate = new Date(e.target.value);
  const formattedDate = selectedDate.toISOString().split("T")[0]; // Formats to YYYY-MM-DD
  setendDate(formattedDate);
};

  return (
    <div className="card">



      <div className="card-header card-title">
        <span>Sales Staff Expenses Report</span>
      </div>
      


      <div className="card-body">
      <div className="row">
  <div className="col-md-12">
  <label className="form-label">Select Staff</label>
                      <Select
                        value={staffvalue}
                        options={staffData}
                        onChange={(e) => onChangestaffdata(e)}
                        onInputChange={(e) => customFilter(e) }
                      />  
                        </div>
    <div className="col-md-4">
    <label className="form-label" >Select Start Date</label>

<input type="date" className="form-control" onChange={(e)=>{handleDateChange(e)}}/>
    </div>

    <div className="col-md-4">
    <label className="form-label">Select End Date</label>

<input type="date" className="form-control"  onChange={(e)=>{handleDateChangeen(e)}}/>
    </div>

 
 </div>
{staff && startDate && endDate &&
        <table className="expense-table">
          <thead>
            <tr>
              <th className="borderpixle">Date</th>
              <th className="borderpixle">Home Town</th>
              <th className="borderpixle">From</th>
              <th className="borderpixle">To</th>
              <th className="borderpixle">Mode</th>
              <th className="borderpixle">Fare</th>
              <th className="borderpixle">Daily Allowance as per Master</th>
              <th className="borderpixle">Nature of Other Exp.</th>
              <th className="borderpixle">Amount</th>
              <th className="borderpixle">Accepted Allowance</th>
              <th className="borderpixle">Day Total Expenses</th>
              <th className="borderpixle">Status</th>
              <th className="borderpixle">Accepted Total</th> {/* Added Accepted Total header */}
              <th className="borderpixle">Number of Visits</th> {/* Added Number of Visits header */}
              <th className="borderpixle">Secondary order Value    </th> {/* Added Number of Visits header */}

            </tr>
          </thead>
          <tbody>
            {reportData?.map((entry, index) => {
              const travelRows = entry.travel.length;
              const expenseRows = entry.otherExpenses.length || 1;
              const maxRows = Math.max(travelRows, expenseRows);
              const totalExpenses =
                entry.travel.reduce((sum, t) => sum + t.fare, 0) +
                entry.otherExpenses.reduce((sum, e) => sum + e.amount, 0) +
                entry.dailyAllowance;

              return Array.from({ length: maxRows }).map((_, i) => (
                <tr key={`${index}-${i}`}>
                  {i === 0 && (
                    <>
                      <td className="borderpixle" rowSpan={maxRows}>{entry.date}</td>
                      <td className="borderpixle" rowSpan={maxRows}>{entry.homeTown}</td>
                    </>
                  )}
                  {i < travelRows ? (
                    <>
                      <td className="borderpixle">{entry.travel[i].from}</td>
                      <td className="borderpixle">{entry.travel[i].to}</td>
                      <td className="borderpixle">{entry.travel[i].mode}</td>
                      <td className="borderpixle">{entry.travel[i].fare}</td>
                    </>
                  ) : (
                    <>
                      <td className="borderpixle"></td>
                      <td className="borderpixle"></td>
                      <td className="borderpixle"></td>
                      <td className="borderpixle"></td>
                    </>
                  )}
                                    {i === 0 && (
                    <td className="borderpixle" rowSpan={maxRows}>{entry.dailyAllowance}</td>
                  )}
                  {/* <td className="borderpixle">{entry.dailyAllowance}</td> */}
                  <td className="borderpixle">{entry.otherExpenses[i]?.type || ""}</td>
                  <td className="borderpixle">{entry.otherExpenses[i]?.amount || ""}</td>
                  {/* <td className="borderpixle">{entry.acceptedAllowance}</td> Display Accepted Allowance */}
                  {i === 0 && (
                    <td className="borderpixle" rowSpan={maxRows}>{entry.acceptedAllowance}</td>
                  )}
                  <td className="borderpixle">{i === 0 ? totalExpenses : ""}</td>
                  <td className="borderpixle">{entry.travel[i]?.status || ""}</td>
                  {/* <td className="borderpixle">{entry.acceptedAllowance}</td> Display Accepted Total */}
                  <td className="borderpixle">{i === 0 ? entry.acceptedAllowance : ""}</td>

                  {i === 0 && (
                    <td className="borderpixle" rowSpan={maxRows}>{entry.numberOfVisits}</td>
                  )}      
                     {i === 0 && (
                    <td className="borderpixle" rowSpan={maxRows}>{entry.scvalue}</td>
                  )}  
                          </tr>
              ));
            })}
          
            {/* <tr>
              <td colSpan="12" className="borderpixle"style={{color:'white'}}>  Expenses</td>
              <td colSpan="12" className="borderpixle"style={{color:'white'}}>  Expenses</td>
              

            </tr> */}
            {/* <tr>
              <td colSpan="12" className="borderpixle"style={{color:'white'}}>  Expenses</td>
              <td colSpan="12" className="borderpixle"style={{color:'white'}}>  Expenses</td>
              

            </tr> */}
            {/* <tr>
              <td colSpan="12" className="borderpixle"style={{color:'white'}}>  Expenses</td>
              <td colSpan="12" className="borderpixle"style={{color:'white'}}>  Expenses</td>
              

            </tr> */}
            {/* <tr>
              <td colSpan="12" className="borderpixle">Total Expenses</td>
              <td className="borderpixle">0</td>
              <td className="borderpixle">0</td>
              <td className="borderpixle">0</td>

            </tr> */}
          </tbody>
        </table>}
      </div>
    </div>
  );
};

export default Salesexpensereprot;
