import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { FaEye, FaEyeSlash, FaFileUpload } from "react-icons/fa";
import { ImFolderUpload } from "react-icons/im";

const EditSalesExpense = () => {
  const { id, pointindex } = useParams();
  const navigate = useNavigate();
  const currentRole = JSON.parse(localStorage.getItem("user")).roles[0];
  const staffid = JSON.parse(localStorage.getItem("user")).id;
  //   const staffname = JSON.parse(localStorage.getItem("user")).username;
  console.log(currentRole);
  const [staffname, setStaffname] = useState("");
  const [role, setRole] = useState("");
  const [date, setDate] = useState("");
  const [hometown, setHometown] = useState("");
  const [dailyallownces, setdailyallownces] = useState(null);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [totalexp, setTotalExp] = useState("");
  const [salesexpenseitems, setSalesExpenseItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [salesexpwithincity, setsalesexpwithincity] = useState("");
  const [salesexpoutsidecity, setsalesexpoutsidecity] = useState("");
  const [salesexpdaytrip, setsalesexpdaytrip] = useState("");
  const [adminRemark, setAdminRemark] = useState("");
  const [rsmRemarks, setRsmRemark] = useState("");
  const emptyBlob = new Blob([""], { type: "application/octet-stream" });
  const [otherexpamounttotal, setOtherexpamounttotal] = useState("");
  const [travelfaretotal, setTravelfaretotal] = useState("");
  const [designation, setDesignation] = useState("");
  const [] = useState("");
  const fileRefs = useRef([]);
  const [rsmapprovedtotalamount, setrsmapprovedtotalamount] = useState("");
  const [adminapprovedtotalamount, setadminapprovedtotalamount] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [rsmapproved, setrsmapproved] = useState('')
   const [adminapproved, setAdminapproved] = useState('')

  if (fileRefs.current.length !== salesexpenseitems.length) {
    fileRefs.current = salesexpenseitems.map((_, index) => fileRefs.current[index] || React.createRef());
  }

  const disableCondition = JSON.parse(localStorage.getItem("userId")) === staffid;
  console.log(disableCondition);
  console.log(files2)

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();

    formData.append("expdate", date);
    formData.append("staffid", staffida);
    formData.append("hometown", hometown);
    formData.append("dailyallownces", dailyallownces);
    formData.append("totalexp", totalexp);
    formData.append("salesexpenseitems", JSON.stringify(salesexpenseitems));
    formData.append("adminRemarks", adminRemark);
    formData.append("rsmRemarks", rsmRemarks);
    formData.append("staffname", staffname);
    formData.append("designation", designation);
    formData.append("travelfaretotal", travelfaretotal);
    formData.append("otherexpamounttotal", otherexpamounttotal);

    console.log(files2.length);
    if (files2.length === 0) {
      formData.append("file", new Blob([], { type: "application/octet-stream" }));
    } else {
      for (let i = 0; i < files2.length; i++) {
        files2[i] ? formData.append("file", files2[i]) : formData.append("file", null);
      }
    }

    // if (files2.length > 0) {
    //   for (let i = 0; i < files2.length; i++) {
    //     files2[i] ? formData.append("file", files2[i]) : formData.append("file", null);
    //   }
    // }

    console.log(files2);

    console.log(formData);

    try {
      const response = await axios.put(`${BASE_URL}salesexp/${id}`, formData, {
        headers: authHeader(),
      });

      console.log(response.data);

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data has been saved successfully!",
      });
      navigate("/salesexpense/1");
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
const [status, setstatus] = useState('')
  // /
  const [datbaseexpenses, setDatbaseexpenses] = useState([])

  const [staffida, setStaffid] = useState('')
  const [rsmFinalStatus, setRsmFinalStatus] = useState(null); 
const [adminFinalStatus, setAdminFinalStatus] = useState(false);
  async function getSalesExpenseData() {
    try {
      const [response1, response2] = await Promise.all([
        axios.get(`http://43.225.54.138:8080/scm/api/salesexp/item/${id}`, {
          headers: authHeader(),
        }),
        axios.get(`http://43.225.54.138:8080/scm/api/salesexp/${id}`, {
          headers: authHeader(),
        }),
      ]);
      setDatbaseexpenses(response2.data.salesExpenseItems?.map((data) => ({
        id: data?.id,
        travelfrom: data?.travelfrom,
        travelto: data?.travelto,
        modeoftravel: data?.modeoftravel,
        travelfare: data?.travelfare,
        otherexp: data?.otherexp,
        otherexpamount: data?.otherexpamount,
        total: data?.total,
        rsmstatus:data?.rsmstatus,adminstatus:data?.adminstatus,
        approvalbyrsm: data?.approvalbyrsm,
        approvedexpensebyrsm: data?.approvedexpensebyrsm,
        approvalbyadmin: data?.approvalbyadmin,
        approvedexpensebyadmin: data?.approvedexpensebyadmin,
        image: response1.data?.find((item) => item.salesExpenseItems.id === data.id)?.salesexpfilelocation ?? null,
        imageid: response1.data.find((item) => item.salesExpenseItems.id === data.id)?.id || null,
      })))

      const rows = response2.data.salesExpenseItems?.map((data) => ({
        id: data?.id,
        travelfrom: data?.travelfrom,
        travelto: data?.travelto,
        modeoftravel: data?.modeoftravel,
        travelfare: data?.travelfare,adminstatus:data?.adminstatus,
        otherexp: data?.otherexp,
        rsmstatus:data?.rsmstatus,
        otherexpamount: data?.otherexpamount,
        total: data?.total,
        approvalbyrsm: data?.approvalbyrsm,
        approvedexpensebyrsm: data?.approvedexpensebyrsm,
        approvalbyadmin: data?.approvalbyadmin,
        approvedexpensebyadmin: data?.approvedexpensebyadmin,
        image: response1.data?.find((item) => item.salesExpenseItems.id === data.id)?.salesexpfilelocation ?? null,
        imageid: response1.data.find((item) => item.salesExpenseItems.id === data.id)?.id || null,
      }));







      const getFinalStatus = (rows) => {
        const rsmStatus=    rows.every(row => row.rsmstatus) ? true : false;
        const adminStatus=  rows.every(row => row.adminstatus) ? true : false;
          setRsmFinalStatus(rows.every(row => row.rsmstatus) ? true : false);
          setAdminFinalStatus(rows.every(row => row.adminstatus) ? true : false);
console.log( rows)
      };
      
      const finalResult = getFinalStatus(response2.data.salesExpenseItems);
      
      console.log(finalResult);



      setDate(response2.data.expdate);
      setStaffid(Number(response2.data.staffid));
      setHometown(response2.data.hometown);
      setdailyallownces(response2.data.dailyallownces);
      setrsmapproved(response2.data.createbyrsmname);
      setAdminapproved(response2.data.createbyadminname);
      setRemarks(response2.data.remarks);
      setSalesExpenseItems(rows);
      setTotalExp(response2.data.totalexp);
      setStaffname(response2.data.staffname);
      setRole(response2.data.designation);
      setAdminRemark(response2.data.adminRemarks);
      setRsmRemark(response2.data.rsmRemarks);  
      console.log(response2.data)
      setTravelfaretotal(response2.data.travelfaretotal);
      setOtherexpamounttotal(response2.data.otherexpamounttotal);
      setDesignation(response2.data.designation);
  setstatus(response2.data.status)
      const images = response2.data.salesExpenseItems?.map((data) =>
        data.salesExpenseImages !== null
          ? data.salesExpenseImages
          : { salesexpfilelocation: "SalesAgent/_exp_1_empty-file.txt", salesexpfilename: "empty-file.txt" }
      );
  
      console.log("Images:", images);
      setFiles(images);
    } catch (error) {
      console.error("Error:", error.message);
    }
  }
  
  async function salesExpValues() {
    try {
      const response = await axios.get(`${BASE_URL}staff/getById/${staffid}`, {
        headers: authHeader(),
      });

      setsalesexpwithincity(response.data.salesexpwithincity);
      setsalesexpoutsidecity(response.data.salesexpoutsidecity);
      setsalesexpdaytrip(response.data.salesexpdaytrip);
    } catch (error) {
      console.log(error);
    }
  }
  console.log( staffid, staffida);

  const handleFileChangeInItems = (event, index) => {
    console.log(event);
    console.log(index);
    const image = event.target.files[0];
    const fileArray = [...files];

    const maxSize = 5 * 1024 * 1024;
    if (image && image.size <= maxSize) {
      if ((image && image.type === "image/jpeg") || (image && image.type === "image/jpg") || (image && image.type === "image/png" && image.size <= maxSize)) {
        // File is a valid JPG image
        fileArray[index] = image;
        setFiles(fileArray);
        setFiles2(fileArray);
        // You can upload the file or perform other actions here
      } else {
        if ((image && image.type !== "image/jpeg") || (image && image.type !== "image/jpg") || (image && image.type !== "image/png")) {
          Swal.fire({
            title: "Please select a valid JPG,PNG,JPEG image.",
            icon: "warning",
            button: {
              ok: "Ok",
            },
          });
        }
      }
    } else {
      Swal.fire({
        title: "Please select an image that is no larger than 500KB.",
        icon: "warning",
        button: {
          ok: "Ok",
        },
      });
    }

    const fileInput = document.getElementById(`fileInput-${event.target.id.split("-")[1]}`);
    if (fileInput) {
      fileInput.value = null;
    }
  };

  // const handleRemoveFile = (e, index) => {
  //   e.preventDefault();
  //   const newFiles = files.filter((_, ind) => ind !== index);

  //   console.log(newFiles);
  //   setFiles(newFiles);

  //   const fileInput = document.getElementById(`fileInput-${e.target.id.split("-")[1]}`);
  //   if (fileInput) {
  //     fileInput.value = null;
  //   }
  // };

  async function deleteImage(id) {
    try {
      const response = await axios.delete(`${BASE_URL}salesexp/${id}`, {
        headers: authHeader(),
      });
    } catch (error) {
      console.log(error);
    }
  }

  const removeImage = async (e, index) => {
    e.preventDefault();

    console.log(files[index]);

    deleteImage(files[index].id);

    const images = [...files];

    // Create an empty blob and set it at the specified index
    images[index] = undefined; // You can specify the type according to your needs

    console.log(images);

    setFiles(images);
  };

  function removeRow(index) {
    const rows = salesexpenseitems.filter((_, ind) => ind !== index);
    let totalExp = 0;
    if (rows.length > 0) {
      totalExp = rows
        .map((data) => data.total)
        .reduce((acc, curr) => {
          acc += Number(curr);
          return Number(acc);
        });
      setTotalExp(Number(Number(totalExp) + Number(dailyallownces)));
    } else {
      setTotalExp(dailyallownces);
    }

    const newFiles = files.filter((_, ind) => ind !== index);

    console.log(newFiles);
    setFiles(newFiles);

    // const fileInput = document.getElementById(`fileInput-${e.target.id.split("-")[1]}`);
    // if (fileInput) {
    //   fileInput.value = null;
    // }

    setSalesExpenseItems(rows);
  }

  function handleAdminApprovedExpenseChange(e, index) {
    const rows = [...salesexpenseitems];
    const value = e.target.value;

    rows[index].approvedexpensebyadmin = value;

    const totalexp = rows
      .map((data) => data.total)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    const totalApprovalByRsm = rows
      .map((data) => data.approvedexpensebyadmin)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    setadminapprovedtotalamount(totalApprovalByRsm);

    setSalesExpenseItems(rows);
    setTotalExp(Number(totalexp)+Number(dailyallownces));
  }

  function handleRsmApprovedExpenseChange(e, index) {
    const rows = [...salesexpenseitems];
    const value = e.target.value;

    rows[index].approvedexpensebyrsm = value;

    const totalexp = rows
      .map((data) => data.total)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    const totalApprovalByRsm = rows
      .map((data) => data.approvedexpensebyrsm)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    setrsmapprovedtotalamount(totalApprovalByRsm);

    setSalesExpenseItems(rows);
    setTotalExp(Number(totalexp)+Number(dailyallownces));
  }

  function approvalByRsm(e, index) {
    const value = e.target.value;
    const row = [...salesexpenseitems];
    row[index].approvalbyrsm = e.target.value;
    if (value === "Accept") {
      row[index].approvedexpensebyrsm = row[index].total;
    } else if (value === "Reject") {
      row[index].approvedexpensebyrsm = 0;
    }

    const totalApprovalByRsm = row
      .map((data) => data.approvedexpensebyrsm)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    setrsmapprovedtotalamount(totalApprovalByRsm);

    setSalesExpenseItems(row);
  }

  function approvalByAdmin(e, index) {
    const value = e.target.value;
    const row = [...salesexpenseitems];
    row[index].approvalbyadmin = e.target.value;
    if (value === "Accept") {
      row[index].approvedexpensebyadmin = row[index].approvedexpensebyrsm;
    } else if (value === "Reject") {
      row[index].approvedexpensebyadmin = 0;
    }

    const totalApprovalByRsm = row
      .map((data) => data.approvedexpensebyadmin)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    setadminapprovedtotalamount(totalApprovalByRsm);
    setSalesExpenseItems(row);
  }

  function hometownAndDailyAllowanceHandler(e) {
    if (hometown.length > 0 && e.target.value === "Select..." && salesexpenseitems.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Please select the trip",
        confirmButtonText: "Got it!",
      });
      return;
    }
    const value = e.target.value;
    let totalValue = 0;
    let dailyallownceValue = 0;
    console.log(role[0] === "ROLE_ADMIN");
    if (role[0] == "ROLE_RSM" && value === "WITHIN CITY") {
      dailyallownceValue = salesexpwithincity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_RSM" && value === "OUTSIDE CITY") {
      dailyallownceValue = salesexpoutsidecity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_RSM" && value === "DAYTRIP CITY") {
      dailyallownceValue = salesexpdaytrip;
      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] == "ROLE_ASM" && value === "WITHIN CITY") {
      dailyallownceValue = salesexpwithincity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASM" && value === "OUTSIDE CITY") {
      dailyallownceValue = salesexpoutsidecity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASM" && value === "DAYTRIP CITY") {
      dailyallownceValue = salesexpdaytrip;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] == "ROLE_ASE" && value === "WITHIN CITY") {
      dailyallownceValue = salesexpwithincity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASE" && value === "OUTSIDE CITY") {
      dailyallownceValue = salesexpoutsidecity;
      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASE" && value === "DAYTRIP CITY") {
      dailyallownceValue = salesexpdaytrip;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        }
      }
    }

    setHometown(value);
    setdailyallownces(dailyallownceValue);
    setTotalExp(salesexpenseitems.length > 0 ? totalValue : dailyallownceValue);
  }

  function handleExpenseChange() {
    let row = {
      travelfrom: "",
      travelto: "",
      modeoftravel: "",
      travelfare: "",
      otherexp: "",
      otherexpamount: "",
      total: "",
      image:null,
      imageid:null,    };

    setSalesExpenseItems([...salesexpenseitems, row]);
  }

  function handleTravelFare(e, index) {
    const row = [...salesexpenseitems];
    row[index].travelfare = Number(e.target.value);
    row[index].total = Number(Number(e.target.value) + Number(row[index].otherexpamount));

    const totalExp = row
      .map((data) => data.total)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      });

    const totaltravelfare = row
      ?.map((data) => data.travelfare)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);
    console.log(totalExp);
    setTotalExp(Number(Number(totalExp) + Number(dailyallownces)));
    setSalesExpenseItems(row);
    setTravelfaretotal(totaltravelfare);
  }

  function handleOtherExpAmount(e, index) {
    const row = [...salesexpenseitems];
    row[index].otherexpamount = Number(e.target.value);
    row[index].total = Number(Number(e.target.value) + Number(row[index].travelfare));

    const totalExp = row
      .map((data) => data.total)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      });

    const totalotherexpamount = row
      ?.map((data) => data.otherexpamount)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    console.log(totalExp);
    setTotalExp(Number(Number(totalExp) + Number(dailyallownces)));
    setSalesExpenseItems(row);
    setOtherexpamounttotal(totalotherexpamount);
  }

  console.log("salesexpenseitems", salesexpenseitems);
  console.log("Files", files);
  console.log(adminRemark);

  useEffect(() => {
    getSalesExpenseData();
    salesExpValues();
  }, []);

  for (let i = 0; i < files.length; i++) {
    console.log(files[i]?.salesexpfilename !== "empty-file.txt" || (files[i]?.size !== undefined && files[i]?.size !== 0));
  }

  return (
    <MDBCard>
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">Edit Sales Expense</div>
        <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/salesexpense/1`}>
          <Button>Back</Button>
        </Link>
      </div>

      <Form className="product2">
        <div className="row">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Staff Name</Form.Label>
            <Form.Control type="text" value={staffname} disabled />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Designation</Form.Label>
            <Form.Control type="text" value={role} disabled />
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="formBasicName">
            <Form.Label className="cname2">Date</Form.Label>
            <Form.Control type="date" name="date"                         
// disabled={
//   staffida !== staffid || 
//   (designation === 'ROLE_RSM' ? adminFinalStatus === true : (adminFinalStatus === true || rsmFinalStatus === true))
// }

disabled={
  staffida !== staffid||status=='Complete'
  //  || 
  // (designation === 'ROLE_RSM' ? adminFinalStatus === true : (adminFinalStatus === true || rsmFinalStatus === true))
}
max={new Date().toISOString().split("T")[0]} autoComplete="off" value={date} onChange={(e) => setDate(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="formBasicName">
            <Form.Label className="cname2">Type  </Form.Label>
            <Form.Select className="w-100 h-50" name="hometown"                     
disabled={
  staffida !== staffid||status=='Complete'
  //  || 
  // (designation === 'ROLE_RSM' ? adminFinalStatus === true : (adminFinalStatus === true || rsmFinalStatus === true))
}
            // disabled={staffida==staffid?false:true}
 value={hometown} onChange={(e) => hometownAndDailyAllowanceHandler(e)}>
              <option>Select...</option>
              <option value="WITHIN CITY">WITHIN CITY</option>
              <option value="OUTSIDE CITY">OUTSIDE CITY</option>
              <option value="DAYTRIP CITY">DAYTRIP CITY</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="formBasicName">
            <Form.Label className="cname2">Daily Allowance</Form.Label>
            <Form.Control type="text" autoComplete="off" disabled value={dailyallownces} />
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">Admin Remarks</Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              disabled={currentRole === "ROLE_ADMIN" ? false : true||status=='Complete'}
              value={adminRemark}
              placeholder="Admin Remark"
              name="remark"
              onChange={(e) => setAdminRemark(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">RSM Remarks</Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              disabled={currentRole === "ROLE_RSM" ? false : true||status=='Complete'}
              value={rsmRemarks}
              placeholder="RSM Remark"
              name="remark"
              onChange={(e) => setRsmRemark(e.target.value)}
            />
          </Form.Group> 
        </div>

        <br></br>

        <Button variant="primary" style={{ width: "auto", zIndex: "-1 !important", marginTop: "20px" }} disabled={salesexpenseitems?.length === 6 ? true : false} onClick={handleExpenseChange}>
          <span style={{ fontSize: "1rem" }}>+</span>
        </Button>

        <div className="table-responsive-2">
          <Table striped hover size="sm">
            <thead className="p-3 mb-2 bg-success text-white">
              <tr>

                <th>SR NO.</th>
                <th>Travel From</th>
                <th>Travel To</th>
                <th>Mode Of Travel</th>
                <th>Travel Fare</th>
                <th>Other Expense Name</th>
                <th>Other Expense Amount</th>
                <th colSpan={2}>Total</th>
                <th>Image</th>
          {designation=='ROLE_RSM'?"":<>
            <th colSpan={2}>RSM Approval</th>
                <th>RSM Approved Amount</th>

                <th colSpan={2}>RSM Approved By</th>
             </> }  
                <th colSpan={2}>Admin Approval</th>
                <th>Admin Approved Amount</th>
                <th colSpan={2}>Admin Approved By</th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {salesexpenseitems &&
                salesexpenseitems?.map((data, index) => (
                  <tr className="sale-expense-row" key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          className="sales-expense-input-width"
                          value={data.travelfrom}
                          size="sm"
                          disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}
                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].travelfrom = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.travelto}
                          className="sales-expense-input-width"
                          size="sm"
                          // disabled={staffida !== staffid || data.rsmstatus === 'true'}
                          // disabled={!(staffida == staffid || data.rsmstatus === 'true')}
                          disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].travelto = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.modeoftravel}
                          className="sales-expense-input-width"
                          size="sm"
                          //  disabled={staffida==staffid?false:true}
                          disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].modeoftravel = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control className="sales-expense-input-width"                       
                            //  disabled={staffida==staffid?false:true}
                            disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

 type="number" value={data.travelfare} size="sm" onChange={(e) => handleTravelFare(e, index)} />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.otherexp}
                          className="sales-expense-input-width"
                          size="sm"
                          //  disabled={staffida==staffid?false:true}
                          disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].otherexp = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="number"
                          value={data.otherexpamount}
                          className="sales-expense-input-width"
                          size="sm"
                          //  disabled={staffida==staffid?false:true}
                          disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

                          onChange={(e) => {
                            handleOtherExpAmount(e, index);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td></td>
                    <td>
                      <Form.Group as={Col} className="sale-expense-input" style={{ width: "100px" }}>
                        <Form.Control type="number"                       
                            //  disabled={staffida==staffid?false:true}
                            disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

 value={data.total} size="sm" readOnly />
                      </Form.Group>
                    </td>

                    <td>
                      {/* {files[index]?.salesexpfilename === "empty-file.txt" || files[index]?.salesexpfilename === "blob" || files[index] === undefined ? (
                        <div>
                          <Form.Group as={Col} className="sale-expense-input">
                            <Form.Control type="file" ref={fileRefs.current[index]} style={{ display: "none" }} onChange={(e) => handleFileChangeInItems(e, index)} />
                          </Form.Group>
                          <ImFolderUpload style={{ cursor: "pointer" }} onClick={() => fileRefs.current[index].current.click()} />
                        </div>
                      ) : (
                        <div style={{ width: "200px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <div className="d-flex align-items-center mx-auto gap-2">
                            <p className="mt-3">{files[index]?.name ? files[index]?.name : files[index]?.salesexpfilename}</p>
                            <button onClick={(e) => removeImage(e, index)}>X</button>
                          </div>
                        </div>
                      )} */}
                    <div className="spacealong">
                    {/* <span>{data.image}
                      <FaEye />
                      </span> */}
               { data.image && !data.image.includes("empty-file.txt") &&<>
       <span
        style={{ cursor: "pointer", color: "blue" }}
        onClick={() => setSelectedImage(`http://43.225.54.138:8080/scm/${data.image}`)}
      >
        <FaEye />
      </span>

      {/* Full-Screen Modal */}
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={() => setSelectedImage(null)}
        >
          <img
            src={selectedImage}
            alt="Full Screen Preview"
            style={{ maxWidth: "90%", maxHeight: "90%", borderRadius: "10px" }}
          />
        </div>
      )}
    </> }
                      <span>
                          <Form.Group as={Col} className="sale-expense-input">
                            <Form.Control type="file" ref={fileRefs.current[index]} style={{ display: "none" }} onChange={(e) => handleFileChangeInItems(e, index)} />
                          </Form.Group>
                     
                       {Number(staffida)==staffid?   <ImFolderUpload style={{ cursor: "pointer" }} onClick={() => fileRefs.current[index].current.click()} />
:''}
                        </span>
                      </div>

                    </td>

             {designation=='ROLE_RSM'?   <>
             
             </>:<>
             {/* {data.approvedexpensebyrsm} */}
             <td colSpan={2}>
  <Form.Group className="sales-expense-input">
    <Form.Select
      size="sm"
      value={data?.approvalbyrsm}
      disabled={ data.adminstatus=='true'?true:false||currentRole == "ROLE_RSM"?false:true  }
      className="mt-2 sales-expense-input-width"
      onChange={(e) => {
        approvalByRsm(e, index);
      }}
    >
      <option value="unchecked">Pending</option>
      <option value="Accept">Approve</option>
      <option value="Reject">Reject</option>
    </Form.Select>  
  </Form.Group>
</td>


                    <td>
                    <Form.Group as={Col} className="sale-expense-input" >
          <Form.Control
            type="number"
            className="sales-expense-input-width"
            size="sm"
            value={data?.approvedexpensebyrsm}
            disabled={ data.adminstatus=='true'?true:false||currentRole == "ROLE_RSM"?false:true  }
            onChange={(e) => handleRsmApprovedExpenseChange(e, index)}
          />
        </Form.Group>


              {/* {datbaseexpenses
  .filter((item) => item.id === data.id)
  .map((matchedItem, index) => (
    matchedItem.approvedexpensebyrsm==0 ? (
      <Form.Group as={Col} className="sale-expense-input" key={matchedItem.id}>
        <Form.Control
          type="number"
          className="sales-expense-input-width"
          size="sm"
          value={data?.approvedexpensebyrsm}
         
          onChange={(e) => handleRsmApprovedExpenseChange(e, index)}
        />
      </Form.Group>
    ) : (
      <div key={matchedItem.id}>
        ₹{matchedItem.approvedexpensebyrsm}
      </div>
    )
  ))
} */}
    
                  
                

                    </td>
                    <td colSpan={2}>
                      <Form.Group className="sales-expense-input">
                  {rsmapproved?<soan>{rsmapproved}</soan>:''}
                      </Form.Group>
                    </td></>}   


              {designation!=='ROLE_RSM'?   <>
                    <td colSpan={2}>
                      <Form.Group className="sales-expense-input">
                    { data.rsmstatus=='true'?
                      <Form.Select
                          size="sm"
                          value={data?.approvalbyadmin}
                          disabled={ data.adminstatus=='true'?true:false||currentRole == "ROLE_ADMIN"?false:true  }
                          className="mt-2 sales-expense-input-width"
                          onChange={(e) => {
                            approvalByAdmin(e, index);
                          }}
                        >
                          <option value="unchecked">Pending</option>
                          <option value="Accept">Approve</option>
                          <option value="Reject">Reject</option>
                        </Form.Select>  :     <Form.Select
                          size="sm"
                          value={data?.approvalbyadmin}
                          disabled='true'
                          className="mt-2 sales-expense-input-width"
                        
                        >
                          <option value="unchecked">Pending</option>
                      
                        </Form.Select> 
}
                     </Form.Group>
                    </td></>:  <td colSpan={2}>
                      <Form.Group className="sales-expense-input">
                    {1==1?
                      <Form.Select
                          size="sm"
                          value={data?.approvalbyadmin}
                          disabled={ data.adminstatus=='true'?true:false||currentRole == "ROLE_ADMIN"?false:true  }
                          className="mt-2 sales-expense-input-width"
                          onChange={(e) => {
                            approvalByAdmin(e, index);
                          }}
                        >
                          <option value="unchecked">Pending</option>
                          <option value="Accept">Approve</option>
                          <option value="Reject">Reject</option>
                        </Form.Select>  :     <Form.Select
                          size="sm"
                          value={data?.approvalbyadmin}
                          disabled='true'
                          className="mt-2 sales-expense-input-width"
                        
                        >
                          <option value="unchecked">Pending</option>
                      
                        </Form.Select> 
}
                     </Form.Group>
                    </td>
}




           {     designation!=='ROLE_RSM'?   <> 
             <td>
                      <Form.Group as={Col} className="sale-expense-input">
                     { data.rsmstatus=='true'?  <Form.Control
                         type="number"
                           size="sm"
                          className="sales-expense-input-width"
                          value={data?.approvedexpensebyadmin}
                          disabled={ data.adminstatus=='true'?true:false||currentRole == "ROLE_ADMIN"?false:true  }
                          onChange={(e) => handleAdminApprovedExpenseChange(e, index)}
                        />:  
                           <Form.Control
                         type="number"
                           size="sm"
                          className="sales-expense-input-width"
                          value={data?.approvedexpensebyadmin}
disabled="true"
                         />
                       }
                      </Form.Group>
                    </td></>:<>
                    <Form.Group as={Col} className="sale-expense-input">
                     { 1==1?  <Form.Control
                         type="number"
                           size="sm"
                          className="sales-expense-input-width"
                          value={data?.approvedexpensebyadmin}
                          disabled={ data.adminstatus=='true'?true:false||currentRole == "ROLE_ADMIN"?false:true  }
                          onChange={(e) => handleAdminApprovedExpenseChange(e, index)}
                        />:  
                           <Form.Control
                         type="number"
                           size="sm"
                          className="sales-expense-input-width"
                          value={data?.approvedexpensebyadmin}
disabled="true"
                         />
                       }
                      </Form.Group>
           </> }



                    <td colSpan={2}>
                      <Form.Group className="sales-expense-input">
{status=='Complete'?<soan>{adminapproved}</soan> :''}       
               </Form.Group>
                    </td>
                    <td>
                    {Number(staffida)==staffid?  <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control type="button"
                        disabled={staffida !== staffid || (designation === 'ROLE_RSM' ? data.adminstatus === 'true' : data.rsmstatus === 'true')}

                        className="btn btn-danger" value="X" size="sm" onClick={() => removeRow(index)} />
                      </Form.Group>:''}
                    </td>
                  </tr>
                ))}

              <tr className="sale-expense-row">
                <td colSpan={4} style={{ textAlign: "right" }}>
                  Travel Fare Total
                </td>

                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={travelfaretotal} size="sm" />
                  </Form.Group>
                </td>
                <td style={{ textAlign: "right" }}>Other Expense Total</td>

                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={otherexpamounttotal} size="sm" />
                  </Form.Group>
                </td>

                <td style={{ textAlign: "right", width: "max-content" }}>Total</td>

                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={totalexp} size="sm" />
                  </Form.Group>
                </td>

                <td colSpan={1}></td>
              {  designation!=='ROLE_RSM'?<>
                <td colSpan={2}>Rsm Approved Amount</td>
                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" 
 value={
  salesexpenseitems.reduce((sum, card) => sum + parseFloat(card.approvedexpensebyrsm || 0), 0) 
  + (salesexpenseitems.some(card => parseFloat(card.approvedexpensebyrsm || 0) > 0) ? dailyallownces : 0)
} 
                    size="sm" />
                  </Form.Group>
                </td>
              </> :""}
              {  designation!=='ROLE_RSM'?<>        <td colSpan={2}></td>

                <td colSpan={2}>Admin Approved Amount</td>
                </> :<>
                <td colSpan={1}></td>

<td colSpan={1}>Admin Approved Amount</td>
                </>}
                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text"
value={
  salesexpenseitems.reduce((sum, card) => sum + parseFloat(card.approvedexpensebyadmin || 0), 0) 
  + (salesexpenseitems.some(card => parseFloat(card.approvedexpensebyadmin || 0) > 0) ? dailyallownces : 0)
}                     
                     
                     size="sm" />
                  </Form.Group>
                </td>

                <td colSpan={2}></td>
                <td colSpan={2}></td>

              </tr>
              <tr>
                <td>Total Admin Approved Amount</td>
                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={ status=='Complete'? salesexpenseitems.reduce((sum, card) => sum + parseFloat(card.approvedexpensebyadmin || 0), 0)+dailyallownces:0.00 } size="sm" />
                  </Form.Group>
                </td>

                <td colSpan={15}></td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Button className="mt-5" variant="primary" style={{ width: "auto" }} onClick={handleSubmit}>
          <span style={{ fontSize: "1rem" }}>Submit</span>
        </Button>
      </Form>
    </MDBCard>
  );
};

export default EditSalesExpense;
