import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Form, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import { FaFileUpload } from "react-icons/fa";
import { ImFolderUpload } from "react-icons/im";

const CreateSalesExpense = () => {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("user")).roles;
  const staffid = JSON.parse(localStorage.getItem("user")).id;
  const staffname = JSON.parse(localStorage.getItem("user")).username;
  console.log(role[0], staffid, staffname);
  const [date, setDate] = useState("");
  const [hometown, setHometown] = useState("");
  const [dailyallownces, setdailyallownces] = useState(null);
  const [files, setFiles] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [totalexp, setTotalExp] = useState("");
  const [salesexpenseitems, setSalesExpenseItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [salesexpwithincity, setsalesexpwithincity] = useState("");
  const [salesexpoutsidecity, setsalesexpoutsidecity] = useState("");
  const [salesexpdaytrip, setsalesexpdaytrip] = useState("");
  const [adminRemark, setAdminRemark] = useState("");
  const [rsmRemarks, setRsmRemark] = useState("");
  const emptyBlob = new Blob([""], { type: "application/octet-stream" });
  const [otherexpamounttotal, setOtherexpamounttotal] = useState("");
  const [travelfaretotal, setTravelfaretotal] = useState("");
  const fileRefs = useRef([]);

  if (fileRefs.current.length !== salesexpenseitems.length) {
    fileRefs.current = salesexpenseitems.map((_, index) => fileRefs.current[index] || React.createRef());
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();

    formData.append("expdate", date);
    formData.append("staffid", staffid);
    formData.append("hometown", hometown);
    formData.append("dailyallownces", dailyallownces);
    formData.append("totalexp", totalexp);
    formData.append("salesexpenseitems", JSON.stringify(salesexpenseitems));
    formData.append("adminRemarks", adminRemark);
    formData.append("rsmRemarks", rsmRemarks);
    formData.append("staffname", staffname);
    formData.append("designation", role[0]);
    formData.append("travelfaretotal", travelfaretotal);
    formData.append("otherexpamounttotal", otherexpamounttotal);
    

    if (files.length === 0) {
      formData.append("file", emptyBlob, "empty-file.txt");
    }else {
      for (let i = 0; i < files.length; i++) {
        files[i] !== undefined ? formData.append("file", files[i]) : formData.append("file", emptyBlob, "empty-file.txt");
      }
    }

    console.log(formData);

    try {
      const response = await axios.post(`${BASE_URL}salesexp/`, formData, {
        headers: authHeader(),
      });

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data has been saved successfully!",
      });
      navigate("/salesexpense/1");
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function salesExpValues() {
    try {
      const response = await axios.get(`${BASE_URL}staff/getById/${staffid}`, {
        headers: authHeader(),
      });

      console.log(response);
      setsalesexpwithincity(response.data.salesexpwithincity);
      setsalesexpoutsidecity(response.data.salesexpoutsidecity);
      setsalesexpdaytrip(response.data.salesexpdaytrip);
    } catch (error) {
      console.log(error);
    }
  }

  const handleFileChangeInItems = (event, index) => {
    console.log(event);
    console.log(index);
    const image = event.target.files[0];
    const fileArray = [...files];

    const maxSize = 5 * 1024 * 1024;
    if (image && image.size <= maxSize) {
      if ((image && image.type === "image/jpeg") || (image && image.type === "image/jpg") || (image && image.type === "image/png" && image.size <= maxSize)) {
        // File is a valid JPG image
        fileArray[index] = image;
        setFiles(fileArray);
        // You can upload the file or perform other actions here
      } else {
        if ((image && image.type !== "image/jpeg") || (image && image.type !== "image/jpg") || (image && image.type !== "image/png")) {
          Swal.fire({
            title: "Please select a valid JPG,PNG,JPEG image.",
            icon: "warning",
            button: {
              ok: "Ok",
            },
          });
        }
      }
    } else {
      Swal.fire({
        title: "Please select an image that is no larger than 500KB.",
        icon: "warning",
        button: {
          ok: "Ok",
        },
      });
    }

    const fileInput = document.getElementById(`fileInput-${event.target.id.split("-")[1]}`);
    if (fileInput) {
      fileInput.value = null;
    }
  };

  // const handleRemoveFile = (e, index) => {
  //   e.preventDefault();
  //   const newFiles = files.filter((_, ind) => ind !== index);

  //   console.log(newFiles);
  //   setFiles(newFiles);

  //   const fileInput = document.getElementById(`fileInput-${e.target.id.split("-")[1]}`);
  //   if (fileInput) {
  //     fileInput.value = null;
  //   }
  // };

  // const removeImage = (e, index) => {
  //   e.preventDefault();
  //   console.log(index);
  //   const images = [...files];

  //   images.splice(index, 1);

  //   console.log(images);

  //   setFiles(images);
  // };

  const removeImage = (e, index) => {
    e.preventDefault();
    console.log(index);

    const images = [...files];

    // Create an empty blob and set it at the specified index
    images[index] = undefined; // You can specify the type according to your needs

    console.log(images);

    setFiles(images);
  };

  function removeRow(index) {
    const rows = salesexpenseitems.filter((_, ind) => ind !== index);
    let totalExp = 0;
    if (rows.length > 0) {
      totalExp = rows
        .map((data) => data.total)
        .reduce((acc, curr) => {
          acc += Number(curr);
          return Number(acc);
        });
      setTotalExp(Number(Number(totalExp) + Number(dailyallownces)));
    } else {
      setTotalExp(dailyallownces);
    }

    const newFiles = files.filter((_, ind) => ind !== index);

    console.log(newFiles);
    setFiles(newFiles);

    // const fileInput = document.getElementById(`fileInput-${e.target.id.split("-")[1]}`);
    // if (fileInput) {
    //   fileInput.value = null;
    // }

    setSalesExpenseItems(rows);
  }

  function hometownAndDailyAllowanceHandler(e) {
    if (hometown.length > 0 && e.target.value === "Select..." && salesexpenseitems.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Warning!",
        text: "Please select the trip",
        confirmButtonText: "Got it!",
      });
      return;
    }
    const value = e.target.value;
    let totalValue = 0;
    let dailyallownceValue = 0;
    console.log(role[0] === "ROLE_ADMIN");
    if (role[0] == "ROLE_RSM" && value === "WITHIN CITY") {
      dailyallownceValue = salesexpwithincity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_RSM" && value === "OUTSIDE CITY") {
      dailyallownceValue = salesexpoutsidecity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_RSM" && value === "DAYTRIP CITY") {
      dailyallownceValue = salesexpdaytrip;
      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] == "ROLE_ASM" && value === "WITHIN CITY") {
      dailyallownceValue = salesexpwithincity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASM" && value === "OUTSIDE CITY") {
      dailyallownceValue = salesexpoutsidecity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASM" && value === "DAYTRIP CITY") {
      dailyallownceValue = salesexpdaytrip;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] == "ROLE_ASE" && value === "WITHIN CITY") {
      dailyallownceValue = salesexpwithincity;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASE" && value === "OUTSIDE CITY") {
      dailyallownceValue = salesexpoutsidecity;
      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "DAYTRIP CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpdaytrip) + Number(dailyallownceValue));
        }
      }
    } else if (role[0] === "ROLE_ASE" && value === "DAYTRIP CITY") {
      dailyallownceValue = salesexpdaytrip;

      if (salesexpenseitems?.length > 0) {
        if (hometown === "WITHIN CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpwithincity) + Number(dailyallownceValue));
        } else if (hometown === "OUTSIDE CITY") {
          totalValue = Number(Number(totalexp) - Number(salesexpoutsidecity) + Number(dailyallownceValue));
        }
      }
    }

    setHometown(value);
    setdailyallownces(dailyallownceValue);
    setTotalExp(salesexpenseitems.length > 0 ? totalValue : dailyallownceValue);
  }

  function handleExpenseChange() {
    let row = {
      travelfrom: "",
      travelto: "",
      modeoftravel: "",
      travelfare: "",
      otherexp: "",
      otherexpamount: "",
      total: "",
    };

    setSalesExpenseItems([...salesexpenseitems, row]);
  }

  function handleTravelFare(e, index) {
    const row = [...salesexpenseitems];
    row[index].travelfare = Number(e.target.value);
    row[index].total = Number(Number(e.target.value) + Number(row[index].otherexpamount));

    const totalExp = row
      .map((data) => data.total)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      });

    const totaltravelfare = row
      ?.map((data) => data.travelfare)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);
    console.log(totalExp);
    setTotalExp(Number(Number(totalExp) + Number(dailyallownces)));
    setSalesExpenseItems(row);
    setTravelfaretotal(totaltravelfare);
  }

  function handleOtherExpAmount(e, index) {
    const row = [...salesexpenseitems];
    row[index].otherexpamount = Number(e.target.value);
    row[index].total = Number(Number(e.target.value) + Number(row[index].travelfare));

    const totalExp = row
      .map((data) => data.total)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      });

    const totalotherexpamount = row
      ?.map((data) => data.otherexpamount)
      .reduce((acc, curr) => {
        acc += Number(curr);
        return Number(acc);
      }, 0);

    console.log(totalExp);
    setTotalExp(Number(Number(totalExp) + Number(dailyallownces)));
    setSalesExpenseItems(row);
    setOtherexpamounttotal(totalotherexpamount);
  }

  console.log("salesexpenseitems", salesexpenseitems);
  console.log("Files", files);
  console.log(adminRemark);

  useEffect(() => {
    salesExpValues();
  }, []);

  console.log(files[1]);

  return (
    <MDBCard>
      <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">ADD Sales Expense</div>
        <Link className="p-2 w-200 card-header card-title bd-highlight" to={`/salesexpense/1`}>
          <Button>Back</Button>
        </Link>
      </div>

      <Form className="product2">
        <div className="row">
          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Staff Name</Form.Label>
            <Form.Control type="text" value={staffname} disabled />
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label className="cname2">Designation</Form.Label>
            <Form.Control type="text" value={role[0]} disabled />
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="formBasicName">
            <Form.Label className="cname2">Date</Form.Label>
            <Form.Control type="date"    max={new Date().toISOString().split("T")[0]}  name="date" autoComplete="off" onChange={(e) => setDate(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="formBasicName">
            <Form.Label className="cname2">Type</Form.Label>
            <Form.Select className="w-100 h-50" name="hometown" value={hometown} onChange={(e) => hometownAndDailyAllowanceHandler(e)}>
              <option>Select...</option>
              <option value="WITHIN CITY">WITHIN CITY</option>
              <option value="OUTSIDE CITY">OUTSIDE CITY</option>
              <option value="DAYTRIP CITY">DAYTRIP CITY</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} md="4" controlId="formBasicName">
            <Form.Label className="cname2">Daily Allowance</Form.Label>
            <Form.Control type="text" autoComplete="off" disabled value={dailyallownces} />
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">Admin Remarks</Form.Label>
            <Form.Control
              as="textarea"
              autoComplete="off"
              disabled={role[0] === "ROLE_ADMIN" ? false : true}
              placeholder="Admin Remark"
              name="remark"
              onChange={(e) => setAdminRemark(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="formBasicName">
            <Form.Label className="cname2">RSM Remarks</Form.Label>
            <Form.Control as="textarea" autoComplete="off" disabled={role[0] === "ROLE_RSM" ? false : true} placeholder="RSM Remark" name="remark" onChange={(e) => setRsmRemark(e.target.value)} />
          </Form.Group>
        </div>

        <br></br>

        <Button variant="primary" style={{ width: "auto", zIndex: "-1 !important", marginTop: "20px" }} disabled={salesexpenseitems?.length === 6 ? true : false} onClick={handleExpenseChange}>
          <span style={{ fontSize: "1rem" }}>+</span>
        </Button>

        <div className="table-responsive-2">
          <Table striped responsive hover size="sm">
            <thead className="p-3 mb-2 bg-success text-white">
              <tr>
                <th>SR NO.</th>
                <th>Travel From</th>
                <th>Travel To</th>
                <th>Mode Of Travel</th>
                <th>Travel Fare</th>
                <th>Other Expense Name</th>
                <th>Other Expense Amount</th>
                <th>Image</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {salesexpenseitems &&
                salesexpenseitems?.map((data, index) => (
                  <tr className="sale-expense-row" key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.travelfrom}
                          size="sm"
                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].travelfrom = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.travelto}
                          size="sm"
                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].travelto = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.modeoftravel}
                          size="sm"
                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].modeoftravel = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control type="number" value={data.travelfare} size="sm" onChange={(e) => handleTravelFare(e, index)} />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="text"
                          value={data.otherexp}
                          size="sm"
                          onChange={(e) => {
                            const row = [...salesexpenseitems];
                            row[index].otherexp = e.target.value;
                            setSalesExpenseItems(row);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control
                          type="number"
                          value={data.otherexpamount}
                          size="sm"
                          onChange={(e) => {
                            handleOtherExpAmount(e, index);
                          }}
                        />
                      </Form.Group>
                    </td>

                    <td>
                      {files[index] !== undefined ? (
                        <div style={{ width: "200px", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <div className="d-flex align-items-center mx-auto gap-2">
                            <p className="mt-3">{files[index]?.name}</p>
                            <button onClick={(e) => removeImage(e, index)}>X</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Form.Group as={Col} className="sale-expense-input">
                            <Form.Control type="file" ref={fileRefs.current[index]} style={{ display: "none" }} onChange={(e) => handleFileChangeInItems(e, index)} />
                          </Form.Group>
                          <ImFolderUpload style={{ cursor: "pointer" }} onClick={() => fileRefs.current[index].current.click()} />
                        </div>
                      )}
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control type="number" value={data.total} size="sm" readOnly />
                      </Form.Group>
                    </td>

                    <td>
                      <Form.Group as={Col} className="sale-expense-input">
                        <Form.Control type="button" className="btn btn-danger" value="X" size="sm" onClick={() => removeRow(index)} />
                      </Form.Group>
                    </td>
                  </tr>
                ))}

              <tr className="sale-expense-row">
                <td colSpan={4} style={{ textAlign: "right" }}>
                  Travel Fare Total
                </td>

                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={travelfaretotal} size="sm" />
                  </Form.Group>
                </td>
                <td style={{ textAlign: "right" }}>Other Expense Total</td>

                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={otherexpamounttotal} size="sm" />
                  </Form.Group>
                </td>
                <td style={{ textAlign: "right", width: "max-content" }}>Grand Total</td>

                <td>
                  <Form.Group as={Col} className="sale-expense-input">
                    <Form.Control type="text" value={totalexp} size="sm" />
                  </Form.Group>
                </td>

                <td colSpan={2}></td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Button className="mt-5" variant="primary" style={{ width: "auto" }} onClick={handleSubmit}>
          <span style={{ fontSize: "1rem" }}>Submit</span>
        </Button>
      </Form>
    </MDBCard>
  );
};

export default CreateSalesExpense;
