import React, { useEffect } from "react";

const Ewaybill = () => {
  useEffect(() => {
    // document.getElementById("qerteww").style.display = "none";
    // document.getElementById("sidein").style.display = "none";

    const elements = ["naving", "1sidebar",'qerteww','sidein','closemenu', "qw", "swift", "logoo", "logoo1", "menu"];
    elements.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add("hidden");
      }
    });

    const newElement = document.getElementById("new");
    if (newElement) {
      newElement.classList.add("fullWidth");
    }
  }, []);
  const data =[
    {
      "hsn": "73239390",
      "product": "Dabba Deep 19x24 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "35.22",
      "taxableAmt": 12631.86,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Sauce Pan Regular 9x11 (22G) & STAINLESS STEEL UTENSILS",
      "quantity": "41.48",
      "taxableAmt": 14208.06,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Dabba Coriander 7x9 (26G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "58.40",
      "taxableAmt": 31799.49,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Sada 5.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.90",
      "taxableAmt": 7620.48,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Prem 7 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "22.98",
      "taxableAmt": 8806.68,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Prem 8 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "21.78",
      "taxableAmt": 8346.80,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass New Juicy 7 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "24",
      "taxableAmt": 10653.70,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Kadai Induction Bottom 11 (22G) & STAINLESS STEEL UTENSILS",
      "quantity": "22.24",
      "taxableAmt": 9411.25,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Sauce Pan Regular 9x11 (22G) Wire & STAINLESS STEEL UTENSILS",
      "quantity": "20.58",
      "taxableAmt": 7049.22,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Lassi 8 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "23.76",
      "taxableAmt": 10547.16,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Pav Bhaji Plate & STAINLESS STEEL UTENSILS",
      "quantity": "14.52",
      "taxableAmt": 4850.84,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati J.K. 6 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "16.68",
      "taxableAmt": 7058.44,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Plate Halwa J.K. 6.5 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.40",
      "taxableAmt": 7786.29,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Amrapali 7.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.22",
      "taxableAmt": 6982.48,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Thums Up 7.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "24.50",
      "taxableAmt": 9389.18,
      "taxRate": 12,
      "productType": "KGS"
    }, {
      "hsn": "73239390",
      "product": "Dabba Deep 19x24 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "35.22",
      "taxableAmt": 12631.86,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Sauce Pan Regular 9x11 (22G) & STAINLESS STEEL UTENSILS",
      "quantity": "41.48",
      "taxableAmt": 14208.06,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Dabba Coriander 7x9 (26G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "58.40",
      "taxableAmt": 31799.49,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Sada 5.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.90",
      "taxableAmt": 7620.48,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Prem 7 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "22.98",
      "taxableAmt": 8806.68,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Prem 8 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "21.78",
      "taxableAmt": 8346.80,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass New Juicy 7 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "24",
      "taxableAmt": 10653.70,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Kadai Induction Bottom 11 (22G) & STAINLESS STEEL UTENSILS",
      "quantity": "22.24",
      "taxableAmt": 9411.25,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Sauce Pan Regular 9x11 (22G) Wire & STAINLESS STEEL UTENSILS",
      "quantity": "20.58",
      "taxableAmt": 7049.22,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Lassi 8 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "23.76",
      "taxableAmt": 10547.16,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Pav Bhaji Plate & STAINLESS STEEL UTENSILS",
      "quantity": "14.52",
      "taxableAmt": 4850.84,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati J.K. 6 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "16.68",
      "taxableAmt": 7058.44,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Plate Halwa J.K. 6.5 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.40",
      "taxableAmt": 7786.29,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Amrapali 7.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.22",
      "taxableAmt": 6982.48,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Thums Up 7.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "24.50",
      "taxableAmt": 9389.18,
      "taxRate": 12,
      "productType": "KGS"
    }, {
      "hsn": "73239390",
      "product": "Dabba Deep 19x24 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "35.22",
      "taxableAmt": 12631.86,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Sauce Pan Regular 9x11 (22G) & STAINLESS STEEL UTENSILS",
      "quantity": "41.48",
      "taxableAmt": 14208.06,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Dabba Coriander 7x9 (26G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "58.40",
      "taxableAmt": 31799.49,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Sada 5.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.90",
      "taxableAmt": 7620.48,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Prem 7 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "22.98",
      "taxableAmt": 8806.68,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati Prem 8 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "21.78",
      "taxableAmt": 8346.80,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass New Juicy 7 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "24",
      "taxableAmt": 10653.70,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Kadai Induction Bottom 11 (22G) & STAINLESS STEEL UTENSILS",
      "quantity": "22.24",
      "taxableAmt": 9411.25,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Sauce Pan Regular 9x11 (22G) Wire & STAINLESS STEEL UTENSILS",
      "quantity": "20.58",
      "taxableAmt": 7049.22,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Lassi 8 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "23.76",
      "taxableAmt": 10547.16,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Pav Bhaji Plate & STAINLESS STEEL UTENSILS",
      "quantity": "14.52",
      "taxableAmt": 4850.84,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Vati J.K. 6 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "16.68",
      "taxableAmt": 7058.44,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Plate Halwa J.K. 6.5 (18G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.40",
      "taxableAmt": 7786.29,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Amrapali 7.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "18.22",
      "taxableAmt": 6982.48,
      "taxRate": 12,
      "productType": "KGS"
    },
    {
      "hsn": "73239390",
      "product": "Glass Thums Up 7.5 (22G) Prm & STAINLESS STEEL UTENSILS",
      "quantity": "24.50",
      "taxableAmt": 9389.18,
      "taxRate": 12,
      "productType": "KGS"
    }
  ]
  console.log(data.length);
  
  const handlePrint = () => {
    const printContent = document.getElementById("printDiv").innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    // window.location.reload(); // Reload to restore original content
  };
  const PAGE_SIZE = 30; // Number of items per page
  const pages = [];
  for (let i = 0; i < data.length; i += PAGE_SIZE) {
    pages.push(data.slice(i, i + PAGE_SIZE));
  }
  console.log(pages.length);

  return (
//     <div>
//       <div id="printDiv" style={{ border: "1px solid red",background:'white', padding: "10px" }}>
// <div style={{textAlign:'center',fontWeight:'500'}}><span className="fontsize12" style={{borderBottom:'2px solid'}}>E-way Bill</span></div>

// <div className="padding borderbottom2"  >
// <div className="row">
//     <div className="col">
//         <div className="flexcol14">
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Doc No. : S/DTR/24-25/1568</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Date : 25-Feb-25</span>
//    </div>
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>IRN : 918dacce0344b802bacf94345f859d9be7587373214aaf58649f58ae228f4874</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Ack No.: 122525469863896</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>   Ack Date : 25-Feb-25
//    </span>


//    </div>
//    </div>
//          </div>
//          <div className="col" style={{textAlign:'right'}}>
//             <img src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c7/Batman_Infobox.jpg/220px-Batman_Infobox.jpg" style={{width:'90px',height:'93px'}} />
//          </div>
// </div>






// </div>
// <div className="padding borderbottom2"  style={{background:'white'}}>
// <div className="row">
//     <div className="col">
//         <div className="flexcol14">
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>1. e-Way Bill Details</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>e-Way Bill No. : 261915549572&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mode : 1 - Road</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Generated By : 27AABCN9540N1ZY&nbsp;&nbsp;&nbsp;Approx Distance : 1976 KM</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Supply Type : Outward Transaction&nbsp;&nbsp;&nbsp;Type : Regular</span>
//    </div>
  


//     </div>
//          </div>
//          <div className="col" style={{textAlign:'right'}}>
//          <div className="row " style={{lineHeight:'18px'}}>
//             <div className="col-12">
//             <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Generated Date : 25-Feb-25 8:43 PM </span>

//             </div>
//             <div className="col">
//             <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Valid Upto : 7-Mar-25 11:59 PM </span>

//             </div>
// </div>
//          {/*Valid Upto : 7-Mar-25 11:59 PM */}
//           </div>  
// </div>






// </div>
// {/* add this details on page 1 only  */}
// {<div className="padding borderbottom2"  style={{background:'white'}}>
// <div className="row">
//     <div className="col">
//         <div className="flexcol">
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>From</span>
//    </div>
   
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>NEELAM APPLIANCES LIMITED</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>GSTIN : 27AABCN9540N1ZY</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Maharashtra</span>

//    </div>
//    </div>
//          </div>
//          <div className="col">
//         <div className="flexcol">
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>From</span>
//    </div>
   
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>NEELAM APPLIANCES LIMITED</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>GSTIN : 27AABCN9540N1ZY</span>
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Maharashtra</span>

//    </div>
//    </div>
//          </div>
// </div>
// <span className="makerforspace"></span>
// <div className="row">
//     <div className="col">
//         <div className="flexcol">
//    <div className="flexcol">
//    {/* <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span> */}
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Dispatch From</span>
//    </div>
   
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>    Gr. Floor Gala No:7/8, 1st Floor Gala No: 1/3/5/7/9,
// Neelam House, Fafadia Indl. Estate, Golani Naka,, Waliv
// Village, Next to Varun Industries,, Vasai East. Dist: </span>
  
//    </div>
//    </div>
//          </div>
//          <div className="col">
//         <div className="flexcol">
//    <div className="flexcol">
//    {/* <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span> */}
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Ship To</span>
//    </div>
   
//    <div className="flexcol">
//    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>P-11, Chitpur Spur, 3rd Floor,, Kolkotta. Kolkata West
//    Bengal 700007    </span>
 
//    </div>
//    </div>
//          </div>
// </div>





// </div>}
// {/* ok */}

// <div className=" padding borderbottom2"  style={{background:'white'}}>
// <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>3. Goods Details</span>

// </div>
// <div className="padding borderbottom2">
// <div className="row   borderbottom2">
//   <div className="col-1">
//   <span className="fontsize10" style={{textAlign:'left',fontWeight:'500'}}>HSN no</span>

//     </div>
//     <div className="col-7">
//   <span className="fontsize10" style={{textAlign:'left',fontWeight:'500'}}>Product Name & Desc</span>

//     </div>
//     <div className="col-1">
//   <span className="fontsize10" style={{textAlign:'left',fontWeight:'500'}}>Quantity</span>

//     </div>

//     <div className="col-2">
//   <span className="fontsize10 center" style={{textAlign:'left',fontWeight:'500'}}>Taxable Amt</span>

//     </div>
    
//     <div className="col-1">
//   <span className="fontsize10" style={{textAlign:'left',fontWeight:'500'}}>Tax Rate</span>

//     </div>
//  </div>
//  <div  className="row   rowspacer">

//  {data.slice(1, 40).map((item, index) => (
//     <>
//           <div className="col-1">
//             <span className=" ">{item.hsn}</span>
//           </div>
//           <div className="col-7">
//             <span className=" ">{item.product}</span>
//           </div>
//           <div className="col-1">
//             <span className=" ">{item.quantity}<span className='tpyefind'>{item.productType}</span></span>
//           </div>
//           <div className="col-2 center">
//             <span className=" ">{item.taxableAmt}</span>
//           </div>
//           <div className="col">
//             <span className=" ">{item.taxRate}</span>
//           </div>
          
//           </>
//       ))}
//               </div>
// </div>
// <div className="  borderbottom2"  style={{background:'white'}}>
//  <div className='row padding' style={{lineHeight:'10px'}}>
//       <div className='col'>
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Tot.Taxable Amt : 1,57,141.93 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other Amt : 0.04</span>
//       </div>
//       <div className='col' style={{textAlign:'right'}}>
//       <span className="fontsize12" style={{textAlign:'right',fontWeight:'500'}}>Total Inv Amt :5,94,847.00</span>
//       </div>
//       <div className='col-12'>
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>IGST Amt : 18,857.03</span>
//       </div>
//  </div>
 
// </div>


// {/* add this only on last page */}
// <div className="  borderbottom2"  style={{background:'white'}}>
//  <div className='row padding' style={{lineHeight:'12px'}}>
//   <span  style={{textAlign:'left',fontWeight:'500'}} className="fontsize12">4. Transportation Details</span>
//       <div className='col-6'> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Transporter ID : 06AADCT0663J4Z9</span>
//       </div>
//       <div className='col-6' style={{textAlign:'right'}}> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Doc No&nbsp;</span>
//       </div>
//       <div className='col-6'> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Name : TCI EXPRESS LIMITED (E-WAY BILL) </span>
//       </div>
//       <div className='col-6' style={{textAlign:'right'}}> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Date : 25-Feb-25</span>
//       </div>
//  </div>
//  </div>
//  <div className="  borderbottom2"  style={{background:'white'}}>
//  <div className='row padding' style={{lineHeight:'12px'}}>
//   <span  style={{textAlign:'left',fontWeight:'500'}} className="fontsize12">5. Vehicle Details</span>
//       <div className='col-6'> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}> </span>
//       </div>
//       <div className='col-6' style={{textAlign:'right'}}> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Doc No&nbsp;</span>
//       </div>
//       <div className='col-6'> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Vehicle No. : MH48AY5013 From : VASAI  </span>
//       </div>
//       <div className='col-6' style={{textAlign:'right'}}> 
//       <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>CEWB No. :</span>
//       </div>
//  </div>
//  </div>
//       </div>
//       <button onClick={handlePrint} style={{ marginTop: "10px", padding: "5px 10px" }}>
//         Print
//       </button>
//     </div>
// till heree

<div id="printDiv">
     {pages.map((pageData, pageIndex) => (
      <div key={pageIndex} id={`printDiv${pageIndex}`} className="print-page" style={{ border: "1px solid red", background: 'white', padding: "10px", pageBreakAfter: 'always' }}>
        
        {/* Header */}
        <div style={{ textAlign: 'center', fontWeight: '500' }}>
          <span className="fontsize12" style={{ borderBottom: '2px solid' }}>E-way Bill</span>
        </div>

        {/* Main Content */}
        <div className="padding borderbottom2" style={{ background: 'white' }}>
          <div className="row">
            <div className="col">
              <div className="flexcol14">
                <div className="flexcol">
                  <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>Doc No. : S/DTR/24-25/1568</span>
                  <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>Date : 25-Feb-25</span>
                </div>
                <div className="flexcol">
                  <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>IRN : 918dacce0344b802bacf94345f859d9be7587373214aaf58649f58ae228f4874</span>
                  <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>Ack No.: 122525469863896</span>
                  <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>Ack Date : 25-Feb-25</span>
                </div>
              </div>
            </div>
            <div className="col" style={{ textAlign: 'right' }}>
              <img src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c7/Batman_Infobox.jpg/220px-Batman_Infobox.jpg" style={{ width: '90px', height: '93px' }} />
            </div>
          </div>
        </div>



 {pageIndex==0?<div className="padding borderbottom2"  style={{background:'white'}}>
 <div className="row">
     <div className="col">
         <div className="flexcol">
    <div className="flexcol">
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span>
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>From</span>
    </div>
   
    <div className="flexcol">
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>NEELAM APPLIANCES LIMITED</span>
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>GSTIN : 27AABCN9540N1ZY</span>
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Maharashtra</span>

    </div>
    </div>
          </div>
          <div className="col">
         <div className="flexcol">
    <div className="flexcol">
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span>
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>From</span>
    </div>
   
    <div className="flexcol">
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>NEELAM APPLIANCES LIMITED</span>
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>GSTIN : 27AABCN9540N1ZY</span>
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Maharashtra</span>

    </div>
    </div>
          </div>
 </div>
 <span className="makerforspace"></span>
 <div className="row">
     <div className="col">
         <div className="flexcol">
    <div className="flexcol">
    {/* <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span> */}
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Dispatch From</span>
    </div>
   
    <div className="flexcol">
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>    Gr. Floor Gala No:7/8, 1st Floor Gala No: 1/3/5/7/9,
 Neelam House, Fafadia Indl. Estate, Golani Naka,, Waliv
 Village, Next to Varun Industries,, Vasai East. Dist: </span>
  
    </div>
    </div>
          </div>
          <div className="col">
         <div className="flexcol">
    <div className="flexcol">
    {/* <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>2. Address Details</span> */}
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Ship To</span>
    </div>
   
    <div className="flexcol">
    <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>P-11, Chitpur Spur, 3rd Floor,, Kolkotta. Kolkata West
    Bengal 700007    </span>
 
    </div>
    </div>
          </div>
 </div>





 </div>:''}




        {/* Goods Details */}
        <div className="padding borderbottom2">
          <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>3. Goods Details</span>
        </div>
        <div className="padding borderbottom2">
          <div className="row borderbottom2">
            <div className="col-1"><span className="fontsize10" style={{ textAlign: 'left', fontWeight: '500' }}>HSN no</span></div>
            <div className="col-7"><span className="fontsize10" style={{ textAlign: 'left', fontWeight: '500' }}>Product Name & Desc</span></div>
            <div className="col-1"><span className="fontsize10" style={{ textAlign: 'left', fontWeight: '500' }}>Quantity</span></div>
            <div className="col-2"><span className="fontsize10 center" style={{ textAlign: 'left', fontWeight: '500' }}>Taxable Amt</span></div>
            <div className="col-1"><span className="fontsize10" style={{ textAlign: 'left', fontWeight: '500' }}>Tax Rate</span></div>
          </div>

          {pageData.map((item, index) => (
            <div key={index} className="row rowspacer">
              <div className="col-1"><span>{item.hsn}</span></div>
              <div className="col-7"><span>{item.product}</span></div>
              <div className="col-1"><span>{item.quantity}<span className='tpyefind'>{item.productType}</span></span></div>
              <div className="col-2 center"><span>{item.taxableAmt}</span></div>
              <div className="col"><span>{item.taxRate}</span></div>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="borderbottom2" style={{ background: 'white' }}>
          <div className='row padding' style={{ lineHeight: '10px' }}>
            <div className='col'>
              <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>Tot.Taxable Amt : 1,57,141.93 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Other Amt : 0.04</span>
            </div>
            <div className='col' style={{ textAlign: 'right' }}>
              <span className="fontsize12" style={{ textAlign: 'right', fontWeight: '500' }}>Total Inv Amt : 5,94,847.00</span>
            </div>
            <div className='col-12'>
              <span className="fontsize12" style={{ textAlign: 'left', fontWeight: '500' }}>IGST Amt : 18,857.03</span>
            </div>
          </div>
        </div>
       {Number(pages.length)==pageIndex+1?<>
        <div className="  borderbottom2"  style={{background:'white'}}>
 <div className='row padding' style={{lineHeight:'12px'}}>
  <span  style={{textAlign:'left',fontWeight:'500'}} className="fontsize12">4. Transportation Details</span>
      <div className='col-6'> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Transporter ID : 06AADCT0663J4Z9</span>
      </div>
      <div className='col-6' style={{textAlign:'right'}}> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Doc No&nbsp;</span>
      </div>
      <div className='col-6'> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Name : TCI EXPRESS LIMITED (E-WAY BILL) </span>
      </div>
      <div className='col-6' style={{textAlign:'right'}}> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Date : 25-Feb-25</span>
      </div>
 </div>
 </div>
 {/* <h1>{pages.length +""+""+ pageIndex}</h1> */}
 <div className="  borderbottom2"  style={{background:'white'}}>
 <div className='row padding' style={{lineHeight:'9px'}}>
  <span  style={{textAlign:'left',fontWeight:'500'}} className="fontsize12">5. Vehicle Details</span>
      <div className='col-6'> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}> </span>
      </div>
      <div className='col-6' style={{textAlign:'right'}}> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Doc No&nbsp;</span>
      </div>
      <div className='col-6'> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>Vehicle No. : MH48AY5013 From : VASAI  </span>
      </div>
      <div className='col-6' style={{textAlign:'right'}}> 
      <span className="fontsize12" style={{textAlign:'left',fontWeight:'500'}}>CEWB No. :</span>
      </div>
 </div>
 </div>
       </>:''}
      </div>
    ))}
    


    <button onClick={handlePrint} className="print" style={{ marginTop: "10px", padding: "5px 10px" }}>
      Print
    </button>
  </div>
  );
};

export default Ewaybill;
