import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import authHeader from "../../services/auth-header";
import StaffService from "../../services/StaffService";
import UserService from "../../services/user.service";
import { MDBCard } from "mdbreact";
import Select from "react-select";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../services/Api";
import Cropper from "react-easy-crop";
import Modal from "react-bootstrap/Modal";
import getCroppedImg from "../Brand/getCroppedImg";
import Profilestaffcom from "./Profilestaffcom";
export default function Staff() {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };
  const { id } = useParams();

  const [staffName, setstaffName] = useState("");
  const [doj, setdoj] = useState("");
  const [address, setaddress] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [email, setemail] = useState("");
  const [gender, setgender] = useState("");
  const [designation, setdesignation] = useState("");
  const [salary, setsalary] = useState("");
  const [area, setarea] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [bloodGroup, setbloodGroup] = useState("");
  const [fatherName, setfatherName] = useState("");
  const [spouseName, setspouseName] = useState("");
  const [bankDetail, setbankDetail] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [bankName, setbankName] = useState("");
  const [branchName, setbranchName] = useState("");
  const [panNumber, setpanNumber] = useState("");
  const [aadharNumber, setaadharNumber] = useState("");
  const [dateOfAnniversary, setdateOfAnniversary] = useState("");
  const [password, setpassword] = useState("");
  const [nsm, setnsm] = useState([]);
  const [nsmId, setnsmId] = useState(0);
  const [zonesdata, setzonesdata] = useState([]);
  const [zonesvalue, setzonesvalue] = useState([]);
  const [zoneId, setzoneId] = useState("");
  const [statedata, setStatedata] = useState([]);
  const [statedatavalue, setStatedatavalue] = useState([]);
  const [stateZoneId, setstateZoneId] = useState("");
  const [rsm, setRsm] = useState([]);
  const [rsmvalue, setRsmvalue] = useState([]);
  const [rsmId, setrsmId] = useState(0);
  const [asm, setasm] = useState([]);
  const [asmvalue, setasmvalue] = useState([]);
  const [asmId, setasmId] = useState(0);
  const [ase, setase] = useState([]);
  const [asevalue, setasevalue] = useState([]);
  const [aseId, setaseId] = useState(0);
  const [Roles, setRoles] = useState([]);
  const [roleId, setroleId] = useState("");
  const [error, setError] = useState(false);
  const [status, setstatus] = useState("");
  const [sendotp, setsendotp] = useState("");
  const [verifydone, setverifydone] = useState("");
  const [opttimeout, setopttimeout] = useState("");
  const [emailLoginStatus, setemailLoginStatus] = useState("Disable");
  const [profilePhoto, setprofilePhoto] = useState(null);
  const [profilePhotoData, setprofilePhotoData] = useState(null);
  const [errorProfile, setErrorProfile] = useState("");
  const [salesexpwithincity, setSalesexpwithincity] = useState("");
  const [salesexpoutsidecity, setSalesexpoutsidecity] = useState("");
  const [salesexpdaytrip, setSalesexpdaytrip] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  /* Navigator */
  const navigate = useNavigate();
  console.log(Roles.filter((item) => item.value == roleId).map((item) => item.label !== "ROLE_NSM")[0]);
  useEffect(() => {
    /* Staff Role */

    StaffService.getStaffRole().then((res) => {
      let aa = [];
      res.data.map((user) => {
        if (user.name == "ROLE_DISTRIBUTOR" || user.name == "ROLE_RETAILER" || user.name == "ROLE_SUPPLIER" || user.name == "ROLE_MODERATOR" || user.name == "ROLE_USER") {
          return null;
        } else {
          aa.push(user);
        }
      });
      setRoles(aa.map((user) => ({ value: user.id, label: user.name })));
    });

    /* Zone Data */
    UserService.getZoneData().then((res) => {
      let zone = res.data;
      setzonesdata(zone.map((user) => ({ value: user.id, label: user.zoneName })));
      setzonesvalue(res.data);
    });

    /* NSM Data */
    UserService.getNSMData().then((res) => {
      setnsm(res.data.map((user) => ({ value: user.id, label: user.staffName })));
    });
  }, []);

  /* Zone Change */
  const onZone = (e) => {
    console.log(e.map((item) => ({ id: item.value })));
    let name = e.value;
    setzoneId(e.map((item) => ({ id: item.value })));
    setStatedatavalue("");
    setRsmvalue("");
    setasmvalue("");
    setasevalue("");
    /* State Data */
    setStatedata("");

    // http://43.225.54.138:8080/scm/api/staff/states/zone/2,3,4
    axios
      .get(BASE_URL + "staff/states/zone/" + e.map((item) => item.value), {
        headers: authHeader(),
      })
      .then((res) => {
        setStatedata(res.data.map((item) => ({ value: item.id, label: item.statename })));
        const validValues = statedatavalue.map((item) => item.value); // Get values from e
        console.log(validValues);
        const filteredData = res.data.filter((item) => validValues.includes(item.id)); // Filter based on valid values
        console.log(filteredData);
        setStatedatavalue(
          filteredData.map((item) => ({
            value: item.id,
            label: item.statename,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching verification status:", error);
      });

    // zonesvalue.map(user => {
    //   if (user.id == name) {
    //     setStatedata((user.state_zone).map(item => ({ value: item.id, label: item.state_name })))
    //   }
    // });

    // /* Rsm Data */
    // UserService.getRSMData(e.value).then(res => {
    //   setRsm((res.data).map(user => ({ value: user.id, label: user.staffName })))
    // });
  };

  /* State Change */
  const onSelect = (e) => {
    setasmvalue("");
    setasevalue("");
    setRsmvalue("");

    axios
      .get(BASE_URL + "staff/states/zone/rsm/" + e.map((item) => item.value), {
        headers: authHeader(),
      })
      .then((res) => {
        // Filter unique staff members based on staffid
        const uniqueStaffIds = new Set();
        const uniqueArr = res.data.filter((item) => {
          if (!uniqueStaffIds.has(item.staffid)) {
            uniqueStaffIds.add(item.staffid);
            return true;
          }
          return false;
        });

        console.log(uniqueArr);

        setRsm(
          uniqueArr.map((item) => ({
            value: item.staffid,
            label: item.staffname,
          }))
        );

        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching verification status:", error);
      });
    setstateZoneId(e.map((item) => ({ id: item.value })));
    setStatedatavalue(e.map((item) => ({ value: item.value, label: item.label })));
  };
  console.log(rsm);

  const onNsm = (e) => {
    setnsmId(e.map((item) => item.value));
  };
  // alert(Roles)
  /* RSM Change */
  const onRsm = (e) => {
    console.log(e.map((item) => item.value));
    let name = e.value;
    setrsmId(e.map((item) => item.value));
    setasmvalue("");
    setasevalue("");
    setRsmvalue(e.map((item) => ({ value: item.value, label: item.label })));
    /* ASM DATA */

    axios
      .get(BASE_URL + "staff/multiple/asm/rsm/" + e.map((item) => item.value), {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        const uniqueStaffIds = new Set();
        const uniqueArr = res.data.filter((item) => {
          if (!uniqueStaffIds.has(item.staffid)) {
            uniqueStaffIds.add(item.staffid);
            return true;
          }
          return false;
        });

        console.log(uniqueArr);
        setasm(
          uniqueArr.map((user) => ({
            value: user.staffid,
            label: user.staffname,
          }))
        );
        console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching verification status:", error);
      });
    /* ASE DATA */

    axios
      .get(BASE_URL + "staff/multiple/ase/rsm/" + e.map((item) => item.value), {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        const uniqueStaffIds = new Set();
        const uniqueArr = res.data.filter((item) => {
          if (!uniqueStaffIds.has(item.staffid)) {
            uniqueStaffIds.add(item.staffid);
            return true;
          }
          return false;
        });

        console.log(uniqueArr);
        setase(
          uniqueArr.map((user) => ({
            value: user.staffid,
            label: user.staffname,
          }))
        );
        // console.log(res.data);
      })
      .catch((error) => {
        console.error("Error fetching verification status:", error);
      });
  };
  function authHeaderFile() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
      return {
        Authorization: "Bearer " + user.token,
        "Content-Type": "multipart/form-data",
      }; // for Spring Boot back-end
      // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
      return {};
    }
  }
  /* ASM CHANGE */
  const onAsm = (e) => {
    setasmId(e.map((item) => item.value));
    setasevalue("");
    setasmvalue(e.map((item) => ({ value: item.value, label: item.label })));
    /* ASE DATA */
    // UserService.getASEData(e.value).then(res => {
    //   setase([{ value: "", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
    // });
    axios
      .get(BASE_URL + "staff/multiple/ase/asm/" + e.map((item) => item.value), {
        headers: authHeader(),
      })
      .then((res) => {
        const uniqueStaffIds = new Set();
        const uniqueArr = res.data.filter((item) => {
          if (!uniqueStaffIds.has(item.staffid)) {
            uniqueStaffIds.add(item.staffid);
            return true;
          }
          return false;
        });

        setase(
          uniqueArr.map((user) => ({
            value: user.staffid,
            label: user.staffname,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching verification status:", error);
      });
  };

  /* ASE DATA */
  const onAse = (e) => {
    setaseId(e.map((item) => item.value));
    setasevalue(e.map((item) => ({ value: item.value, label: item.label })));
  };

  /* ROlES Change */

  const onRole = (e) => {
    setroleId(e.value);
  };
  let Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const sendemail = (e) => {
    axios
      .post(
        BASE_URL + "auth/sendverificationcode/otp",
        { email: e },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setstatus(res.data);
        setverifydone("");
        setopttimeout("");
      });
  };

  const verifyemail = (e, f) => {
    let dataverify = { email: e, otp: f };

    axios
      .post(BASE_URL + "auth/verifyEmailWithOtp", dataverify, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('mello',res.data)
        if (res.data == "invalid email or otp") {
          alert("Invalid OTP! Please Enter Valid OTP");
        } else if (res.data == "time limit exceed !!! please generate new otp .") {
          alert("Your OTP has been Expired! , Please Generate New OTP");
          setopttimeout(res.data);
        } else {
          setverifydone(res.data);
        }
      });
  };
  const [userid, setuserid] = useState(0);
  const sendDataToAPI = () => {
    console.log(statedatavalue.map((item) => ({ id: item.value })));

    const multipleStaffDtos = [
      {
        nsmid: nsmId === 0 ? [] : Array.isArray(nsmId) ? nsmId.map((item) => item) : [],
        rsmid: rsmvalue === 0 ? [] : Array.isArray(rsmvalue) ? rsmvalue.map((item) => item.value) : [],
        asmid: asmvalue === 0 ? [] : Array.isArray(asmvalue) ? asmvalue.map((item) => item.value) : [],
        aseid: asevalue === 0 ? [] : Array.isArray(asevalue) ? asevalue.map((item) => item.value) : [],
      },
    ];

    let staff = {
      staffName,
      doj,
      address,
      mobileNumber,
      email,
      gender,
      salary,
      area,
      dateOfBirth,
      bloodGroup,
      fatherName,
      spouseName,
      bankDetail,
      accountNumber,
      ifscCode,
      bankName,
      branchName,
      panNumber,
      aadharNumber,
      dateOfAnniversary,
      password,
      multipleStaffDtos,
      zones: zoneId,
      statezones: statedatavalue.map((item) => ({ id: item.value })),
      roleId,
      emailLoginStatus,
      salesexpwithincity,
      salesexpoutsidecity,
      salesexpdaytrip,
    };

    if (
      staffName.length !== 0 &&
      doj.length !== 0 &&
      address.length !== 0 &&
      mobileNumber.length !== 0 &&
      email.length !== 0 &&
      gender.length !== 0 &&
      salary.length !== 0 &&
      area.length !== 0 &&
      dateOfBirth.length !== 0 &&
      bankDetail.length !== 0 &&
      accountNumber.length !== 0 &&
      ifscCode.length !== 0 &&
      bankName.length !== 0 &&
      branchName.length !== 0 &&
      password.length !== 0 &&
      zoneId.length !== 0 &&
      stateZoneId.length !== 0 &&
      roleId.length !== 0
    ) {
      setError(false);
      setIsLoading(true);

      StaffService.getStaffcreate(staff)
        .then((res) => {
          if (!res?.data?.id) {
            throw new Error("Failed to create staff");
          }
          navigate(`/screate/${res?.data?.id}`);
          setuserid(res?.data?.id);
        })
        .catch((error) => {
          console.error("Error adding  :", error);
          if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
              didOpen: () => {
                applyFontSizeTransition("issueText");
              },
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError(true);
    }
  };
  const handleRemoveFile = (index) => {
    // const newFiles = [...files];
    // newFiles[index] = null;
    // setFiles(newFiles);
  };
  const handleProfilePic = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      const fileType = file.type;
      if (/image\/(jpeg|png|gif)/i.test(fileType)) {
        setprofilePhoto(file);

        formData.append("file", file);

        setprofilePhotoData(formData);
      } else {
        setprofilePhoto(null);
        setErrorProfile(true);
        event.target.value = "";
      }
    }
  };
  const handleRemoveProfilePhoto = () => {
    setprofilePhoto(null);
    setErrorProfile(false);

    const fileInput = document.getElementById("profilePicInput");
    if (fileInput) {
      fileInput.value = ""; // This clears the file input
    }
  };
  console.log(profilePhoto);

  return (
    <>
      {id == 0 ? (
        <MDBCard data-aos="fade">
          <div className="d-flex bd-highlight">
            <div className="card-header card-title w-100">ADD STAFF</div>
            <Link className="p-2 w-200 card-header card-title bd-highlight" to={"/Stafftable/1"}>
              <Button>Back</Button>
            </Link>
            {/* <Button
          className="w-200 card-header card-title"
          style={{ color: "white !important", width: "200px", padding: "1px" }}
          onClick={handleShow}
        >
          Add Profile Photo
        </Button> */}
          </div>
          {/* <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>  Add Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group as={Col} md="6" controlId="formBasicName">
            <Form.Label>Profile Photo*</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              autoComplete="off"

              style={{
                borderColor:errorProfile? 'red':'' ||(error && profilePhoto.length == 0 ? "red" : ""),
                
              }}
              onChange={handleProfilePic}
            />
            {error && staffName.length === 0 ? (
              <p
                style={{ color: error && staffName.length === 0 ? "red" : " " }}
              >
                This field is Required
              </p>
            ) ||errorProfile && <p style={{ color: "red" }}>
            Please upload a valid image file (.jpg, .png, .gif).
          </p>: (
              ""
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal> */}
          <Form className="product2">
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Full Name*</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  style={{
                    borderColor: error && staffName.length == 0 ? "red" : "",
                  }}
                  onChange={(e) => setstaffName(e.target.value)}
                />
                {error && staffName.length === 0 ? <p style={{ color: error && staffName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Date of Joining*</Form.Label>
                <Form.Control type="date" style={{ borderColor: error && doj.length == 0 ? "red" : "" }} onChange={(e) => setdoj(e.target.value)} />
                {error && doj.length === 0 ? <p style={{ color: error && doj.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Address*</Form.Label>
                <Form.Control style={{ borderColor: error && address.length == 0 ? "red" : "" }} type="text" autoComplete="off" onChange={(e) => setaddress(e.target.value)} />
                {error && address.length === 0 ? <p style={{ color: error && address.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Zone*</Form.Label>
                <Select isMulti style={{ borderColor: error && zoneId.length === 0 ? "red" : "" }} options={zonesdata} onChange={(e) => onZone(e)} />

                {/* <Select style={{ borderColor: error && zoneId.length == 0 ? "red" : "" }} options={zonesdata} onChange={(e) => { onZone(e) }} /> */}
                {error && zoneId.length === 0 ? <p style={{ color: error && zoneId.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>State*</Form.Label>
                <Select
                  style={{
                    borderColor: error && stateZoneId.length == 0 ? "red" : "",
                  }}
                  isMulti
                  value={statedatavalue}
                  options={statedata}
                  onChange={(e) => {
                    onSelect(e);
                  }}
                />
                {error && stateZoneId.length === 0 ? (
                  <p
                    style={{
                      color: error && stateZoneId.length === 0 ? "red" : " ",
                    }}
                  >
                    This field is Required
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Mobile No*</Form.Label>
                <Form.Control
                  style={{
                    borderColor: error && mobileNumber.length !== 10 ? "red" : "",
                  }}
                  type="number"
                  autoComplete="off"
                  onChange={(e) => setmobileNumber(e.target.value)}
                />
                {error && mobileNumber.length !== 10 ? (
                  <p
                    style={{
                      color: error && mobileNumber.length === 0 ? "red" : " ",
                    }}
                  >
                    This field is Required
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label className="cname2">OTP Login</Form.Label>
                <Form.Select value={emailLoginStatus} name="emailLoginStatus" onChange={(e) => setemailLoginStatus(e.target.value)}>
                  <option>--Select--</option>
                  <option value={"Enable"}>Enable</option>
                  <option value={"Disable"}>Disable</option>
                </Form.Select>
              </Form.Group>
              {emailLoginStatus === "Disable" ? (
                <>
                  <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      style={{
                        borderColor: error && email.length == 0 ? "red" : "",
                      }}
                      type="email"
                      autoComplete="off"
                      onChange={(e) => setemail(e.target.value)}
                      placeholder="Enter Email"
                    />
                    {error && email.length === 0 ? <p style={{ color: error && email.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
                  </Form.Group>
                </>
              ) : (
                <>
                  <Form.Group as={Col} md="4" controlId="formBasicName">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      style={{
                        borderColor: error && email.length == 0 ? "red" : "",
                      }}
                      type="email"
                      disabled={verifydone == "" ? false : opttimeout == "" ? true : false}
                      autoComplete="off"
                      onChange={(e) => setemail(e.target.value)}
                      placeholder="Enter Email"
                    />
                    {error && email.length === 0 ? <p style={{ color: error && email.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
                  </Form.Group>
                  {verifydone == "" ? (
                    <Form.Group as={Col} md="2" controlId="formBasicName">
                      <Form.Label className="cname2"></Form.Label>
                      {/* <Button onClick={() => sendemail(email)}>OTP Generate</Button> */}
                      <div style={{ marginTop: "27px" }}>
                        <span
                          style={{
                            color: "white",
                            border: "0.5px solid #ededed",
                            padding: "6px",
                            background: "rgb(43 177 39)",
                            borderRadius: "6px",
                            cursor: "pointer",
                          }}
                          onClick={() => sendemail(email)}
                        >
                          OTP&nbsp;Generate
                        </span>
                      </div>
                    </Form.Group>
                  ) : opttimeout == "" ? (
                    <Form.Group as={Col} md="1" controlId="formBasicName">
                      <Form.Label className="cname2"></Form.Label>
                      <span class="snackbar_circle">
                        <span class="checkmark">L</span>
                      </span>
                    </Form.Group>
                  ) : (
                    <Form.Group as={Col} md="1" controlId="formBasicName">
                      <Form.Label className="cname2"></Form.Label>
                      {/* <Button onClick={() => sendemail(email)}>OTP Generate</Button> */}
                      <div style={{ marginTop: "27px" }}>
                        <span
                          style={{
                            color: "white",
                            border: "0.5px solid #ededed",
                            padding: "6px",
                            background: "rgb(43 177 39)",
                            borderRadius: "6px",
                            cursor: "pointer",
                          }}
                          onClick={() => sendemail(email)}
                        >
                          OTP&nbsp;Generate
                        </span>
                      </div>
                    </Form.Group>
                  )}
                  {status !== "" ? (
                    verifydone == "" && opttimeout == "" ? (
                      <Row>
                        <Form.Group as={Col} md="1" controlId="formBasicName">
                          <Form.Label className="cname2">Verification Code*</Form.Label>
                          <Form.Control type="text" autoComplete="off" onChange={(e) => setsendotp(e.target.value)} placeholder="Enter OTP" />
                        </Form.Group>

                        <Form.Group as={Col} md="1" controlId="formBasicName">
                          <Form.Label className="cname2"></Form.Label>
                          <Button onClick={() => verifyemail(email, sendotp)}>Verify</Button>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="formBasicName"></Form.Group>
                      </Row>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Gender*</Form.Label>
                <br></br>
                <input type="radio" onChange={(e) => setgender(e.target.value)} value="male" name="radio" /> Male&nbsp;&nbsp;
                <input type="radio" onChange={(e) => setgender(e.target.value)} value="female" name="radio" /> Female
                {error && gender.length === 0 ? <p style={{ color: error && email.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Designation*</Form.Label>
                <Select
                  options={Roles}
                  style={{ borderColor: error && roleId.length === 0 ? "red" : "" }}
                  onChange={(e) => {
                    onRole(e);
                  }}
                />
                {error && roleId.length === 0 ? <p style={{ color: error && roleId.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Salary*</Form.Label>
                <Form.Control style={{ borderColor: error && salary.length === 0 ? "red" : "" }} type="number" autoComplete="off" onChange={(e) => setsalary(e.target.value)} />
                {error && salary.length === 0 ? <p style={{ color: error && salary.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Area*</Form.Label>
                <Form.Control style={{ borderColor: error && area.length === 0 ? "red" : "" }} type="text" autoComplete="off" onChange={(e) => setarea(e.target.value)} />
                {error && area.length === 0 ? <p style={{ color: error && area.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <br></br>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Date&nbsp;of&nbsp;Birth*</Form.Label>
                <Form.Control
                  style={{
                    borderColor: error && dateOfBirth.length === 0 ? "red" : "",
                  }}
                  type="date"
                  onChange={(e) => setdateOfBirth(e.target.value)}
                />
                {error && dateOfBirth.length === 0 ? (
                  <p
                    style={{
                      color: error && dateOfBirth.length === 0 ? "red" : " ",
                    }}
                  >
                    This field is Required
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Blood&nbsp;Group</Form.Label>
                <Form.Control type="email" onChange={(e) => setbloodGroup(e.target.value)} autoComplete="off" />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Father Name</Form.Label>
                <Form.Control type="text" autoComplete="off" onChange={(e) => setfatherName(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Spouse Name</Form.Label>
                <Form.Control type="text" autoComplete="off" onChange={(e) => setspouseName(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Bank A/c Name* </Form.Label>
                <Form.Control
                  autoComplete="off"
                  style={{
                    borderColor: error && bankDetail.length == 0 ? "red" : "",
                  }}
                  type="text"
                  onChange={(e) => setbankDetail(e.target.value)}
                />
                {error && bankDetail.length === 0 ? (
                  <p
                    style={{
                      color: error && bankDetail.length === 0 ? "red" : " ",
                    }}
                  >
                    This field is Required
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>A/C NO*</Form.Label>
                <Form.Control
                  autoComplete="off"
                  style={{
                    borderColor: error && accountNumber.length == 0 ? "red" : "",
                  }}
                  type="number"
                  onChange={(e) => setaccountNumber(e.target.value)}
                />
                {error && accountNumber.length === 0 ? (
                  <p
                    style={{
                      color: error && accountNumber.length === 0 ? "red" : " ",
                    }}
                  >
                    This field is Required
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>IFSC CODE*</Form.Label>
                <Form.Control
                  autoComplete="off"
                  style={{
                    borderColor: error && ifscCode.length == 0 ? "red" : "",
                  }}
                  type="text"
                  onChange={(e) => setifscCode(e.target.value)}
                />
                {error && ifscCode.length === 0 ? <p style={{ color: error && ifscCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Bank Name*</Form.Label>
                <Form.Control
                  autoComplete="off"
                  style={{
                    borderColor: error && bankName.length == 0 ? "red" : "",
                  }}
                  type="text"
                  onChange={(e) => setbankName(e.target.value)}
                />
                {error && bankName.length === 0 ? <p style={{ color: error && bankName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Branch Name*</Form.Label>
                <Form.Control
                  autoComplete="off"
                  style={{
                    borderColor: error && branchName.length == 0 ? "red" : "",
                  }}
                  type="text"
                  onChange={(e) => setbranchName(e.target.value)}
                />
                {error && branchName.length === 0 ? (
                  <p
                    style={{
                      color: error && branchName.length === 0 ? "red" : " ",
                    }}
                  >
                    This field is Required
                  </p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>PAN Number*</Form.Label>
                <Form.Control autoComplete="off" type="text" onChange={(e) => setpanNumber(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>AADHAR Number</Form.Label>
                <Form.Control autoComplete="off" type="number" onChange={(e) => setaadharNumber(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Date of Anniversary</Form.Label>
                <Form.Control autoComplete="off" type="date" onChange={(e) => setdateOfAnniversary(e.target.value)} />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Password*</Form.Label>
                <Form.Control
                  autoComplete="off"
                  style={{
                    borderColor: error && password.length == 0 ? "red" : "",
                  }}
                  type="text"
                  onChange={(e) => setpassword(e.target.value)}
                />
                {error && password.length === 0 ? <p style={{ color: error && password.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              </Form.Group>
              {Roles.filter((item) => item.value === roleId)
                .map((item) => item.label)
                .some((label) => label !== "ROLE_NSM" && label !== "ROLE_ADMIN") ? (
                <>
                  <Form.Group as={Col} md="6" controlId="formBasicName">
                    <Form.Label>NSM</Form.Label>
                    <Select
                      options={nsm}
                      isMulti
                      onChange={(e) => {
                        onNsm(e);
                      }}
                    />
                  </Form.Group>
                  {Roles.filter((item) => item.value == roleId).map((item) => item.label !== "ROLE_RSM")[0] !== false ? (
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                      <Form.Label>RSM</Form.Label>
                      <Select
                        style={{
                          borderColor: error && rsm.length == 0 ? "red" : "",
                        }}
                        value={rsmvalue}
                        isMulti
                        options={rsm}
                        onChange={(e) => {
                          onRsm(e);
                        }}
                      />
                      {/* {error && rsm.length === 0 ? <p style={{ color: error && rsm.length === 0 ? "red" : " " }}>This field is Required</p> : ""} */}
                    </Form.Group>
                  ) : (
                    ""
                  )}
                  {Roles.filter((item) => item.value == roleId)
                    .map((item) => item.label)
                    .some((label) => label !== "ROLE_ASM" && label !== "ROLE_RSM") ? (
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                      <Form.Label>ASM</Form.Label>
                      <Select
                        style={{
                          borderColor: error && asm.length == 0 ? "red" : "",
                        }}
                        value={asmvalue}
                        isMulti
                        options={asm}
                        onChange={(e) => {
                          onAsm(e);
                        }}
                      />
                      {/* {error&&asm.length === 0 ? <p style={{ color: error&&asm.length === 0 ? "red" : " " }}>This field is Required</p> : ""} */}
                    </Form.Group>
                  ) : (
                    ""
                  )}
                  {Roles.filter((item) => item.value == roleId)
                    .map((item) => item.label)
                    .some((label) => label !== "ROLE_ASE" && label !== "ROLE_ASM" && label !== "ROLE_RSM") ? (
                    <Form.Group as={Col} md="6" controlId="formBasicName">
                      <Form.Label>ASE</Form.Label>
                      <Select
                        options={ase}
                        value={asevalue}
                        isMulti
                        onChange={(e) => {
                          onAse(e);
                        }}
                      />
                      {/* {error&&ase.length === 0 ? <p style={{ color: error&&ase.length === 0 ? "red" : " " }}>This field is Required</p> : ""} */}
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Sales Expense Within City</Form.Label>
                <Form.Control autoComplete="off" type="number" onChange={(e) => setSalesexpwithincity(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Sales Expense Outside City</Form.Label>
                <Form.Control autoComplete="off" type="number" onChange={(e) => setSalesexpoutsidecity(e.target.value)} />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="formBasicName">
                <Form.Label>Sales Expense Daytrip</Form.Label>
                <Form.Control autoComplete="off" type="number" onChange={(e) => setSalesexpdaytrip(e.target.value)} />
              </Form.Group>
            </Row>
            {/* <Button variant="primary" type="button" onClick={sendDataToAPI}>
          Submit
        </Button> */}

            <Button variant="primary" style={{ width: "auto" }} onClick={sendDataToAPI} disabled={isLoading}>
              {isLoading ? "Loading..." : "Submit"}
            </Button>
          </Form>
        </MDBCard>
      ) : (
        <Profilestaffcom userfirend="staffid" axiosdata="staffid" butvisble="false" steper="false" routerarea="/Stafftable/1" />
      )}
    </>
  );
}
