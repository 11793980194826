

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReatilerService from "../../services/Reatiler";
import UserService from "../../services/user.service";
import DistrbutorService from "../../services/Distrbutor";
import { MDBCard } from "mdbreact";
import Swal from "sweetalert2";
import authHeader from "../../services/auth-header";
import axios from "axios";
import { BASE_URL } from "../../services/Api";
import AuthService from "../../services/auth.service";
import Profilestaffcom from "../Staff/Profilestaffcom";

export default function Edit() {
  const [projectionEntryData, setProjectionEntryData] = useState({
    projectionentryitem: [],
  });

  var group1 = "";
  var group2 = "";
  var group3 = "";
  var group4 = "";
  var group5 = "";

  const [groupn1Options, setGroupN1Options] = useState();
  const [groupn2Options, setGroupN2Options] = useState();
  const [groupn3Options, setGroupN3Options] = useState();
  const [groupn4Options, setGroupN4Options] = useState();
  const [groupn5Options, setGroupN5Options] = useState();
  const [productOptions, setProductOptions] = useState();

  const [group1Value, setGroup1Value] = useState();
  const [group2Value, setGroup2Value] = useState();
  const [group3Value, setGroup3Value] = useState();
  const [group4Value, setGroup4Value] = useState();
  const [group5Value, setGroup5Value] = useState();

  const [group1Search, setGroup1Search] = useState("");
  const [group2Search, setGroup2Search] = useState("");
  const [group3Search, setGroup3Search] = useState("");
  const [group4Search, setGroup4Search] = useState("");
  const [group5Search, setGroup5Search] = useState("");
  const [productSearch, setProductSearch] = useState(encodeURIComponent(" "));

  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [disabled5, setDisabled5] = useState(false);
  const currentRole = JSON.parse(localStorage.getItem("user")).roles[0];
  
  const currenttokenid=JSON.parse(localStorage.getItem("user")).id;
  const [rolesforpassword, setRolesforpassword] = useState('')

  useEffect(() => {
    console.log(group1Value, group2Value, group3Value, group4Value, group5Value);
    group1 = group1Value;
    group2 = group2Value;
    group3 = group3Value;
    group4 = group4Value;
    group5 = group5Value;

    console.log(group1, group2, group3, group4, group5);

    handleInitialDisabledConditions();
  }, [group1Value, group2Value, group3Value, group4Value, group5Value]);

  async function groupn1() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn1`, {
        headers: authHeader(),
      });

      setGroupN1Options(response.data.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn2() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn2/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group2Search}`, {
        headers: authHeader(),
      });

      setGroupN2Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn3() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn3/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group3Search}`, {
        headers: authHeader(),
      });

      setGroupN3Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn4() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn4/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group4Search}`, {
        headers: authHeader(),
      });

      setGroupN4Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  async function groupn5() {
    try {
      const response = await axios.get(`${BASE_URL}v1/groupn5/page?pageNumber=&pageSize=&field=id&direction=asc&search=${group5Search}`, {
        headers: authHeader(),
      });

      setGroupN5Options(response.data.content.map((data) => ({ value: data.id, label: data.title })));
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  const setDisabledFunctions = [setDisabled1, setDisabled2, setDisabled3, setDisabled4, setDisabled5];

  function handleInitialDisabledConditions() {
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    //const groupIndex = parseInt(groupValue.replace("groupn", ""));

    // Disable all previous groups and all groups after the next one
    // for (let i = 0; i < setDisabledFunctions.length; i++) {
    //   if (i < groupIndex - 1) {
    //     setDisabledFunctions[i](true); // Disable previous groups
    //   } else if (i > groupIndex) {
    //     setDisabledFunctions[i](true); // Disable subsequent groups
    //   }
    // }

    console.log(group1, group2, group3, group4, group5);

    if (group1?.value !== undefined) {
      setDisabled2(false);
      setDisabled3(true);
      setDisabled4(true);
      setDisabled5(true);
    } else {
      setDisabled1(true);
    }

    console.log(disabled1, disabled2, disabled3, disabled4, disabled5);

    if (group2?.value !== undefined) {
      setDisabled3(false);
      setDisabled4(true);
      setDisabled5(true);
    } else if (group1?.value !== undefined) {
      setDisabled2(false);
    } else {
      setDisabled2(true);
    }

    console.log(disabled1, disabled2, disabled3, disabled4, disabled5);

    if (group3?.value !== undefined) {
      setDisabled4(false);
      setDisabled5(true);
    } else if (group2?.value !== undefined) {
      setDisabled3(false);
    } else {
      setDisabled3(true);
    }

    console.log(disabled1, disabled2, disabled3, disabled4, disabled5);

    if (group4?.value !== undefined) {
      setDisabled5(false);
    } else if (group3?.value !== undefined) {
      setDisabled4(false);
    } else {
      setDisabled4(true);
    }

    console.log(disabled1, disabled2, disabled3, disabled4, disabled5);

    // Handle specific group logic
    // if (group2) {
    //   if (!group1 || group1?.value === undefined || group1?.value === "neelam") {
    //     setDisabled1(true);
    //   }

    //   if (!group4 || group4?.value === undefined || group4?.value === "neelam") {
    //     setDisabled4(true);
    //   }
    //   if (!group5 || group5?.value === undefined || group5?.value === "neelam") {
    //     setDisabled5(true);
    //   }

    //   setDisabled3(false);
    // }

    // if (group3) {
    //   if (!group1 || group1?.value === undefined || group1?.value === "neelam") {
    //     setDisabled1(true);
    //   }
    //   if (!group2 || group2?.value === undefined || group2?.value === "neelam") {
    //     setDisabled2(true);
    //   }

    //   if (!group5 || group5?.value === undefined || group5?.value === "neelam") {
    //     setDisabled5(true);
    //   }

    //   setDisabled4(false);
    // }

    // if (group4) {
    //   if (!group1 || group1?.value === undefined || group1?.value === "neelam") {
    //     setDisabled1(true);
    //   }
    //   if (!group2 || group2?.value === undefined || group2?.value === "neelam") {
    //     setDisabled2(true);
    //   }
    //   if (!group3 || group3?.value === undefined || group3?.value === "neelam") {
    //     setDisabled3(true);
    //   }

    //   setDisabled5(false);
    // }

    // if (group5) {
    //   if (!group1 || group1?.value === undefined || group1?.value === "neelam") {
    //     setDisabled1(true);
    //   }
    //   if (!group2 || group2?.value === undefined || group2?.value === "neelam") {
    //     setDisabled2(true);
    //   }
    //   if (!group3 || group3?.value === undefined || group3?.value === "neelam") {
    //     setDisabled3(true);
    //   }
    //   if (!group4 || group4?.value === undefined || group4?.value === "neelam") {
    //     setDisabled4(true);
    //   }
    // }

    console.log(disabled1, disabled2, disabled3, disabled4, disabled5);
  }

  function handleDisabledConditions(groupValue) {
    if (!groupValue) {
      return;
    }
    console.log(groupValue);

    // Reset all disabled states to false
    setDisabledFunctions.forEach((setDisabled) => setDisabled(false));

    // Determine the index of the selected group (1-indexed)
    const groupIndex = parseInt(groupValue.replace("groupn", ""));

    // Disable all previous groups and all groups after the next one
    // for (let i = 0; i < setDisabledFunctions.length; i++) {
    //   if (i < groupIndex - 1) {
    //     setDisabledFunctions[i](true); // Disable previous groups
    //   } else if (i > groupIndex) {
    //     setDisabledFunctions[i](true); // Disable subsequent groups
    //   }
    // }

    if (groupValue === "groupn1") {
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    // Handle specific group logic
    if (groupValue === "groupn2") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }

      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn3") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }

      if (!group5Value || group5Value?.value?.length === 0 || group5Value?.value === "neelam") {
        setDisabled5(true);
      }
    }

    if (groupValue === "groupn4") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
    }

    if (groupValue === "groupn5") {
      if (!group1Value || group1Value?.value?.length === 0 || group1Value?.value === "neelam") {
        setDisabled1(true);
      }
      if (!group2Value || group2Value?.value?.length === 0 || group2Value?.value === "neelam") {
        setDisabled2(true);
      }
      if (!group3Value || group3Value?.value?.length === 0 || group3Value?.value === "neelam") {
        setDisabled3(true);
      }
      if (!group4Value || group4Value?.value?.length === 0 || group4Value?.value === "neelam") {
        setDisabled4(true);
      }
    }
  }

  function changeHandler(e, element) {
    console.log(e);

    if (element) {
      setProjectionEntryData({
        ...projectionEntryData,
        [element]: {
          id: e.value,
        },
      });
    } else {
      const { name, value } = e.target;

      setProjectionEntryData({
        ...projectionEntryData,
        [name]: value,
      });
    }

    if (element === "groupn1") {
      setGroup1Value(e);
    } else if (element === "groupn2") {
      setGroup2Value(e);
    } else if (element === "groupn3") {
      setGroup3Value(e);
    } else if (element === "groupn4") {
      setGroup4Value(e);
    } else if (element === "groupn5") {
      setGroup5Value(e);
    }

    handleDisabledConditions(element);
  }

  useEffect(() => {
    groupn1();
    groupn2();
    groupn3();
    groupn4();
    groupn5();
  }, []);

  const { pointindex } = useParams();
  /* Distributor Id */
  const { id } = useParams();
  const [aadharcard, setaadharcard] = useState("");
  const [tradeName, settradeName] = useState("");
  const [billingAddress, setbillingAddress] = useState("");
  const [deliveryAddress, setdeliveryAddress] = useState([]);
  const [country, setcountry] = useState({ value: "101", label: "India" });
  const [stateid, setstateid] = useState("");
  const [statecode, setstatecode] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [city, setcity] = useState("");
  const [panNumber, setpanNumber] = useState("");
  const [gstNumber, setgstNumber] = useState("");
  const [gstType, setgstType] = useState("");
  const [pinCode, setpinCode] = useState("");
  const [perMobileNumber, setperMobileNumber] = useState("");
  const [alterMobileNumber, setalterMobileNumber] = useState("");
  const [perEmail, setperEmail] = useState("");
  const [alterEmail, setalterEmail] = useState("");
  const [creditLimit, setcreditLimit] = useState("");
  const [creditDays, setcreditDays] = useState("");
  const [transporterName, settransporterName] = useState("");
  const [deliveryLocation, setdeliveryLocation] = useState("");
  const [countryoptions, setCountryoptions] = useState({ value: "101", label: "India" });
  const [statedata, setstatedata] = useState([]);
  const [statevalue, setstatevalue] = useState([]);
  const [boxProductDiscount, setboxProductDiscount] = useState("");
  const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState("0");
  const [kgProductDiscount, setkgProductDiscount] = useState("");
  const [schemekgProductDiscount, setschemekgProductDiscount] = useState("0");
  const [corporaetProductDiscount, setcorporaetProductDiscount] = useState("");
  const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState("0");
  const [cookerProductDiscount, setcookerProductDiscount] = useState("");
  const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState("0");
  const [noshProductDiscount, setnoshProductDiscount] = useState("");
  const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState("0");
  const [distributorName1, setdistributorName1] = useState("");
  const [distributorName2, setdistributorName2] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [roles1, setRoles1] = useState("");
  const [dob1, setdob1] = useState("");
  const [dob2, setdob2] = useState("");
  const [doa1, setdoa1] = useState("");
  const [doa2, setdoa2] = useState("");
  const [mobNo1, setmobNo1] = useState("");
  const [mobNo2, setmobNo2] = useState("");
  const [email1, setemail1] = useState("");
  const [email2, setemail2] = useState("");
  const [aseid, setaseid] = useState("");
  const [ase, setase] = useState([]);
  const [asm, setasm] = useState([]);
  const [asmid, setasmid] = useState("");
  const [rsmid, setrsmid] = useState("");
  const [zonedata, setZonedata] = useState([]);
  const [zoneAlldata, setZoneAlldata] = useState([]);
  const [stateAlldata, setstateAlldata] = useState([]);
  const [zonesid, setzonesid] = useState("");
  const [rsm, setrsm] = useState([]);
  const [Rsmdata, setRsmdata] = useState([]);
  const [nsm, setNsm] = useState([]);
  const [nsmid, setnsmid] = useState("");
  const [error, setError] = useState(false);
  const [sameDeliveryAdd, setsameDeliveryAdd] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const [distrubatorName, setdistrubatorName] = useState("");
  const [distrbutorvalue, setDistrbutorvalue] = useState([]);
  const [distrbutor, setDistrbutor] = useState([]);
  const [asedata, setasedata] = useState([]);
  const [asmdata, setasmdata] = useState([]);
  const [colourtype, setcolourtype] = useState("");
  const [emailLoginStatus, setemailLoginStatus] = useState("");
  const [updatestatus, setupdatestatus] = useState(false);
  const [verifydone, setverifydone] = useState("");
  const [opttimeout, setopttimeout] = useState("");
  const [status, setstatus] = useState("");
  const [sendotp, setsendotp] = useState("");
const[passworddecrp,setPasswordecrp]=useState('');
  const user = AuthService.getCurrentUser();
  /* Navigator */
  const navigate = useNavigate();
  let Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const handtype = (e) => {
    switch (e) {
      case "REG":
        setgstType("register");
        break;
      case "UNR":
        setgstType("unregister");
        break;
      case "COM":
        setgstType("composition");
        break;
      case "CON":
        setgstType("consumer");
        break;
      default:
        setgstType("unknown");
    }
  };
  function extractPAN(inputString) {
    console.log(inputString.slice(2, 12));
    setpanNumber(inputString.slice(2, 12));
  }

  function checksum(g) {
    let p;
    let regTest = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/.test(g);
    if (regTest) {
      let a = 65,
        b = 55,
        c = 36;
      return Array["from"](g).reduce((i, j, k, g) => {
        p = (p = (j.charCodeAt(0) < a ? parseInt(j) : j.charCodeAt(0) - b) * ((k % 2) + 1)) > c ? 1 + (p - c) : p;
        return k < 14 ? i + p : j == ((c = c - (i % c)) < 10 ? c : String.fromCharCode(c + b));
      }, 0);
    }
    return regTest;
  }
  console.log(deliveryAddress);
  const getgstdetails = () => {
    if (checksum(gstNumber)) {
      let cap;
      axios
        .get(`http://43.225.54.138:8080/scm/gst/gstin/${gstNumber}`, { headers: authHeader() })
        .then((response) => {
          console.log(response.data);
          // setGstdetails(response.data)
          const gstTypeChar = gstNumber[5].toUpperCase();

          // settradeName(response.data.TradeName)
          let businessType;
          switch (gstTypeChar) {
            case "P":
              businessType = "Proprietor";
              settradeName(response.data.LegalName);
              setdistributorName1(response.data.TradeName);
              handtype(response.data.TxpType);
              extractPAN(gstNumber);
              break;
            case "F":
              businessType = "Firm";
              // setcompanyname(response.data.LegalName)
              settradeName(response.data.LegalName);
              setdistributorName1(response.data.TradeName);
              handtype(response.data.TxpType);
              extractPAN(gstNumber);

              break;
            case "C":
              businessType = "Company";
              // setcompanyname(response.data.LegalName)
              settradeName(response.data.LegalName);
              setdistributorName1(response.data.TradeName);
              handtype(response.data.TxpType);
              extractPAN(gstNumber);

              break;
            default:
              businessType = "Unknown type";
          }

          axios
            .get(`http://43.225.54.138:8080/scm/api/state`, { headers: authHeader() })
            .then((response1) => {
              // setgststated(response1.data)
              //   setsname(response.data.name)
              let statename = response1.data
                .filter((item) => item.state_code == response.data.StateCode)
                .map((item) => item.name)
                .toString();
              console.log(response1.data);
              setbillingAddress(response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + " " + response.data.AddrSt + " " + statename);
              console.log(deliveryAddress.length);
              if (deliveryAddress.length !== 0) {
                const updatedAddresses = deliveryAddress.map((item, index) => {
                  if (item.id === response.data.Gstin) {
                    return item;
                  } else {
                    const newValue = {
                      delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + " " + response.data.AddrSt,
                      state: statename,
                      pincode: response.data.AddrPncd,
                      id: response.data.Gstin,
                    };
                    return newValue;
                  }
                });

                const indexOfItemToDelete = updatedAddresses.findIndex((item) => item.id === response.data.Gstin);
                if (indexOfItemToDelete !== -1) {
                  updatedAddresses.splice(indexOfItemToDelete, 1);
                }

                const newItem = {
                  delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + "" + response.data.AddrSt,
                  state: statename,
                  pincode: response.data.AddrPncd,
                  id: response.data.Gstin,
                  id: response.data.Gstin,
                };
                updatedAddresses.push(newItem);

                setdeliveryAddress(updatedAddresses);
              } else {
                setdeliveryAddress([
                  ...deliveryAddress,
                  {
                    delivery_address: response.data.AddrBnm + " " + response.data.AddrBno + " " + response.data.AddrFlno + " " + response.data.AddrLoc + "" + response.data.AddrSt,
                    state: statename,
                    pincode: response.data.AddrPncd,
                    id: response.data.Gstin,
                  },
                ]);
              }

              // console.log(cap);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      alert("Entered Gst is Incorrect");
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const applyFontSizeTransition = (elementId) => {
    const element = document.getElementById(elementId);
    element.style.transition = "font-size 2s";
    element.style.fontSize = "30px";
    element.style.fontWeight = "600";
    // font-weight: 600;
    setTimeout(() => {
      document.getElementById("typer1").classList.add("promote");
      element.style.fontSize = "24px";
    }, 1000);
  };
  const getallstaff = (id) => {
    DistrbutorService.getstaffforret(id).then((res) => {
      // setDistributordata(res.data);
      setasm([res.data.filter((item) => item.rolename == "ROLE_ASM").map((user) => ({ value: user.id, label: user.staff_name }))]);
      setrsm([res.data.filter((item) => item.rolename == "ROLE_RSM").map((user) => ({ value: user.id, label: user.staff_name }))]);
      // setNsm([res.data.filter((item)=>item.rolename=="ROLE_NSM").map(user => ({ value: user.id, label: user.staff_name}))])

      // setNsm
      setase([res.data.filter((item) => item.rolename == "ROLE_ASE").map((user) => ({ value: user.id, label: user.staff_name }))]);
      console.log([res.data.filter((item) => item.rolename == "ROLE_ASE").map((user) => ({ value: user.id, label: user.staff_name }))]);
    });
  };

  
  useEffect(() => {
    // if (gstNumber.length == 0) {
    /* Distributor Data */
    axios
      .get(`http://43.225.54.138:8080/scm/api/distributor/distributortostaff/${id}`, { headers: authHeader() })
      .then((response) => {
        setaseid(response.data.filter((item) => item.aseid !== 0).map((user) => ({ value: user.aseid, label: user.asestaffname })));
        setasmid(response.data.filter((item) => item.asmid !== 0).map((user) => ({ value: user.asmid, label: user.asmstaffname })));
        setrsmid(response.data.filter((item) => item.rsmid !== 0).map((user) => ({ value: user.rsmid, label: user.rsmstaffname })));
        setnsmid(response.data.filter((item) => item.nsmid !== 0).map((user) => ({ value: user.nsmid, label: user.nsmstaffname })));
        console.log(response.data.filter((item) => item.nsmid !== 0).map((user) => ({ value: user.nsmid, label: user.nsmstaffname })));
      })
      .catch((error) => {
        console.error(error);
      });
    DistrbutorService.getdistributorget(id).then((res) => {
      console.log(res.data);
      settradeName(res.data.tradeName);
      setbillingAddress(res.data.billingAddress);
      setdeliveryAddress(res.data.deliveryAddress);
      setcity(res.data.city);
      setRolesforpassword(res.data.roles[0].name);
      setcountry(res.data.country);
      setstatecode(res.data.statecode);
      setpanNumber(res.data.panNumber);
      setgstNumber(res.data.gstNumber);
      setgstType(res.data.gstType);
      setpinCode(res.data.pinCode);
      setperMobileNumber(res.data.perMobileNumber);
      setalterMobileNumber(res.data.alterMobileNumber);
      setperEmail(res.data.perEmail);
      setLatitude(res.data.latitude);
      setLongitude(res.data.longitude);
      setalterEmail(res.data.alterEmail);
      setcreditLimit(res.data.creditLimit);
      setPasswordecrp(res.data.passwordDecrypted);
      setcreditDays(res.data.creditDays);
      settransporterName(res.data.transporterName);
      setdeliveryLocation(res.data.deliveryLocation);
      setboxProductDiscount(res.data.boxProductDiscount);
      setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
      setkgProductDiscount(res.data.kgProductDiscount);
      setschemekgProductDiscount(res.data.schemekgProductDiscount);
      setcorporaetProductDiscount(res.data.corporaetProductDiscount);
      setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
      setcookerProductDiscount(res.data.cookerProductDiscount);
      setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
      setnoshProductDiscount(res.data.noshProductDiscount);
      setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
      setdistributorName1(res.data.distributorName1);
      setdistributorName2(res.data.distributorName2);
      setGroup1Value({ value: res?.data?.groupn1?.id, label: res?.data?.groupn1?.title });
      setGroup2Value({ value: res?.data?.groupn2?.id, label: res?.data?.groupn2?.title });
      setGroup3Value({ value: res?.data?.groupn3?.id, label: res?.data?.groupn3?.title });
      setGroup4Value({ value: res?.data?.groupn4?.id, label: res?.data?.groupn4?.title });
      setGroup5Value({ value: res?.data?.groupn5?.id, label: res?.data?.groupn5?.title });
      // setPassword();
      setRoles1(res.data.roles[0].id);
      setRole(res.data.roles[0].name);
      setdob1(res.data.dob1);
      setdob2(res.data.dob2);
      setPaymentTerms(res.data.paymentTerms);
      setdoa1(res.data.doa1);
      setdoa2(res.data.doa2);
      setmobNo1(res.data.mobNo1);
      setmobNo2(res.data.mobNo2);
      setemail1(res.data.email1);
      setemail2(res.data.email2);
      // setaseid(res.data.aseid);
      // setasmid(res.data.asmid);
      // setrsmid(res.data.rsmid);
      setzonesid(res.data.zonesid);
      setstateid(res.data.stateid);
      getallstaff(res.data.stateid);
      // setnsmid(res.data.nsmid);
      setaadharcard(res.data.aadharcard);
      setcolourtype(res.data.colourtype == "" ? "" : res.data.colourtype);
      let state1 = res.data.stateid;
      let zone = res.data.zonesid;
      let rsm2 = res.data.rsmid;
      let asm1 = res.data.asmid;
      let ase1 = res.data.aseid;
      setemailLoginStatus(res.data.emailLoginStatus);

      if (res.data.billingAddress == res.data.deliveryAddress) {
        //     const button1 = document.getElementById("item");
        // button1.value =res.data.billingAddress;
        const checkbox1 = document.getElementById("neway");
        setsameDeliveryAdd(res.data.billingAddress);
        checkbox1.checked = true;
        setisChecked(true);
      }

      /* Zone Data */
      UserService.getZoneData().then((res_1) => {
        setZoneAlldata([{ value: "", label: "NA" }, ...res_1.data.map((user) => ({ value: user.id, label: user.zoneName }))]);
        setstateAlldata(res_1.data);
      });
      UserService.getZoneDataEdit1(zone).then((res) => {
        setZonedata({ value: res.data.id, label: res.data.zoneName });

        /* State Data */
        // setStatedata((res.data.state_zone).map(user => ({ value: user.id, label: user.state_name })));

        let state = res.data.state_zone;
        setstatedata([{ value: "", label: "NA" }, ...res.data.state_zone.map((user) => ({ value: user.id, label: user.state_name }))]);
        state.map((user) => {
          if (user.id == state1) {
            setstatevalue({ value: user.id, label: user.state_name });
          }
        });
      });

      /* NSM Data */
      UserService.getNSMData().then((res) => {
        setNsm(
          res.data.map((item) => ({
            value: item.id,
            label: item.staffName,
          }))
        );

        const newUser = { value: res.data[0].id, label: res.data[0].staffName };

        // const existingData=nsmid

        // const updatedRsmid = [newUser, ...existingData];

        //  setnsmid((prevNsmid) => [newUser, ...prevNsmid]);
      });

      // /* RSM Data */
      UserService.getRSMData(zone).then((res) => {
        let rsm1 = res.data;

        rsm1.map((user) => {
          if (user.id == rsm2) {
            console.log(user);
            const newUser = { value: user.id, label: user.staffName };

            const existingData = rsmid;
            // .filter(item => item.id !== 0)
            // .map(user => ({ value: user.id, label: user.staffName }));

            const updatedRsmid = [newUser, ...existingData];

            setrsmid(updatedRsmid);
          }
        });
      });

      // /* ASM Data */
      UserService.getASMData(rsm2).then((res) => {
        let Asm = res.data;
        Asm.map((user) => {
          if (user.id == asm1) {
            // setasm({ value: user.id, label: user.staffName })
            console.log(user);
            const newUser = { value: user.id, label: user.staffName };

            const existingData = asmid;
            // .filter(item => item.id !== 0)
            // .map(user => ({ value: user.id, label: user.staffName }));

            const updatedRsmid = [newUser, ...existingData];

            setasmid(updatedRsmid);
          }
        });
      });

      // /* ASE Data */
      UserService.getASEData(asm1).then((res) => {
        let Ase = res.data;
        Ase.map((user) => {
          if (user.id == ase1) {
            // setase({ value: user.id, label: user.staffName })
            // console.log(user)
            const newUser = { value: user.id, label: user.staffName };

            const existingData = aseid;
            // .filter(item => item.id !== 0)
            // .map(user => ({ value: user.id, label: user.staffName }));

            const updatedRsmid = [newUser, ...existingData];

            setaseid(updatedRsmid);
          }
        });
      });
    });
    // }/
    // getgstdetails();
  }, []);

  const onChangeZone = (e) => {
    setzonesid(e.value);
    setstatevalue("");
    setdistrubatorName("");
    setrsm("");
    setasm("");
    setase("");
    setstatedata("");
    setRsmdata("");
    setasmdata("");
    setasedata("");
    let name = e.value;
    setZonedata({ value: e.value, label: e.label });
    stateAlldata.map((user) => {
      if (user.id == name) {
        setZonedata({ value: user.id, label: user.zoneName });
        setstatedata([{ value: "", label: "NA" }, ...user.state_zone.map((item) => ({ value: item.id, label: item.state_name }))]);
      }
    });

    /* RSM DATA */
    UserService.getRSMData(name).then((res) => {
      setRsmdata([{ value: "", label: "NA" }, ...res.data.map((user) => ({ value: user.id, label: user.staffName }))]);
    });
  };

  const onChangeState = (e) => {
    setstateid(e.value);
    setdistrubatorName("");
    setDistrbutorvalue("");
    let name = e.value;
    setstatevalue({ value: e.value, label: e.label });

    getallstaff(e.value);

    /* Distributor Data */
    DistrbutorService.getReatilerTable().then((res) => {
      let dist = [];
      res.data.map((user) => {
        if (user.stateid == name) {
          dist.push(user);
        }
      });

      setDistrbutorvalue([{ value: "", label: "NA" }, ...dist.map((user) => ({ value: user.id, label: user.tradeName }))]);
      setDistrbutor(res.data);
    });
  };
  /* RSM Change */

  // const onRsm = (e) => {

  //   setrsmid(e.value);
  //   setasm('');
  //   setase('');
  //   setasmdata('');
  //   setasedata('');
  //   setrsm({ value: e.value, label: e.label })
  //   /* ASM Data */
  //   UserService.getASMData(e.value).then(res => {
  //     setasmdata([{ value: "", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))]);
  //   });

  //   /* ASE Data */
  //   UserService.getASEData1(e.value).then(res => {
  //     setasedata([{ value: "", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
  //   });
  // };

  // /* ASM Data */
  // const onAsm = (e) => {

  //   setasmid(e.value);
  //   setasedata('');
  //   setasm({ value: e.value, label: e.label })
  //   /* ASM Data */
  //   UserService.getASMedit(e.value).then(res => {
  //     setasm((res.data).map(user => ({ value: user.id, label: user.staffName })));
  //   });

  //   /* ASE Data */
  //   UserService.getASEData(e.value).then(res => {
  //     setasedata([{ value: "", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))]);
  //   });

  // };

  // /* ASE Data */
  // const onAse = (e) => {
  //   setaseid(e.value);
  //   setase({ value: e.value, label: e.label })

  //   /* ASE Data */
  //   UserService.getAEedit(e.value).then(res => {
  //     setase((res.data).map(user => ({ value: user.id, label: user.staffName })))
  //   })
  // };

  /* Date Formate Change */
  const datevalue_1 = new Date(dob1);
  const defaultValue_1 = datevalue_1.toLocaleDateString("en-CA");
  const datevalue_2 = new Date(dob2);
  const defaultValue_2 = datevalue_2.toLocaleDateString("en-CA");
  const datevalue1 = new Date(doa1);
  const defaultValue1 = datevalue1.toLocaleDateString("en-CA");
  const datevalue2 = new Date(doa2);
  const defaultValue2 = datevalue2.toLocaleDateString("en-CA");

  const sendemail = (e) => {
    axios
      .post(
        BASE_URL + "auth/sendverificationcode/otp",
        { email: e },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        setstatus(res.data);
        setverifydone("");
        setopttimeout("");
      })
      .catch((error) => {
        alert("This Email ID is Allready Register");
      });
  };

  const verifyemail = (e, f) => {
    let dataverify = { email: e, otp: f };

    axios
      .post(BASE_URL + "auth/verifyEmailWithOtp", dataverify, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log('mello',res.data)
        if (res.data == "invalid email or otp") {
          alert("Invalid OTP! Please Enter Valid OTP");
        } else if (res.data == "time limit exceed !!! please generate new otp .") {
          alert("Your OTP has been Expired! , Please Generate New OTP");
          setopttimeout(res.data);
        } else {
          setverifydone(res.data);
        }
      });
  };
  console.log(nsmid);
  const [paymentTerms, setPaymentTerms] = useState('')

  const SubmitForm = () => {
    const caddresses = deliveryAddress.map((address) => {
      const { ...rest } = address;
      return rest;
    });
    let roles = [{ id: roles1 }];
    const distributorToStaffs = [
      {
        asmid: asmid.length > 0 ? asmid.map((item) => item.value) : [],
        rsmid: rsmid.length > 0 ? rsmid.map((item) => item.value) : [],
        aseid: aseid.length > 0 ? aseid.map((item) => item.value) : [],
        nsmid: nsmid.length > 0 ? nsmid.map((item) => item.value) : [],
      },
    ];
    const distrbutor = {
      tradeName,
      transporterName,
      billingAddress,
      deliveryAddress: caddresses,
      gstNumber,
      panNumber,
      country,
      city,
      pinCode,
      gstType,
      perMobileNumber,
      alterMobileNumber,
      perEmail,
      alterEmail,
      creditLimit,
      creditDays,
      deliveryLocation,
      boxProductDiscount,
      schemeboxProductDiscount,
      kgProductDiscount,
      schemekgProductDiscount,
      corporaetProductDiscount,
      schemecorporateProductDiscount,
      cookerProductDiscount,
      schemecookerProductDiscount,
      noshProductDiscount,
      schemenoshProductDisocunt,
      distributorName1,
      distributorName2,
      paymentTerms,
      dob1,
      aseid: 0,
      asmid: 0,
      rsmid: 0,
      nsmid: 0,
      dob2,
      doa1,
      doa2,
      distributorToStaffs,
      mobNo1,
      mobNo2,
      email1,
      email2,
      zonesid,
      stateid,
      latitude,
      longitude,
      password,
      roles,
      aadharcard,
      colourtype,
      emailLoginStatus,
      ...(group1Value?.value ? { groupn1: { id: group1Value.value } } : {}),
      ...(group2Value?.value ? { groupn2: { id: group2Value.value } } : {}),
      ...(group3Value?.value ? { groupn3: { id: group3Value.value } } : {}),
      ...(group4Value?.value ? { groupn4: { id: group4Value.value } } : {}),
      ...(group5Value?.value ? { groupn5: { id: group5Value.value } } : {}),
    };
    // DistrbutorService.getdistributorEdit(id, distrbutor).then(res => {
    //   navigate('/distrbutortable')
    // })
    if (
      tradeName.length !== 0 &&
      emailLoginStatus !== "" &&
      billingAddress.length !== 0 &&
      gstNumber.length !== 0 &&
      zonesid.length !== 0 &&
      stateid.length !== 0 &&
      city.length !== 0 &&
      pinCode.length !== 0 &&
      gstType.length !== 0 &&
      perMobileNumber.length !== 0 &&
      perEmail.length !== 0 &&
      creditLimit.length !== 0 &&
      creditDays.length !== 0 &&
      deliveryLocation.length !== 0 &&
      distributorName1.length !== 0 &&
      dob1.length !== 0 &&
      email1.length !== 0 &&
      mobNo1.toString().length == 10 &&
      role.length !== 0
    ) {
      setError(false);
      setIsLoading(true);

      DistrbutorService.getdistributorEdit(id, distrbutor)
        .then((res) => {
          if(JSON.parse(localStorage.getItem('user')).roles[0]=='ROLE_DISTRIBUTOR'){
            // fetchProfilePictureret(JSON.parse(localStorage.getItem('user')).id)
            navigate('/')
          }else{
            navigate(`/Newdis/${pointindex}`);
  
          }


          if (res.status == 200) {
            // alert("ee")
            Toast.fire({
              icon: "success",
              title: "Successfully Updated",
            });
          }
        })
        .catch((error) => {
          console.error("Error adding  :", error);
          if (error.message.includes("Network Error") || error.message.includes("Failed to fetch")) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `
            <div>
              <p id="issueText">Why do I have this issue?</p>
              <ul>
          
              <li id="typer1">You might have an internet issue</li>
              </ul>
            </div>
          `,
              didOpen: () => {
                applyFontSizeTransition("issueText");
              },
            });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError(true);
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...deliveryAddress];
    newFormValues[i][e.target.name] = e.target.value;
    setdeliveryAddress(newFormValues);
  };

  let addFormFields = () => {
    setdeliveryAddress([...deliveryAddress, { delivery_address: "", state: "", pincode: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...deliveryAddress];
    newFormValues.splice(i, 1);
    setdeliveryAddress(newFormValues);
  };
  const handledelete = (id) => {
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DistrbutorService.getDelete(id).then((res) => {
          // window.location.reload();
          console.log(res.data);
          if (res.data == 200 || res.data == 201) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            navigate(`/Newdis/${pointindex}`);
          }
        });
      }
    });
  };
  const onNsm = (e) => {
    // setnsmid(e.map((item)=>item.value));
    setnsmid(e.map((item) => ({ value: item.value, label: item.label })));
  };

  /* RSM Change */
  const onRsm = (e) => {
    // setrsmid(e.map((item)=>item.value));
    setrsmid(e.map((item) => ({ value: item.value, label: item.label })));
  };

  /* ASM Change */
  const onAsm = (e) => {
    // setase('');
    // setasmid(e.map((item)=>item.value));
    setasmid(e.map((item) => ({ value: item.value, label: item.label })));

    // setasevalue('');
    // setasmvalue({ value: (e.value), label: (e.label) });
    /* ASE Data */
    // UserService.getASEData(e.value).then(res => {
    //     setase([{ value: "NAN", label: "NA" }, ...(res.data).map(user => ({ value: user.id, label: user.staffName }))])
    // });
  };

  /* ASE Chnage */
  const onAse = (e) => {
    // setaseid(e.map((item)=>item.value));
    setaseid(e.map((item) => ({ value: item.value, label: item.label })));

    console.log(e.map((item) => item.value));
    // setasevalue({ value: (e.value), label: (e.label) })
  };
const [boolean, setBoolean] = useState(false);
const [dataFromChild, setDataFromChild] = useState('');
const handleDataFromChild = (data) => {
  setBoolean(data);
};
  return (
    <MDBCard>
  {  boolean==false?<>  <div className="d-flex bd-highlight">
        <div className="card-header card-title w-100">EDIT DISTRIBUTOR</div>
        {/* <Link className='p-2 w-200 card-header card-title bd-highlight' to={'/distrbutortable'}><Button>Back</Button></Link> */}
        <a className="prox btn" style={{ width: "auto" }} onClick={()=>{setBoolean(true)}} disabled={isLoading}>
                {isLoading ? "Loading..." :<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"/><circle cx="8.5" cy="8.5" r="1.5"/><polyline points="21 15 16 10 5 21"/></svg>}
              </a>
       
        <Link className="prox btn" to={`/Newdis/${pointindex}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
          >
            <line x1="19" y1="12" x2="5" y2="12" />
            <polyline points="12 19 5 12 12 5" />
          </svg>
        </Link>
        {user.roles[0] === "ROLE_ADMIN" && (
          <a className="delete  btn" onClick={() => handledelete(id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-trash-2"
            >
              <polyline points="3 6 5 6 21 6" />
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
              <line x1="10" y1="11" x2="10" y2="17" />
              <line x1="14" y1="11" x2="14" y2="17" />
            </svg>
          </a>
        )}
      </div>
      <div className="card">
        {/* <div className="card-header card-title">EDIT DISTRIBUTOR</div> */}
        <div className="card card-body">
          <Row className="mb-1">
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Trade Name*</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                style={{ borderColor: error && tradeName.length == 0 ? "red" : "" }}
                onChange={(e) => settradeName(e.target.value)}
                name="tradeName"
                value={tradeName}
              />
              {error && tradeName.length === 0 ? <p style={{ color: error && tradeName.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Billing Address</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={billingAddress}
                name="billingAddress"
                onChange={(e) => {
                  setbillingAddress(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">GST No*</Form.Label>
              <Form.Control
                type="text"
                style={{ borderColor: error && gstNumber.length == 0 ? "red" : "" }}
                autoComplete="off"
                value={gstNumber}
                name="gstNumber"
                onChange={(e) => setgstNumber(e.target.value)}
              />
              {error && gstNumber.length === 0 ? <p style={{ color: error && gstNumber.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <a className="btn btn-sm ertere" onClick={getgstdetails}>
              Get Gst Details
            </a>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Pan Number</Form.Label>
              <Form.Control type="text" autoComplete="off" value={panNumber} name="panNumber" onChange={(e) => setpanNumber(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Aadhar card Number</Form.Label>
              <Form.Control type="text" autoComplete="off" value={aadharcard} name="aadharcard" onChange={(e) => setaadharcard(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Country</Form.Label>
              <Select value={countryoptions} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Zone*</Form.Label>
              <Select value={zonedata} options={zoneAlldata} onChange={(e) => onChangeZone(e)} style={{ borderColor: error && zonesid.length === 0 ? "red" : "" }} />
              {error && zonesid.length === 0 ? <p style={{ color: error && zonesid.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">State*</Form.Label>
              <Select value={statevalue} options={statedata} onChange={(e) => onChangeState(e)} style={{ borderColor: error && stateid.length === 0 ? "red" : "" }} />
              {error && stateid.length === 0 ? <p style={{ color: error && stateid.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">City*</Form.Label>
              <Form.Control type="text" style={{ borderColor: error && city.length == 0 ? "red" : "" }} autoComplete="off" value={city} name="city" onChange={(e) => setcity(e.target.value)} />
              {error && city.length === 0 ? <p style={{ color: error && city.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Zip Code*</Form.Label>
              <Form.Control
                type="number"
                min="0"
                style={{ borderColor: error && pinCode.length == 0 ? "red" : "" }}
                autoComplete="off"
                value={pinCode}
                name="pinCode"
                onChange={(e) => setpinCode(e.target.value)}
              />
              {error && pinCode.length === 0 ? <p style={{ color: error && pinCode.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Distributor Type</Form.Label>
              <Form.Select value={colourtype} name="colourtype" onChange={(e) => setcolourtype(e.target.value)}>
                <option>--Select--</option>
                <option value="bluedistributor">Blue Distributor</option>
                <option value="whitedistributor">White Distributor</option>
                <option value="orangedistributor">Orange Distributor</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Gst Type*</Form.Label>
              <Form.Select value={gstType} style={{ borderColor: error && gstType.length == 0 ? "red" : "" }} name="gstType" onChange={(e) => setgstType(e.target.value)}>
                <option>Select</option>
                <option value="unregister">UnRegister</option>
                <option value="register">Register</option>
                <option value="composition">Composition</option>
                <option value="consumer">Consumer</option>
              </Form.Select>
              {error && gstType.length === 0 ? <p style={{ color: error && gstType.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Mobile No*</Form.Label>
              <Form.Control
                type="number"
                style={{ borderColor: error && perMobileNumber.length !== 10 ? "red" : "" }}
                autoComplete="off"
                value={perMobileNumber}
                name="perMobileNumber"
                onChange={(e) => setperMobileNumber(e.target.value)}
              />
              {error && perMobileNumber.length !== 10 ? <p style={{ color: error && gstType.length !== 10 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Alter Mobile No</Form.Label>
              <Form.Control type="number" autoComplete="off" value={alterMobileNumber} name="alterMobileNumber" onChange={(e) => setalterMobileNumber(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">OTP Login</Form.Label>
              <Form.Select value={emailLoginStatus} onChange={(e) => setemailLoginStatus(e.target.value)}>
                <option>--Select--</option>
                <option value={"Enable"}>Enable</option>
                <option value={"Disable"}>Disable</option>
              </Form.Select>
            </Form.Group>

            {emailLoginStatus === "Disable" ? (
              <>
                <Form.Group as={Col} md="6" controlId="formBasicName">
                  <Form.Label className="cname2">Email*</Form.Label>
                  <Form.Control
                    type="email"
                    style={{ borderColor: error && perEmail.length === 0 ? "red" : "" }}
                    autoComplete="off"
                    value={perEmail}
                    name="perEmail"
                    onChange={(e) => setperEmail(e.target.value)}
                    readOnly
                  />
                  {error && perEmail.length === 0 ? <p style={{ color: error && perEmail.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
                </Form.Group>
              </>
            ) : (
              <>
                <Form.Group as={Col} md="5" controlId="formBasicName">
                  <Form.Label className="cname2">Email*</Form.Label>
                  <Form.Control
                    type="email"
                    value={perEmail}
                    name="perEmail"
                    autoComplete="off"
                    disabled={verifydone == "" ? false : opttimeout == "" ? true : false}
                    style={{ borderColor: error && perEmail.length === 0 ? "red" : "" }}
                    onChange={(e) => setperEmail(e.target.value)}
                    placeholder="Enter Email"
                  />
                  {error && perMobileNumber.length === 0 ? <p style={{ color: error && perMobileNumber.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
                </Form.Group>
                {verifydone == "" ? (
                  <Form.Group as={Col} md="1" controlId="formBasicName">
                    <Form.Label className="cname2"></Form.Label>
                    <Button onClick={() => sendemail(perEmail)}>OTP Generate</Button>
                  </Form.Group>
                ) : opttimeout == "" ? (
                  <Form.Group as={Col} md="1" controlId="formBasicName">
                    <Form.Label className="cname2"></Form.Label>
                    <span class="snackbar_circle">
                      <span class="checkmark">L</span>
                    </span>
                  </Form.Group>
                ) : (
                  <Form.Group as={Col} md="1" controlId="formBasicName">
                    <Form.Label className="cname2"></Form.Label>
                    <Button onClick={() => sendemail(perEmail)}>OTP Generate</Button>
                  </Form.Group>
                )}
                {status !== "" ? (
                  verifydone == "" && opttimeout == "" ? (
                    <Row>
                      <Form.Group as={Col} md="1" controlId="formBasicName">
                        <Form.Label className="cname2">Verification Code*</Form.Label>
                        <Form.Control type="text" autoComplete="off" onChange={(e) => setsendotp(e.target.value)} placeholder="Enter OTP" />
                      </Form.Group>

                      <Form.Group as={Col} md="1" controlId="formBasicName">
                        <Form.Label className="cname2"></Form.Label>
                        <Button onClick={() => verifyemail(perEmail, sendotp)}>Verify</Button>
                      </Form.Group>

                      <Form.Group as={Col} md="4" controlId="formBasicName"></Form.Group>
                    </Row>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </>
            )}
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Alter Email</Form.Label>
              <Form.Control type="email" autoComplete="off" value={alterEmail} name="alterEmail" onChange={(e) => setalterEmail(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Credit Limit*</Form.Label>
              <Form.Control
                type="number"
                style={{ borderColor: error && creditLimit.length === 0 ? "red" : "" }}
                autoComplete="off"
                value={creditLimit}
                name="creditLimit"
                onChange={(e) => setcreditLimit(e.target.value)}
              />
              {error && creditLimit.length === 0 ? <p style={{ color: error && creditLimit.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Credit Days*</Form.Label>
              <Form.Control
                type="number"
                autoComplete="off"
                style={{ borderColor: error && creditDays.length === 0 ? "red" : "" }}
                value={creditDays}
                name="creditDays"
                onChange={(e) => setcreditDays(e.target.value)}
              />
              {error && creditDays.length === 0 ? <p style={{ color: error && creditDays.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Transporter Name</Form.Label>
              <Form.Control type="text" autoComplete="off" value={transporterName} name="transporterName" onChange={(e) => settransporterName(e.target.value)} />
            </Form.Group>

            {/* <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Current Password  </Form.Label>
              <Form.Control type="text" readonly autoComplete="off" value={passworddecrp} name="passworddecrp"    />
            </Form.Group> */}
               {currentRole=='ROLE_ADMIN'||currenttokenid==id&&rolesforpassword==currentRole  ?  <Form.Group as={Col} md="6" controlId="formBasicName">
                                      <Form.Label className="cname2"><b>Current Password : -</b></Form.Label>
                                      <Form.Control type="text" disabled autoComplete="off" value={passworddecrp} name="passworddecrp"    />
                                      </Form.Group>:''}
            {/* passworddecrp */}
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Delivery Location*</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                style={{ borderColor: error && deliveryLocation.length === 0 ? "red" : "" }}
                value={deliveryLocation}
                name="deliveryLocation"
                onChange={(e) => setdeliveryLocation(e.target.value)}
              />
              {error && deliveryLocation.length === 0 ? <p style={{ color: error && deliveryLocation.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
              <Form.Label className="cname2">  Terms of Payment  </Form.Label>
              <Form.Control
                autoComplete="off"
                // style={{ borderColor: error && transporterName.length == 0 ? "red" : "" }}
                type="text"
                onChange={(e) => setPaymentTerms(e.target.value)}
                placeholder="Enter Payment Terms"
              />
            
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Box&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={boxProductDiscount} name="boxProductDiscount" onChange={(e) => setboxProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Box&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={schemeboxProductDiscount} name="schemeboxProductDiscount" onChange={(e) => setschemeboxProductDiscount(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">KG&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={kgProductDiscount} name="kgProductDiscount" onChange={(e) => setkgProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">KG&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={schemekgProductDiscount} name="schemekgProductDiscount" onChange={(e) => setschemekgProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={corporaetProductDiscount} name="corporaetProductDiscount" onChange={(e) => setcorporaetProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Corporate&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
              <Form.Control
                type="number"
                autoComplete="off"
                value={schemecorporateProductDiscount}
                name="schemecorporateProductDiscount"
                onChange={(e) => setschemecorporateProductDiscount(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={cookerProductDiscount} name="cookerProductDiscount" onChange={(e) => setcookerProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Cooker&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={schemecookerProductDiscount} name="schemecookerProductDiscount" onChange={(e) => setschemecookerProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">Nosh&nbsp;Product&nbsp;Trade&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={noshProductDiscount} name="noshProductDiscount" onChange={(e) => setnoshProductDiscount(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="formBasicName">
              <Form.Label className="cname2">NOSH&nbsp;Product&nbsp;Scheme&nbsp;Discount</Form.Label>
              <Form.Control type="number" autoComplete="off" value={schemenoshProductDisocunt} name="schemenoshProductDisocunt" onChange={(e) => setschemenoshProductDisocunt(e.target.value)} />
            </Form.Group>

            <Row>
              <Form.Group as={Col} md="4" controlId="formBasicName">
                <br></br>
                <Form.Label className="cname2">Group-1</Form.Label>
                <Select value={group1Value} isDisabled={disabled1} options={groupn1Options} onChange={(e) => changeHandler(e, "groupn1")} onInputChange={(e) => setGroup1Search(e)} />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formBasicName">
                <br></br>
                <Form.Label className="cname2">Group-2</Form.Label>
                <Select value={group2Value} isDisabled={disabled2} options={groupn2Options} onChange={(e) => changeHandler(e, "groupn2")} onInputChange={(e) => setGroup2Search(e)} />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formBasicName">
                <br></br>
                <Form.Label className="label">Group-3</Form.Label>
                <Select value={group3Value} isDisabled={disabled3} options={groupn3Options} onChange={(e) => changeHandler(e, "groupn3")} onInputChange={(e) => setGroup3Search(e)} />
              </Form.Group>

              <Form.Group as={Col} md="4" controlId="formBasicName">
                <Form.Label className="cname2">Group-4</Form.Label>
                <Select value={group4Value} isDisabled={disabled4} options={groupn4Options} onChange={(e) => changeHandler(e, "groupn4")} onInputChange={(e) => setGroup4Search(e)} />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="formBasicName">
                <Form.Label className="cname2">Group-5</Form.Label>
                <Select value={group5Value} isDisabled={disabled5} options={groupn5Options} onChange={(e) => changeHandler(e, "groupn5")} onInputChange={(e) => setGroup5Search(e)} />
              </Form.Group>
            </Row>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Distributor Name&nbsp;1*</Form.Label>
              <Form.Control
                type="text"
                autoComplete="off"
                value={distributorName1}
                name="distributorName1"
                style={{ borderColor: error && distributorName1.length === 0 ? "red" : "" }}
                onChange={(e) => setdistributorName1(e.target.value)}
              />
              {error && distributorName1.length === 0 ? <p style={{ color: error && distributorName1.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Distributor Name&nbsp;2</Form.Label>
              <Form.Control type="text" autoComplete="off" value={distributorName2} name="distributorName2" onChange={(e) => setdistributorName2(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Date&nbsp;of&nbsp;Birth&nbsp;1*</Form.Label>
              <Form.Control type="date" style={{ borderColor: error && dob1.length === 0 ? "red" : "" }} value={defaultValue_1} name="dob1" onChange={(e) => setdob1(e.target.value)} />
              {error && dob1.length === 0 ? <p style={{ color: error && dob1.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Date&nbsp;of&nbsp;Birth&nbsp;2</Form.Label>
              <Form.Control type="date" value={defaultValue_2} name="dob2" onChange={(e) => setdob2(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;1</Form.Label>
              <Form.Control type="date" value={defaultValue1} name="doa1" onChange={(e) => setdoa1(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Date&nbsp;of&nbsp;Anniversary&nbsp;2</Form.Label>
              <Form.Control type="date" value={defaultValue2} name="doa2" onChange={(e) => setdoa2(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Mobile No&nbsp;1*</Form.Label>
              <Form.Control
                type="number"
                style={{ borderColor: error && mobNo1.toString().length !== 10 ? "red" : "" }}
                autoComplete="off"
                value={mobNo1}
                name="mobNo1"
                onChange={(e) => setmobNo1(e.target.value)}
              />
              {error && mobNo1.toString().length !== 10 ? <p style={{ color: error && mobNo1.toString().length !== 10 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Mobile No&nbsp;2</Form.Label>
              <Form.Control type="number" autoComplete="off" value={mobNo2} name="mobNo2" onChange={(e) => setmobNo2(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Email&nbsp;1*</Form.Label>
              <Form.Control
                type="email"
                style={{ borderColor: error && email1.length === 10 ? "red" : "" }}
                autoComplete="off"
                value={email1}
                name="email1"
                onChange={(e) => setemail1(e.target.value)}
              />
              {error && email1.length === 0 ? <p style={{ color: error && email1.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Email&nbsp;2</Form.Label>
              <Form.Control type="email" autoComplete="off" value={email2} name="email2" onChange={(e) => setemail2(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md="6" className="ert1" controlId="formBasicName">
              <Form.Label className="cname2">NSM</Form.Label>
              <Select
                options={nsm}
                value={nsmid}
                isMulti
                onChange={(e) => {
                  onNsm(e);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="ert2" controlId="formBasicName">
              <Form.Label className="cname2">RSM</Form.Label>
              <Select
                isMulti
                options={rsm[0]}
                value={rsmid}
                onChange={(e) => {
                  onRsm(e);
                }}
              />
            </Form.Group>
            {console.log(rsmid)}
            <Form.Group as={Col} md="6" className="ert1" controlId="formBasicName">
              <Form.Label className="cname2">ASM</Form.Label>
              <Select
                isMulti
                options={asm[0]}
                value={asmid}
                onChange={(e) => {
                  onAsm(e);
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" className="ert2" controlId="formBasicName">
              <Form.Label className="cname2">ASE</Form.Label>
              <Select
                options={ase[0]}
                isMulti
                value={aseid}
                onChange={(e) => {
                  onAse(e);
                }}
              />
            </Form.Group>
            <div className="row">
              <div className="col">
                <label className="form-label">Latitude</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setLatitude(e.target.value);
                  }}
                  value={latitude}
                  className="form-control"
                />
              </div>
              <div className="col">
                <label className="form-label">Longitude</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setLongitude(e.target.value);
                  }}
                  value={longitude}
                  className="form-control"
                />
              </div>
            </div>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Password*</Form.Label>
              <Form.Control type="text" style={{ borderColor: error && password.length === 0 ? "red" : "" }} autoComplete="off" name="password" onChange={(e) => setPassword(e.target.value)} />
              {error && password.length === 0 ? <p style={{ color: error && password.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicName">
              <Form.Label className="cname2">Roles*</Form.Label>
              <Form.Control style={{ borderColor: error && role.length === 0 ? "red" : "" }} type="text" value={role} />
              {error && role.length === 0 ? <p style={{ color: error && role.length === 0 ? "red" : " " }}>This field is Required</p> : ""}
            </Form.Group>
            <button type="button" className="btn btn-sm btn-primary" onClick={addFormFields}>
              +
            </button>
            <div className="row">
              <div className="col-6 card">
                {/* <label>Adresss</label> */}
                {deliveryAddress.map((address, index) => (
                  <div key={index}>
                    <label>Address</label>
                    <input type="text" name="delivery_address" className="form-control" value={address.delivery_address} onChange={(e) => handleChange(index, e)} />
                    <label className="form-label">Statename</label>
                    <input type="text" value={address.state} onChange={(e) => handleChange(index, e)} name="state" className="form-control" />

                    <label className="form-label">Pin-Code</label>
                    <input type="text" value={address.pincode} onChange={(e) => handleChange(index, e)} name="pincode" className="form-control" />
                    <button type="button" className="btn btn-sm" onClick={() => removeFormFields(index)}>
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Row>
          <Row>
            <Col>
              {/* <Button variant="primary" type="button" onClick={SubmitForm} >
              Submit
            </Button>
             */}
              <Button variant="primary" style={{ width: "auto" }} onClick={SubmitForm} disabled={isLoading}>
                {isLoading ? "Loading..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </div>
      </div></>:<Profilestaffcom axiosdata='distributor' sendDataToParent={handleDataFromChild} butvisble='true' userfirend='distributorid' steper='true' routerarea='/Newdis/1'/>}
    </MDBCard>
  );
}
