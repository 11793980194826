import React from "react";
 import D4 from "../services/D4";

const SalesExpenseIndexTable = () => {
  const header = [
    { label: "ID", field: "id" },
    { label: "Staff Name", field: "staffname" },
    { label: "Designation", field: "designation" },
    { label: "Expense Date", field: "expdate" },
    { label: "Type", field: "hometown" },
    { label: "Status", field: "status" },

    // { label: "Daily Allowances", field: "dailyallownces" },
    {
      label: "Action",
      field: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-eye"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
          <circle cx="12" cy="12" r="3" />
        </svg>
      ),
    },
  ];

  return (
    <D4
      header={header}
      tableTitle={"Sales Expense"}
      apiField={"salesexp"}
      addButton={"Add Sales Expense"}
      createRoute={"/salesexpense/create"}
      updateRoute={"/salesexpense/view"}
      viewRoute={"/salesexpense/view"}
    />
  );
};

export default SalesExpenseIndexTable;
