import React, { useEffect, useState } from "react";
import WareHouseService from "../services/WareHouseService";
import { SecondaryOrderComponent } from "./SecondaryOrderComponent";
function SecondaryOrderReports() {

  let header = [
    { label: "Order Created Date", field: "ordercreateddate" },
    { label: "Order Date", field: "orderdate" },
    { label: "ORDER ID", field: "orderid" },
    { label: "Trade Name", field: "tradename" },
 
    { label: "City", field: "city" },
    { label: "State", field: "state" },
    { label: "Zone", field: "zone" },
    { label: "RSM Name", field: "rsmname" },
    { label: "ASE Name", field: "asename" },
    { label: "Product Name", field: "productname" },
    { label: "EAN CODE", field: "eancode" },
    { label: "Product Group", field: "productgroup" },
    { label: "Qty PRM", field: "qtyprm" },
    { label: "Qty Alt", field: "qtyalt" },
    { label: "Rate", field: "rate" },
    { label: "Gross Amount", field: "grossamount" },
    { label: "1St Dis%", field: "1stdis" },
    { label: "2nd Dis%", field: "2nddis" },
    { label: "Taxable Value", field: "taxablevalue" },
    { label: "Tax Value", field: "taxvalue" },
    { label: "Total Value", field: "totalvalue" },
    { label: "Create By", field: "createdby" },
    { label: "Created Time", field: "createdtime" }

  ];
  const sumofkeys = [{ first: "closingstock" }];
  return (
    <SecondaryOrderComponent
      head={header}
      title={"Secondary Order Reports"}
      axiostabledata={""}
      Add={""}
      Create={""}
      Edit={""}
      View={""}
      Sumofkeys={sumofkeys}
    />
  );
}

export default SecondaryOrderReports;
