import axios from "axios";
import { MDBCard } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row, Table } from "react-bootstrap";
import { BASE_URL } from "../services/Api";
import authHeader from "../services/auth-header";
import Select from "react-select";
import ProductService from "../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { changecostsales, changesalescalmeasurement, changesalesdiscount, changesalesmeasurement, changesalesqty, changesalessqpbmeasurement, changesalestext, changeunitofMeasurementsales, emptysalesdata, removefromcartfromsales, salesaddtocart } from "../../redux/Action/Sales";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CompanyService from "../services/CompanyService";

export default function Create1() {
    const navigate = useNavigate();

    const modes1=[
        { "id": 1, "label": "Road" },
        { "id": 2, "label": "Rail" },
        { "id": 3, "label": "Air" },
        { "id": 4, "label": "Ship" },
        { "id": 5, "label": "In Transit" }
      ]
      const vechiletype=[
        { "id": "R", "label": "Regular" },
        { "id": "O", "label": "ODC" }
      ]
      const transtype=[
        { "id": 1, "label": "Regular" },
        { "id": 2, "label": "Bill To - Ship To" },
        { "id": 3, "label": "Bill From - Dispatch From" },
        { "id": 4, "label": "Combination of 2 and 3" }
      ]
   

    const [isLoading, setIsLoading] = useState(false);
    const applyFontSizeTransition = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.transition = 'font-size 2s';
        element.style.fontSize = '30px';
        element.style.fontWeight = '600';
        // font-weight: 600;
        setTimeout(() => {

            document.getElementById("typer1").classList.add('promote');
            element.style.fontSize = '24px';
        }, 1000);
    };
    const [warehousehdata, setwarehousehdata] = useState([]);
    const [warehousehvalue, setwarehousehvalue] = useState([]);
    const [warehouse, setwarehouse] = useState('');
    const [retailer, setretailer] = useState('');
    const [customer, setcustomer] = useState('');
    const [distributor, setdistributor] = useState('');
    const [buyerorderno, setbuyerorderno] = useState('');
    const [invoiceno, setinvoiceno] = useState('');
    const [invoicedate, setinvoicedate] = useState('');
    const [status, setstatus] = useState('true');
    const [taxtype, settaxtype] = useState("withtax");
    const [termsofdelivery, settermsofdelivery] = useState('');
    const [deliverynoteno, setdeliverynoteno] = useState('');
    const [destination, setdestination] = useState('');
    const [packaginglistno, setpackaginglistno] = useState('');
    const [gstno, setgstno] = useState('');
    const [panno, setpanno] = useState('');
    const [ewaybillno, setewaybillno] = useState('');
    const [grnno, setgrnno] = useState('');
    const [lrno, setlrno] = useState('');
    const [udyamno, setudyamno] = useState('');
    const [msmeno, setmsmeno] = useState('');
    const [productdata, setproductdata] = useState([]);
    const [boxProductDiscount, setboxProductDiscount] = useState('');
    const [schemeboxProductDiscount, setschemeboxProductDiscount] = useState('');
    const [kgProductDiscount, setkgProductDiscount] = useState('');
    const [schemekgProductDiscount, setschemekgProductDiscount] = useState('');
    const [corporaetProductDiscount, setcorporaetProductDiscount] = useState('');
    const [schemecorporateProductDiscount, setschemecorporateProductDiscount] = useState('');
    const [cookerProductDiscount, setcookerProductDiscount] = useState('');
    const [schemecookerProductDiscount, setschemecookerProductDiscount] = useState('');
    const [noshProductDiscount, setnoshProductDiscount] = useState('');
    const [schemenoshProductDisocunt, setschemenoshProductDisocunt] = useState('');
    const [branchdata, setbranchdata] = useState([]);
    const [branch, setbranch] = useState('');
    const [bstateid, setbstateid] = useState('');
    const [dstateid, setdstateid] = useState('');
    const [shippingcharge, setShipping] = useState(0);
    const [round, setRound] = useState(0);
    const [paymentTerms, setpaymentTerms] = useState('');
    const [disdel, setdel] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [deliveryAddress, setdelval] = useState([]);
    const [lrndate, setlrndate] = useState('');
    const [edd, setedd] = useState('');
    const [grndate, setgrndate] = useState('');
    const [totalnopkg, settotalnopkg] = useState('');
    const [dispatchedthrough, setdispatchedthrough] = useState('');
    const [img, setimg] = useState();
    const [pdf, setpdf] = useState();
    const [taxSch, settaxSch] = useState('');
    const [supTyp, setsupTyp] = useState('');
    const [no, setno] = useState('');
    const [dt1, setdt1] = useState('');
    const [seller, setseller] = useState({});
    const [buyer, setbuyer] = useState({});
    const [distance, setdistance] = useState('');
    const [transMode, settransMode] = useState('');
    const [transId, settransId] = useState('');
    const [transName, settransName] = useState('');
    const [transDocDt1, settransDocDt1] = useState('');
    const [transDocNo, settransDocNo] = useState('');
    const [vehNo, setvehNo] = useState('');
    const [vehType, setvehType] = useState('');
    const [voucherData, setvoucherData] = useState([]);
    const [voucherid, setvoucherid] = useState('');


    const dispatch = useDispatch();
    let salesItems1 = useSelector((state) => state.salesdata);
    let da_ta = salesItems1.length && salesItems1.map((user) => ({
        "brand": user.brand,
        "calunitofmeasurement": user.calunitofmeasurement,
        "capacity": user.capacity,
        "category": user.category,
        "cgst": user.cgst,
        "cgstLedger": user.cgstLedger,
        "diameter": user.diameter,
        "dlp": user.dlp,
        "eanCode": user.eanCode,
        "grossamount": user.grossamount,
        "gstvalue": user.gstvalue,
        "hsnCode": user.hsnCode,
        "igst": user.igst,
        "igstLedger": user.igstLedger,
        "netAmount": user.netAmount,
        "product": { "id": user.product.id },
        "productName": user.productName,
        "productType": user.productType,
        "salesquantity": user.salesquantity,
        "salesquantitykgs": user.salesquantitykgs,
        "schemeDiscount": user.schemeDiscount,
        "sgst": user.sgst,
        "sgstLedger": user.sgstLedger,
        "shortName": user.shortName,
        "soid": user.soid,
        "standardQtyPerBox": user.standardQtyPerBox,
        "total": user.total,
        "tradeDiscount": user.tradeDiscount,
        "unitofmeasurement": user.unitofmeasurement,
        "uomPrimary": user.uomPrimary,
        "uomSecondary": user.uomSecondary,
        // "SlNo":user.soid,
        "prdDesc": user.PrdDesc,
        "isServc": "N",
        "qty": user.salesquantitykgs,
        "freeQty": user.salesquantity,
        "unit": user.unitofmeasurement,
        "totAmt": user.grossamount,
        "discount": ((Number(user.grossamount) / 100) * Number(user.schemeDiscount)).toFixed(2),
        "assAmt": user.netAmount,
        "gstRt": user.igst,
        "igstAmt": seller.stcd === buyer.stcd ? 0 : Number(user.gstvalue),
        "cgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "sgstAmt": seller.stcd === buyer.stcd ? ((user.gstvalue) / 2) : 0,
        "totItemVal": user.total


    }));
console.log(salesItems1)
    let gross_Amount = salesItems1.length && salesItems1.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
    let gross_AmountassVal = salesItems1.length && salesItems1.map(item => (item.netAmount)).reduce((prev, next) => (prev - (-next)));
    let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let cgstVal = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));
    let aa = [];
    salesItems1.map(user => {
        let igst_value = user.igst;
        let total_value = user.total;
        let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
        let add_amou = Number(total_amou) - (-Number(total_value));
        let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
        aa.push(final_amou)

        // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
    })
    let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


    let grossamount = Number(gross_Amount) + Number(shippingcharge);
    let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(round) + Number(total_per);

    let igstTax = total_per == "" ? Number(gst) : Number(total_per);

    let cgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;
    let sgstTax = total_per == "" ? (Number(gst) / 2) : (Number(total_per)) / 2;


    function roundData(e) {
        if (e) {
            let gross_Amount = salesItems1.length && salesItems1.map(item => Math.round(item.total)).reduce((prev, next) => Math.round(prev - (-next)));
            let gst = salesItems1.length && salesItems1.map(item => (item.gstvalue)).reduce((prev, next) => (prev - (-next)));

            let aa = [];
            salesItems1.map(user => {
                let igst_value = user.igst;
                let total_value = user.total;
                let total_amou = ((Number(shippingcharge) / Number(gross_Amount)) * Number(total_value));
                let add_amou = Number(total_amou) - (-Number(total_value));
                let final_amou = ((Number(add_amou) / 100) * Number(igst_value)).toFixed(2);
                aa.push(final_amou)

                // console.log("mello",((Number(add_amou)/100)*Number(igst_value)).reduce((prev, next) => Math.round(prev - (-next))) );
            })
            let total_per = aa.length && aa.map(user => user).reduce((prev, next) => (prev - (-next)));


            let grossamount = Number(gross_Amount) + Number(shippingcharge);
            let grandTotal = Number(gross_Amount) + Number(shippingcharge) + Number(total_per);
            let grand_Total = Math.ceil(grandTotal);

            if (grand_Total > grandTotal) {
                let rou_nd = (Number(grand_Total) - Number(grandTotal)).toFixed(2);
                setRound(rou_nd);
            } else {
                setRound(0)
            }


        } else if (e == '') {
            setRound(0)
        }
    }
    const [setsellindata, setSetsellindata] = useState([])
    let getcompanydata = () => {
        const id = 1;
        CompanyService.getcompany(id).then((res) => {
            const transformedData = {
                "gstin": res.data.gst,
                "lglNm": res.data.name,
                "addr1": res.data.address,
                "loc": res.data.cities,
                "pin": parseInt(res.data.zipcode),
                "stcd": res.data.states.state_code,
                "ph": res.data.phone,
                "em": res.data.email
            };
            setSetsellindata(transformedData)
            console.log("mello", [transformedData].map((item) => item.gstin));
        });
    };

    /* Get Branch Data */

    const getbranchdata = () => {

        axios.get(BASE_URL + 'branch/getAll', {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbranchdata((res.data).map(user => ({ value: user.id, label: user.branchname })));
        })
    };

    /* get Distributor data */
    const handleRemoveSelection = () => {
        setSelectedOption(null);
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(selectedOption)
        setdelval(selectedOption.value)
    };


    const options = disdel.map(item => ({
        value: item.id,
        label: `Address: ${item.delivery_address}, State: ${item.state}, Pin-code: ${item.pincode}`
    }));
    const customFilter11 = (option, searchText) => {
        return option.label.toLowerCase().includes(searchText.toLowerCase());
    };


    const [comapny, setComapny] = useState([])
    const companydetails=()=>{
      
      axios.get(`${BASE_URL}company/getAll`, { headers: authHeader() })
      .then(response => {
          console.log(response.data[0].cname)

          setComapny(response.data)
    // setFromTrdName(response.data[0].cname)
    // setFromGstin(response.data[0].gst)
    // setFromAddr1(response.data[0].address)
    // setFromPlace(response.data[0].cities)
    // setFromPincode(response.data[0].zipcode)
    // setFromStateCode(27)
          // console.log(response.data.data.map(use => ({ value: use.id, label: use.product_Name })))
      })
    }

    /* get Product Data */

    let getProductData = () => {

        axios.get(BASE_URL + 'product/page/0/10/asc/id/%20', {
            headers: authHeader()
        }).then(res => {

            setproductdata((res.data.Index).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })));
        })

    };

    const customFilter = (e) => {
        let name = e;
        axios.get(BASE_URL + 'product/page/0/10/asc/id/' + name, {
            headers: authHeader()
        }).then(res => {
            setproductdata((res.data.data).map(use => ({ value: use.id, label: use.product_Name + ' - ' + use.ean_Code + ' - ' + use.short_Name })))
        })
    }

    /* Current Date */
    const datevalue_1 = new Date(dt1);
    const formatter = new Intl.DateTimeFormat('in-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const defaultValue_1 = dt1 == "" ? '' : formatter.format(datevalue_1);

    const datevalue_4 = new Date(transDocDt1);

    const defaultValue_4 = transDocDt1 == "" ? '' : formatter.format(datevalue_4);

    console.log(defaultValue_4)
    useEffect(() => {
        getbranchdata();
        getProductData();
        companydetails();
        dispatch(emptysalesdata());
        getcompanydata();
    }, []);

    /* onChange Distributor */

    const onChangeCustomer = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setdistributor(e.value);

        axios.get(BASE_URL + 'distributor/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            setbuyer({ "gstin": res.data.gstNumber, "loc": res.data.city, "pos": res.data.stateid, "stcd": res.data.stateid, "addr1": res.data.billingAddress, "lglNm": res.data.tradeName, "pin": res.data.pinCode, "ph": res.data.perMobileNumber, "em": res.data.perEmail })
            setdel(res.data.deliveryAddress);
            setpaymentTerms(res.data.paymentTerms||'')

            setdstateid(res.data.stateid);
            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        })
    };
 
    const onChangeCustomer1 = (e) => {
        // console.log("mello",e);
        let name = e.value;
        setretailer(e.value);

        axios.get(BASE_URL + 'retailer/getById/' + name, {
            headers: authHeader()
        }).then(res => {
            setbuyer({ "gstin": res.data.gstNumber, "loc": res.data.city, "pos": res.data.stateid, "stcd": res.data.stateid, "addr1": res.data.billingAddress, "lglNm": res.data.tradeName, "pin": res.data.pinCode, "ph": res.data.perMobileNumber, "em": res.data.perEmail })
            setdel(res.data.deliveryAddress);
            setdstateid(res.data.stateid);
            setpaymentTerms(res.data.paymentTerms||'')

            setboxProductDiscount(res.data.boxProductDiscount);
            setschemeboxProductDiscount(res.data.schemeboxProductDiscount);
            setkgProductDiscount(res.data.kgProductDiscount);
            setschemekgProductDiscount(res.data.schemekgProductDiscount);
            setcorporaetProductDiscount(res.data.corporaetProductDiscount);
            setschemecorporateProductDiscount(res.data.schemecorporateProductDiscount);
            setcookerProductDiscount(res.data.cookerProductDiscount);
            setschemecookerProductDiscount(res.data.schemecookerProductDiscount);
            setnoshProductDiscount(res.data.noshProductDiscount);
            setschemenoshProductDisocunt(res.data.schemenoshProductDisocunt);
        })
    };
    console.log(buyer)

    let addData = (e) => {
        let name = e.value;
        let filteredItems = salesItems1.filter(item => item.product.id == name);
        if (branch == "" || distributor == "" && retailer == '') {
            Swal.fire({
                title: 'Please Branch or Distributor First Select',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: 'red'
            })
        } else {
            if (filteredItems.length == "0") {
                dispatch(salesaddtocart(name, ({
                    "schemeboxProductDiscount": schemeboxProductDiscount,
                    "schemekgProductDiscount": schemekgProductDiscount,
                    "schemecorporateProductDiscount": schemecorporateProductDiscount,
                    "schemecookerProductDiscount": schemecookerProductDiscount,
                    "schemenoshProductDisocunt": schemenoshProductDisocunt,
                    "kgProductDiscount": kgProductDiscount,
                    "boxProductDiscount": boxProductDiscount,
                    "corporaetProductDiscount": corporaetProductDiscount,
                    "cookerProductDiscount": cookerProductDiscount,
                    "noshProductDiscount": noshProductDiscount
                })));
                setShipping('0');
            } else {
                Swal.fire({
                    title: 'This Product is Already Added',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonColor: 'red'
                })
            }
        }
    };

    let onChangeBranch = (e) => {
        let name = e.value;
        setbranch(e.value);
        setwarehousehvalue("");

        axios.get(BASE_URL + 'branch/' + name, {
            headers: authHeader()
        }).then(res => {
            // console.log("mello",res.data)
            setbstateid(res.data.states.id)
        })

        axios.get(BASE_URL + 'warehouse/getAll', {
            headers: authHeader()
        }).then(res => {
            let branch_data = [];
            (res.data).map(user => {
                // let branchid = user.branch.id;
                if (user.branch !== null) {
                    if (user.branch.id === e.value) {
                        branch_data.push({ value: user.id, label: user.name })
                    }
                }

            })

            setwarehousehdata(branch_data)

        })
    };
    const onChangewarehouse = (e) => {
        setwarehouse(e.value)
        setwarehousehvalue({ value: e.value, label: e.label })
    }
const handlesuptype=(e)=>{
   setsupTyp(e.id)
}
    /* get Shipping Charges */
    let settingship = (e) => {
        if (e == "") {

            setShipping("");
        } else {

            setShipping(e);
        }
    }

    /* get Round Value */
    const settinground = (e) => {

        if (e == "") {

            setRound("");
        } else {


            setRound(e);
        }

    };

    const stype = [
        { "id": "B2B", "label": "Business to Business" },
        { "id": "SEZWP", "label": "SEZ with payment" },
        { "id": "SEZWOP", "label": "SEZ without payment" },
        { "id": "EXPWP", "label": "Export with Payment" },
        { "id": "EXPWOP", "label": "Export without payment" },
        { "id": "DEXP", "label": "Deemed Export" }
    ]
  

    const SGSTcheck = () => {
        let aa = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                aa = sgstTax;

            } else {
                aa = 0;
            }

        } else {
            aa = 0;
        }

        return aa;

    }

    const cgstcheck = () => {

        let bb = 0

        if (taxtype == "withtax") {

            if (bstateid === dstateid) {

                bb = cgstTax;

            } else {

                bb = 0;

            }

        } else {

            bb = 0;

        }
        return bb;
    }

    const igstcheck = () => {
        let cc = 0;

        if (taxtype == "withtax") {
            if (bstateid === dstateid) {
                cc = 0;
            } else {
                cc = igstTax;
            }
        } else {
            cc = 0;
        }
        return cc;
    }
    const handleChange = (e) => setseller({ ...seller, [e.target.name]: e.target.value });
    const handleChange1 = (e) => setbuyer({ ...buyer, [e.target.name]: e.target.value });
    let submitdata = () => {
        let salesItems = da_ta;
        let igst = igstcheck();
        let cgst = cgstcheck();
        let sgst = SGSTcheck();
        let grandtotal = grandTotal;
        let roundofvalue = round;
        let valdtls = { "assVal": gross_AmountassVal, "cgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "sgstVal": seller.stcd === buyer.stcd ? (cgstVal / 2) : 0, "igstVal": seller.stcd === buyer.stcd ? 0 : cgstVal, "othChrg": shippingcharge, "totInvVal": grossamount, "cesVal": 0, "stCesVal": 0 }

        setIsLoading(true);

        let dt = defaultValue_1;
        let transDocDt = defaultValue_4;
        let tdls = { taxSch, supTyp }
        const doc = { no, dt, }
         
        let ewb = {distance, transId, transName, transDocDt, transDocNo, vehNo, vehType, transMode }
        if (customer === 'distributor') {
            const formdata = new FormData();
            formdata.append('warehouse', warehouse);
            formdata.append('distributor', distributor);
            formdata.append('buyerorderno', buyerorderno);
            formdata.append('invoiceno', invoiceno);
            formdata.append('invoicedate', invoicedate);
            formdata.append('status', status);
            formdata.append('taxtype', taxtype);
            formdata.append('termsofdelivery', termsofdelivery);
            formdata.append('deliverynoteno', deliverynoteno);
            formdata.append('destination', destination);
            formdata.append('packaginglistno', packaginglistno);
            formdata.append('gstno', gstno);
            formdata.append('panno', panno);
            formdata.append('ewaybillno', ewaybillno);
            formdata.append('grnno', grnno);
            formdata.append('lrno', lrno);
            formdata.append('udyamno', udyamno);
            formdata.append('msmeno', msmeno);
            formdata.append('si', JSON.stringify(salesItems));
            formdata.append('lrndate', lrndate);
            formdata.append('edd', edd);
            formdata.append('grndate', grndate);
            formdata.append('igst', igst);
            formdata.append('cgst', cgst);
            formdata.append('sgst', sgst);
            formdata.append('grandtotal', grandtotal);
            formdata.append('deliveryAddress', deliveryAddress);
            formdata.append('grossamount', grossamount);
            formdata.append('shippingcharge', shippingcharge);
            formdata.append('roundofvalue', roundofvalue);
            formdata.append('branch', branch);
            formdata.append('paymentTerms', paymentTerms);
            formdata.append('dispatchedthrough', dispatchedthrough);
            formdata.append('totalnopkg', totalnopkg);
            formdata.append('img', img);
            formdata.append('pdf', pdf);
            formdata.append('tdls', JSON.stringify(tdls));
            formdata.append('doc', JSON.stringify(doc));
            formdata.append('seller', JSON.stringify(setsellindata));
            formdata.append('buyer', JSON.stringify(buyer));
            formdata.append('valdtls', JSON.stringify(valdtls));
            formdata.append('ewb', JSON.stringify(ewb));
            formdata.append('voucherid', voucherid);

            axios.post(BASE_URL + 'sales/', formdata, {
                headers: authHeader()
            }).then(res => {
                navigate('/Sales/Table/1')

            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (customer === 'retailer') {
            const formdata = new FormData();
            formdata.append('warehouse', warehouse);
            formdata.append('retailer', retailer);
            formdata.append('buyerorderno', warehouse);
            formdata.append('invoiceno', invoiceno);
            formdata.append('invoicedate', invoicedate);
            formdata.append('status', status);
            formdata.append('taxtype', taxtype);
            formdata.append('termsofdelivery', termsofdelivery);
            formdata.append('deliverynoteno', deliverynoteno);
            formdata.append('destination', destination);
            formdata.append('packaginglistno', packaginglistno);
            formdata.append('gstno', gstno);
            formdata.append('panno', panno);
            formdata.append('ewaybillno', ewaybillno);
            formdata.append('grnno', grnno);
            formdata.append('lrno', lrno);
            formdata.append('udyamno', udyamno);
            formdata.append('msmeno', msmeno);
            formdata.append('si', JSON.stringify(salesItems));
            formdata.append('lrndate', lrndate);
            formdata.append('edd', edd);
            formdata.append('grndate', grndate);
            formdata.append('igst', igst);
            formdata.append('cgst', cgst);
            formdata.append('sgst', sgst);
            formdata.append('grandtotal', grandtotal);
            formdata.append('deliveryAddress', deliveryAddress);
            formdata.append('grossamount', grossamount);
            formdata.append('shippingcharge', shippingcharge);
            formdata.append('roundofvalue', roundofvalue);
            formdata.append('branch', branch);
            formdata.append('paymentTerms', paymentTerms);
            formdata.append('dispatchedthrough', dispatchedthrough);
            formdata.append('totalnopkg', totalnopkg);
            formdata.append('img', img);
            formdata.append('pdf', pdf);
            formdata.append('tdls', JSON.stringify(tdls));
            formdata.append('doc', JSON.stringify(doc));
            formdata.append('seller', JSON.stringify(setsellindata));
            formdata.append('buyer', JSON.stringify(buyer));
            formdata.append('valdtls', JSON.stringify(valdtls));
            formdata.append('ewb', JSON.stringify(ewb));
            formdata.append('voucherid', voucherid);

            axios.post(BASE_URL + 'sales/', formdata, {
                headers: authHeader()
            }).then(res => {
                navigate('/Sales/Table/1')
            }).catch(error => {
                console.error('Error adding  :', error);
                if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `
                    <div>
                      <p id="issueText">Why do I have this issue?</p>
                      <ul>
                  
                      <li id="typer1">You might have an internet issue</li>
                      </ul>
                    </div>
                  `,
                        didOpen: () => {
                            applyFontSizeTransition('issueText');
                        }
                    });
                }
            })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        dispatch(emptysalesdata());
    }
    const handletrans=(e)=>{
        settransId(e.label)
        console.log(e)
        console.log((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))
        )
        settransName((data11.filter((item)=>item.id==e.value).map((item)=>item.transporter_Name))[0])
        } 

// console.log(setsellindata)

    const [disword, setdisword] = useState(encodeURIComponent(' '))
    const [dis, setdistributordata1] = useState([])

    useEffect(() => {
        if (customer === "distributor") {
            axios.get(BASE_URL + `distributor/page/0/10/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
                .then(response => {
                    console.log(response)
                    if (response.data.data == undefined) {
                        setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.trade_Name })));
                    } if (response.data.Index == undefined) {
                        setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.trade_Name })));

                    }

                })
        } else if (customer === 'retailer') {
            axios.get(BASE_URL + `retailer/page/0/100/asc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() })
                .then(response => {
                    //   console.log(response,"mello")
                    if (response.data.data == undefined) {
                        setdistributordata1(response.data.Index.map(use => ({ value: use.id, label: use.retailername })));
                    } if (response.data.Index == undefined) {
                        setdistributordata1(response.data.data.map(use => ({ value: use.id, label: use.retailername })));

                    }

                })
        }


        /** Voucher GetAll */

        axios.get(BASE_URL + `voucher/page/0/10/desc/id/${disword.length ? disword : encodeURIComponent(' ')}`, { headers: authHeader() }).then(res => {
            if (res.data.data == undefined) {
                setvoucherData(res.data.Index.map(use => ({ value: use.id, label: use.voucherseries })));
            } if (res.data.Index == undefined) {
                setvoucherData(res.data.data.map(use => ({ value: use.id, label: use.voucherseries })));

            }
        })

    }, [customer, disword])


    const onChangeVoucher = (e) => {
        setvoucherid(e.label);
    }
    const[data11,setdata11]=useState([])
    const [disword11, setdisword11] = useState(encodeURIComponent(' '))
const [distributordata111, setdistributordata111] = useState([])
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}transporter/page/0/200/asc/id/${disword11.length ? disword11 : encodeURIComponent(' ')}`,
        { headers: authHeader() }
      );

      let options = [];
      if (response.data.Index) {
        setdata11(response.data.Index)
        options = response.data.Index.map(item => ({
          value: item.id,
          label: item.transporter_Gstin,
        }));
      } else if (response.data.data) {
        setdata11(response.data.data)
        options = response.data.data.map(item => ({
          value: item.id,
          label: item.transporter_Gstin,
        }));
      } else {
        console.warn('Unexpected response structure:', response.data);
      }

      // Add a placeholder option at the beginning
      const optionsWithPlaceholder = [
        // { value: '', label: 'Select an option' }, // Placeholder option
        ...options,
      ];

      setdistributordata111(optionsWithPlaceholder);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [disword11]);



    return (

        <MDBCard className="company">
            <h1>Sales Create</h1>
            <Link to='/Sales/Table/1' className="btn btn-primary">Back</Link>
            <Form className="product1">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Sales Data</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Select Customer</Form.Label>
                                    <Form.Select onChange={(e) => setcustomer(e.target.value)}>
                                        <option>Select</option>
                                        <option value={'retailer'}>Retailer</option>
                                        <option value={'distributor'}>Distributor</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Branch Name</Form.Label>
                                    <Select options={branchdata} onChange={(e) => onChangeBranch(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">WareHouse Name</Form.Label>
                                    <Select value={warehousehvalue} options={warehousehdata} onChange={(e) => onChangewarehouse(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Payment</Form.Label>
                                    <Form.Control type='text' name="paymentTerms" value={paymentTerms} onChange={(e) => setpaymentTerms(e.target.value)} autoComplete="off" />
                                </Form.Group>
                                {customer === "distributor" &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                        <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer(options)} />

                                    </Form.Group>}
                                {customer === 'retailer' &&
                                    <Form.Group as={Col} md="4" controlId="formBasicName">
                                        <Form.Label className="label">Customer Name</Form.Label>
                                        {/* <Select options={customerData} onChange={(e) => onChangeCustomer(e)} /> */}
                                        <Select options={dis} onInputChange={(inputValue) => setdisword(inputValue)} onChange={(options) => onChangeCustomer1(options)} />

                                    </Form.Group>}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Buyer Order</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setbuyerorderno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setinvoiceno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Invoice Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setinvoicedate(e.target.value)} />
                                </Form.Group>
                               
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher</Form.Label>
                                    <Select options={voucherData} onChange={(e) => onChangeVoucher(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Voucher Name</Form.Label>
                                    <Form.Control type='text' value={voucherid} name="voucherid" autoComplete="off" readOnly />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Status</Form.Label>
                                    <Form.Select value={status} onChange={(e) => setstatus(e.target.value)}>
                                        <option value='true'>Open</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Select value={taxtype} onChange={(e) => settaxtype(e.target.value)} >
                                        <option>--Select--</option>
                                        <option value='withtax'>With Tax</option>
                                        <option value='withouttax'>Without Tax</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Image</Form.Label>
                                    <Form.Control type="file" onChange={(e) => setimg(e.target.files[0])} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PDF</Form.Label>
                                    <Form.Control type="file" accept=".pdf" onChange={(e) => setpdf(e.target.files[0])} />
                                </Form.Group>

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Billing Address</Accordion.Header>
                        <Accordion.Body>
                            {/* <Row className="mb-1">
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Customer Address</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Customer Details</Form.Label>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Address : -</Form.Label>
                                    <span>&nbsp;{customerAll.address}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Name : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.cname : customerSubContactsdata.contactname}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">City : -</Form.Label>
                                    <span>&nbsp;{customerAll.city}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Phone No : -</Form.Label>
                                    <span>&nbsp;{customerSubContactsdata == "" ? customerAll.phonenumber : customerSubContactsdata.phoneno}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Country : -</Form.Label>
                                    <span>{"India"}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Email Id : -</Form.Label>
                                    <span> {customerSubContactsdata == "" ? customerAll.email : customerSubContactsdata.email}</span>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">State : -</Form.Label>
                                    <span>{supplierstate}</span>
                                </Form.Group>
                            </Row> */}
                            <b><span className="">Delivery Address</span></b> <br></br> <br></br>
                            <Row className="mb-1">
                                {/* <select className="form-select dd">
                                <option className="extrvagnze">select Address</option>
                               
                                {
                                    disdel.map((item)=>{
                                        return<>
                                          <option className="extrvagnze">Address:{item.delivery_address} ,State:-{item.state} &nbsp;,Pin-code:-{item.pincode}</option>
                                        </>
                                    })
                                }
                               </select> */}
                                <Select
                                    options={options}
                                    onChange={handleSelectChange}
                                    filterOption={customFilter11}
                                    placeholder="Select Address"
                                    value={selectedOption}
                                />
                                {selectedOption && (
                                    <button className="btn btn-sm btn-primary" onClick={handleRemoveSelection}>X</button>
                                )}

                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Terms of Delivery</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => settermsofdelivery(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Delivery Note No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdeliverynoteno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Destination</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdestination(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Packaging List No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpackaginglistno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GST No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setgstno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Pan No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setpanno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Eway Bill No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setewaybillno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setgrnno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Dispatched Through</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setdispatchedthrough(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Total No PKG </Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => settotalnopkg(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setlrno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Udyam No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setudyamno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">MSME No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" onChange={(e) => setmsmeno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">LRN Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setlrndate(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">EDD</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setedd(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GRN Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setgrndate(e.target.value)} />
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>GST Details</Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-1">
                                <h1>Transport Details</h1>
                                {/* <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Tax Type</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Tax Type" name="taxSch" onChange={(e) => settaxSch(e.target.value)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Type of supply</Form.Label>
                                    <Select options={stype} className='sett' onChange={handlesuptype}/>

                                    {/* <Form.Control type="text" autoComplete="off" placeholder="Enter Type of supply" onChange={(e) => setsupTyp(e.target.value)} /> */}
                                </Form.Group>
                                <h1>Document Details</h1>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document No</Form.Label>
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter Document No" onChange={(e) => setno(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="formBasicName">
                                    <Form.Label className="label">Document Date</Form.Label>
                                    <Form.Control type="date" autoComplete="off" onChange={(e) => setdt1(e.target.value)} />
                                </Form.Group>
                                <h1>Seller Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Seller </Form.Label>
                                    <Form.Control type="text" value={[setsellindata].map((item) => item.gstin)} disabled autoComplete="off"  name="gstin" placeholder="Enter GSTIN of Supplier" onChange={(e) => {handleChange(e); }} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.gstin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" autoComplete="off" value={[setsellindata].map((item) => item.lglNm)} disabled name="lglNm" placeholder="Enter Legal Name" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.lglNm)} disabled  */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text"  autoComplete="off" value={[setsellindata].map((item) => item.addr1)} disabled name="addr1" placeholder="Enter Address1" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.addr1)} disabled */}
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" autoComplete="off" name="addr2" placeholder="Enter Address2" onChange={(e) => handleChange(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location"  value={[setsellindata].map((item) => item.loc)} disabled autoComplete="off" name="loc" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.loc)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter PinCode" name="pin" value={[setsellindata].map((item) => item.pin)} disabled autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.pin)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter State Code"  value={[setsellindata].map((item) => item.stcd)} disabled autoComplete="off" name="stcd" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.stcd)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone Number</Form.Label>
                                    <Form.Control type="text"  placeholder="Enter Phone Number"  value={[setsellindata].map((item) => item.ph)} disabled name="ph" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* value={[setsellindata].map((item) => item.ph)} disabled */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email Id</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email Id"  disabled value={[setsellindata].map((item) => item.em)}  name="em" autoComplete="off" onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                {/* disabled value={[setsellindata].map((item) => item.em)} */}
                                <h1>Buyer Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">GSTIN of Buyer</Form.Label>
                                    <Form.Control type="text" placeholder="Enter GSTIN of Buyer" value={buyer.gstin || ""} name="gstin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Legal Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Legal Name" value={buyer.lglNm || ""} name="lglNm" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code of Place of Supply</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code of Place of Supply" value={buyer.pos || ""} name="pos" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address1</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address1" value={buyer.addr1 || ""} name="addr1" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                {/* <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Address2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Address2" value={buyer.addr2 || ""} name="addr2" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group> */}
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Location</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Location" value={buyer.loc || ""} name="loc" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">PinCode </Form.Label>
                                    <Form.Control type="text" placeholder="Enter PinCode" value={buyer.pin || ""} name="pin" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">State Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter State Code" value={buyer.stcd || ""} name="stcd" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Phone No</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Phone No" value={buyer.ph || ""} name="ph" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" value={buyer.em || ""} name="em" autoComplete="off" onChange={(e) => handleChange1(e)} />
                                </Form.Group>
                                <h1>Eway Bill Details</h1>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Distance                        <a 
  href="https://einvoice1.gst.gov.in/Others/GetPinCodeDistance" 
  className='btn btn-primary' 
  style={{ 
    display: 'inline-block', 
    width: '147px', 
    height: '33px', 
    // marginTop: '44px', 
    textDecoration: 'none' ,
    background:"#1A42B7!important"
  }}
  target="_blank"
  rel="noopener noreferrer"
>
  <span style={{ position: 'relative', top: '-4px' }}>Distance</span>
</a></Form.Label>
                                    <Form.Control type="text" onChange={(e) => setdistance(e.target.value)} />
                                </Form.Group>
         
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Mode of Transport</Form.Label>
                                    <Select options={modes1} className='sett'onChange={(e) => settransMode(e.id)}  />

                                    {/* <Form.Control type="text" onChange={(e) => settransMode(e.target.value)} /> */}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Id</Form.Label>
                                    <Select options={distributordata111} className='sett'  onInputChange={(inputValue) => setdisword11(inputValue)} onChange={handletrans}/>

                                    {/* <Form.Control type="text" onChange={(e) => settransId(e.target.value)} /> */}
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transporter Name</Form.Label>
                                    <Form.Control type="text" value={transName} onChange={(e) => settransName(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document Date</Form.Label>
                                    <Form.Control type="date" onChange={(e) => settransDocDt1(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Transport Document No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => settransDocNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle No</Form.Label>
                                    <Form.Control type="text" onChange={(e) => setvehNo(e.target.value)} />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formBasicName">
                                    <Form.Label className="label">Vehicle Type</Form.Label>
                                    <Select options={vechiletype}   className='sett'onChange={(e) => setvehType(e.id)}  />

                                    {/* <Form.Control type="text" onChange={(e) => setvehType(e.target.value)} /> */}
                                </Form.Group>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
                <br></br>
                <Row className="mb-1">
                    <Form.Group as={Col} md="12" controlId="formBasicName">
                        <Form.Label className="label">Product</Form.Label>
                        <Select options={productdata} onChange={(e) => addData(e)} onInputChange={(e) => customFilter(e)} />
                    </Form.Group>
                </Row>
                <br></br>
                <Table striped responsive hover size="sm">
                    <thead className='p-3 mb-2 bg-success text-white'>
                        <tr>
                            <th>PRODUCT&nbsp;NAME</th>
                            <th >UOM</th>
                            <th >QTY(PCS)</th>
                            <th >QTY(KG)</th>
                            <th >Rate</th>
                            <th >UOM</th>
                            <th >Trade&nbsp;Discount</th>
                            <th>GROSS AMOUNT (₹)</th>
                            <th >SCHEME DISCOUNT %</th>
                            <th >NET AMOUNT (₹)</th>
                            <th >GST Value</th>
                            <th >Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody style={{ overflow: "scroll" }}>
                        {salesItems1.map(item =>
                            <tr key={item.product.id}>
                                <td>
                                    <Form.Group as={Col} md="12" >
                                        {/* <Form.Control type="text" value={(item.productName ? item.productName : item.product.productName)} size="sm" /> */}
                                        <textarea type="text" className="form-control textcontrol" value={(item.productName ? item.productName : item.product.productName)} size="sm" ></textarea>

                                    </Form.Group>
                                    <Form.Control as="textarea" onChange={(e) => { dispatch(changesalestext(e.target.value, item)) }} value={item.text} rows={2} className='form-control' />
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changeunitofMeasurementsales(e.target.value, item, item.unitofmeasurement)); }} size="sm">
                                        <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                        <option value="pcs">PCS</option>
                                        <option value="kg">KG</option>
                                    </Form.Select>
                                </Form.Group>
                                </td>

                                <td>
                                {/* {"edoiklm"} */}
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changesalessqpbmeasurement(e.target.value, item, taxtype, item.unitofmeasurement)); roundData(e.target.value) }} value={item.salesquantity} disabled={item.unitofmeasurement == "kg" ? true : false||item.unitofmeasurement == "" ? true : false} />
                                    </Form.Group>
                                </td>
                                <td>
                                    {/* {"edoiklm"} */}
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0"  onChange={(e) => { dispatch(changesalesqty(e.target.value, item, taxtype, item.unitofmeasurement)) }} value={Number(item.salesquantitykgs)} disabled={item.unitofmeasurement == "box"||item.unitofmeasurement == "pcs" ? true : false || item.unitofmeasurement == "" ? true : false} size="sm" requied />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" size="sm" onChange={(e) => { dispatch(changecostsales(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.dlp)} />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Select disabled={item.productType !== "kgproduct" ? true : false} onChange={(e) => { dispatch(changesalescalmeasurement(e.target.value, item, item.calunitofmeasurement)); roundData(e.target.value) }} size="sm">
                                            <option>{item.productType !== "kgproduct" ? 'PCS' : "Select"}</option>
                                            <option value="pcs">PCS</option>
                                            <option value="kg">KG</option>
                                        </Form.Select>
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="text" min="0" size="sm" value={Number(item.tradeDiscount)} />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.grossamount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" onChange={(e) => { dispatch(changesalesdiscount(e.target.value, item, taxtype)); roundData(e.target.value) }} value={Number(item.schemeDiscount)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td><Form.Group as={Col} md="12">
                                    <Form.Control type="number" min="0" value={Number(item.netAmount)} size="sm" readOnly requied />
                                </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.gstvalue)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type="number" min="0" value={Number(item.total)} size="sm" />
                                    </Form.Group>
                                </td>
                                <td>
                                    <Form.Group as={Col} md="12">
                                        <Form.Control type='button' className='btn btn-danger' onClick={() => { dispatch(removefromcartfromsales(item.product.id)); roundData('') }} value="X" size="sm" />
                                    </Form.Group>
                                </td>

                            </tr>
                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Packing&nbsp;&&nbsp;Forwarding</td>
                            <td><Form.Group as={Col} md="12">
                                <Form.Control type="number" value={shippingcharge} onChange={(e) => settingship(e.target.value)} style={{ height: 30 }} />
                            </Form.Group></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Net&nbsp;Amount</td>
                            <td> <Form.Group as={Col} md="12">
                                <Form.Control type="number" value={grossamount ? ((grossamount).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                            </Form.Group></td>
                            <td></td>
                        </tr>

                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>IGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={igstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>SGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={SGSTcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>CGST</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={cgstcheck()} style={{ height: 30 }} requied />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Round&nbsp;off&nbsp;Value</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={round} onChange={(e) => settinground(e.target.value)} style={{ height: 30 }} />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontSize: 13 }}>Grand&nbsp;Total</td>
                            <td>
                                <Form.Group as={Col} md="12">
                                    <Form.Control type="number" value={grandTotal ? ((grandTotal).toFixed(2)) : "0"} style={{ height: 30 }} readOnly />
                                </Form.Group>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
                {/* <Button variant="primary" type="button" onClick={submitdata}>
                    Submit
                </Button> */}
                <Button variant="primary" style={{ width: 'auto' }} onClick={submitdata} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </Button>
            </Form>
        </MDBCard>
    )
}